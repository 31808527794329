/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { selectors as rulesSelectors } from '@/redux/api/rules';

import Collapsible from '+components/Collapsible';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { CellNqlSearch, UniversalCell } from '+components/Table/Cells';

const SearchByContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  button:not(:last-child):after {
    content: ', ';
  }
`;

const AlgorithmDetails = (props) => {
  const { block, tdm, icon, formatter, disabled } = props;

  const algorithm = useSelector(rulesSelectors.getAlgorithm(tdm.id)) || tdm;

  const row = useMemo(
    () => ({
      original: {
        ...algorithm,
        customer: block?.customer,
      },
    }),
    [algorithm, block?.customer],
  );

  return (
    <Collapsible
      name={algorithm?.name}
      id={algorithm?.id}
      description={algorithm?.description}
      formatter={formatter}
      icon={icon}
      expandable
      original={row.original}
      disabled={disabled}
    >
      <Row $type={LayoutTypes.field}>
        <Col $type={LayoutTypes.fieldName}>Categories</Col>
        <Col $type={LayoutTypes.fieldValue} gap="5px">
          {algorithm?.categories?.map((item, i) => (
            <Fragment key={i}>
              {UniversalCell('categories')({
                row,
                value: item,
                options: { disabled },
              })}
            </Fragment>
          ))}
        </Col>
      </Row>

      <Row $type={LayoutTypes.field}>
        <Col $type={LayoutTypes.fieldName}>NQL Search</Col>
        <Col $type={LayoutTypes.fieldValue}>
          {algorithm?.search_by?.map((searchBy, index) => (
            <SearchByContainer key={index}>
              {searchBy.search.map((search, i) => (
                <CellNqlSearch
                  key={i}
                  // type={i === 0 ? searchBy.type : undefined}
                  value={search}
                  context={algorithm?.algo_record_type}
                  customer={block?.customer}
                />
              ))}
            </SearchByContainer>
          ))}
        </Col>
      </Row>

      <Row $type={LayoutTypes.field}>
        <Col $type={LayoutTypes.fieldName}>Threshold</Col>
        <Col $type={LayoutTypes.fieldValue}>
          {algorithm?.thresholds?.map((item) => item.threshold).join(', ')}
        </Col>
      </Row>

      <Row $type={LayoutTypes.field}>
        <Col $type={LayoutTypes.fieldName}>Track</Col>
        <Col $type={LayoutTypes.fieldValue}>
          {algorithm?.track_by?.map((track) => (
            <span key={track}>
              {(track?.fields || track)?.map((field) => field).join(', ')}
            </span>
          ))}
        </Col>
      </Row>
    </Collapsible>
  );
};

AlgorithmDetails.propTypes = {
  block: PropTypes.shape().isRequired,
  tdm: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  icon: PropTypes.node.isRequired,
  formatter: PropTypes.func,
  disabled: PropTypes.bool,
};

AlgorithmDetails.defaultProps = {
  formatter: null,
  disabled: false,
};

export default AlgorithmDetails;
