import { Fragment, useMemo, useState } from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import isEqual from 'lodash.isequal';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';
import FeatureFlags from '@/models/FeatureFlags';

import { Breadcrumb } from '+components/Breadcrumb';
import { lang } from '+components/charts/common/utils';
import { trafficDefaultColumns, TrafficTable } from '+components/ContextTables';
import BlockTable, {
  defaultColumns as blockTableDefaultColumns,
} from '+components/ContextTables/BlockTable';
import DnsTable, {
  defaultColumns as dnsTableDefaultColumns,
} from '+components/ContextTables/DnsTable';
import EventTable, {
  defaultColumns as eventTableDefaultColumns,
} from '+components/ContextTables/EventTable';
import FlowTable, {
  defaultColumns as flowTableDefaultColumns,
} from '+components/ContextTables/FlowTable';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import RealtimeManager from '+components/RealtimeManager';
import useEvent from '+hooks/useEvent';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useRealtimeOrRequest from '+hooks/useRealtimeOrRequest';

const excludeContexts = new Set([]);

const defaultTableValues = {
  throttle: {
    wait: 300,
    props: ['tableData', 'noDataText'],
  },
};

const FlowTableRealtime = RealtimeManager(FlowTable, defaultTableValues);
const DnsTableRealTime = RealtimeManager(DnsTable, defaultTableValues);
const TrafficTableRealTime = RealtimeManager(TrafficTable, defaultTableValues);
const EventTableRealTime = RealtimeManager(EventTable, defaultTableValues);
const BlockTableRealTime = RealtimeManager(BlockTable, defaultTableValues);

const TableComponents = {
  [ContextTypes.flow]: FlowTableRealtime,
  [ContextTypes.dns]: DnsTableRealTime,
  [ContextTypes.traffic]: TrafficTableRealTime,
  [ContextTypes.alerts]: EventTableRealTime,
  [ContextTypes.blocks]: BlockTableRealTime,
};

const tableColumns = {
  [ContextTypes.flow]: flowTableDefaultColumns,
  [ContextTypes.dns]: dnsTableDefaultColumns,
  [ContextTypes.traffic]: trafficDefaultColumns,
  [ContextTypes.alerts]: eventTableDefaultColumns,
  [ContextTypes.blocks]: blockTableDefaultColumns,
};

const tableSortBy = {
  [ContextTypes.flow]: [{ id: 'timestamp', desc: true }],
  [ContextTypes.dns]: [{ id: 'timestamp', desc: true }],
  [ContextTypes.traffic]: [{ id: 'timestamp', desc: true }],
  [ContextTypes.alerts]: [{ id: 'timestamp', desc: true }],
  [ContextTypes.blocks]: [{ id: 'start', desc: true }],
};

const RealTimeTraffic = () => {
  const [flowIncludeFields, setFlowIncludeFields] = useState([]);
  const [dnsIncludeFields, setDnsIncludeFields] = useState([]);
  const [trafficIncludeFields, setTrafficIncludeFields] = useState([]);
  const [eventIncludeFields, setEventIncludeFields] = useState([]);
  const [blockIncludeFields, setBlockIncludeFields] = useState([]);
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const excluded = useMemo(() => {
    return isDnsEnabled
      ? excludeContexts
      : new Set([...excludeContexts, ContextTypes.traffic]);
  }, [isDnsEnabled]);

  const excludeContextsArr = useMemo(() => Array.from(excluded), [excluded]);

  const context = useLastAllowedContext({
    excludeContexts: excluded,
    defaultContext: ContextTypes.flow,
  });

  const [filters] = useGlobalFilters(context);

  const TableComponent = useMemo(() => TableComponents[context], [context]);

  const includeFields = {
    [ContextTypes.flow]: flowIncludeFields,
    [ContextTypes.dns]: dnsIncludeFields,
    [ContextTypes.traffic]: trafficIncludeFields,
    [ContextTypes.alerts]: eventIncludeFields,
    [ContextTypes.blocks]: blockIncludeFields,
  };

  const includeFieldsSetters = {
    [ContextTypes.flow]: setFlowIncludeFields,
    [ContextTypes.dns]: setDnsIncludeFields,
    [ContextTypes.traffic]: setTrafficIncludeFields,
    [ContextTypes.alerts]: setEventIncludeFields,
    [ContextTypes.blocks]: setBlockIncludeFields,
  };

  const { records } = useRealtimeOrRequest({
    name: `traffic_${context}_tap_data`,
    includeFields: includeFields[context],
    stopRequest: !includeFields[context].length,
    context,
    refresher: filters.refresher,
  });

  const data = useMemo(() => records?.toArray() || [], [records]);

  const columnsChange = useEvent(
    (allColumns, hiddenColumns, technicalColumns, setter) => {
      const fieldsSet = new Set(
        allColumns
          .map((item) => {
            if (item.realAccessor) {
              return item.realAccessor;
            }
            return typeof item.accessor === 'string' ? item.accessor : item.id;
          })
          .flat()
          .map((field) => {
            if (field.startsWith('label.ip')) {
              return 'label.ip';
            }
            if (field.startsWith('label.port')) {
              return 'label.port';
            }
            return field;
          }),
      );
      // remove hidden columns
      hiddenColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // remove technical columns
      technicalColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // add id - we need it to fetch full record if we need it
      fieldsSet.add('id');
      const nextValue = [...fieldsSet].filter(Boolean);
      setter((prevValue) =>
        isEqual(prevValue, nextValue) ? prevValue : nextValue,
      );
    },
  );

  const onColumnsChange = useEvent(
    (allColumns, hiddenColumns, technicalColumns) => {
      columnsChange(
        allColumns,
        hiddenColumns,
        technicalColumns,
        includeFieldsSetters[context],
      );
    },
  );

  const pageTitle = `Search ${ContextTypesLabels[context]}`;

  return (
    <Fragment>
      <Breadcrumb title={pageTitle} />

      <GlobalFiltersSetting
        nql
        context={context}
        excludeContexts={excludeContextsArr}
        customers
        socketControl
      />
      <TableComponent
        id={`Reports_${context}_Tap`}
        columns={tableColumns[context]}
        tableData={data}
        minRows={20}
        noDataText={records ? undefined : lang.loading}
        onColumnsChange={onColumnsChange}
        exportingAllFields={false}
        sortBy={tableSortBy[context]}
        fillWithEmptyRows
      />
    </Fragment>
  );
};

export default RealTimeTraffic;
