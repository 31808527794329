import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FORM_ERROR } from 'final-form';

import PermissionModel from '@/models/Permission';
import SettingCategories from '@/models/SettingCategories';

import {
  actions as customerActions,
  selectors as customerSelectors,
  MAX_RECENT_CUSTOMERS,
} from '@/redux/api/customer';
import {
  actions as profileActions,
  selectors as profileSelectors,
} from '@/redux/api/user/profile';

import Button from '+components/Button';
import { lang } from '+components/charts/common/utils';
import ConfirmModal from '+components/ConfirmModal';
import { ActionsContainer, Col, Row } from '+components/Layout';
import Tooltip from '+components/Tooltip';
import useCustomerSubscriptionTypes from '+hooks/useCustomerSubscriptionTypes';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';

import CustomersTable from './components/CustomersTable';
import AddEditModal from './components/EditCustomer';

const Customers = () => {
  const dispatch = useDispatch();

  const customerTypes = useSelector(customerSelectors.getCustomerTypes);
  const customerSubscriptionTypes = useCustomerSubscriptionTypes();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const customers = useSelector(customerSelectors.getCustomers);
  const isCustomerFetching = useSelector(customerSelectors.isFetching);
  const error = useSelector(customerSelectors.getError);
  const meta = useSelector(customerSelectors.getMeta);
  const isProfileFetching = useSelector(profileSelectors.isFetching);
  const permissions = usePermissions(PermissionModel.Resources.customer.value);
  const samlProvider = useSelector(customerSelectors.getSamlProvider);

  const [customersRecent, setCustomersRecent] = usePortalSettingsValue(
    SettingCategories.recent,
    'customers',
    {},
  );

  const [editModal, setEditModal] = useState({ show: false });
  const [deleteModal, setDeleteModal] = useState({ show: false });
  const [editing, setEditing] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [checking, setChecking] = useState(null);
  // TODO: PORTAL-1594 workaround for bug
  // @see: https://netography.atlassian.net/browse/PORTAL-1594
  const [listRefresher, setListRefresher] = useState(null);

  const isFetching = isCustomerFetching || isProfileFetching;
  const isMe = customer?.id === editModal.item?.id;
  const canAdd = permissions?.create && (customer?.isReseller ?? false);
  const canManage =
    !isFetching && editModal.item?.id
      ? permissions?.update
      : permissions?.create;
  const canRemove =
    editModal.item?.id &&
    !editModal.item?.directCustomers &&
    !isMe &&
    permissions?.delete;

  useLoadingIndicator(isFetching);

  const defaultCustomer = useMemo(
    () => ({
      type: customerTypes?.customer?.value,
      subscriptionType: customerSubscriptionTypes?.trial?.value,
      retention: customerSubscriptionTypes?.trial?.retention,
      rollupRetention: customerSubscriptionTypes?.trial?.rollupRetention,
      ...(!samlProvider.resellerSso ? {} : { useResellerSso: true }),
    }),
    [samlProvider?.resellerSso, customerTypes, customerSubscriptionTypes],
  );

  const tableData = useMemo(() => {
    const date = Object.entries(customers || {}).reduce((acc, [key, item]) => {
      if (item.parent !== customer.shortname) {
        if (!acc[item.parent]) {
          acc[item.parent] = {};
        }
        acc[item.parent].directCustomers =
          (acc[item.parent].directCustomers || 0) + 1;
      }

      item.node?.split('+').forEach((node) => {
        if (
          !!customers[node] &&
          ![item.shortname, item.parent, customer.shortname].includes(node)
        ) {
          if (!acc[node]) {
            acc[node] = {};
          }
          acc[node].subCustomers = (acc[node].subCustomers || 0) + 1;
        }
      });

      let devices = +(item.meta?.devices ?? Number.NaN);
      devices = Number.isNaN(devices) ? null : devices;

      let vpcs = +(item.meta?.vpcs ?? Number.NaN);
      vpcs = Number.isNaN(vpcs) ? null : vpcs;

      return {
        ...acc,
        [key]: {
          ...(item.isReseller
            ? {
                directCustomers: 0,
                subCustomers: 0,
              }
            : {}),
          ...acc[key],
          ...item,
          ingest: `${item.ingestIp}:${item.ingestPort}`,
          devices,
          vpcs,
          created: +item.created,
        },
      };
    }, {});
    return Object.values(date);
  }, [customers, customer?.shortname]);

  const toggleEditModal = useCallback(
    (item) => {
      setEditModal((prev) => ({
        show: !prev.show,
        item: item?.target ? { ...defaultCustomer } : item,
      }));
      dispatch(customerActions.cancelled());
    },
    [defaultCustomer],
  );

  const toggleDeleteModal = useCallback((item) => {
    setDeleteModal((prev) => ({
      show: !prev.show,
      shortname: item?.shortname,
    }));
    dispatch(customerActions.cancelled());
  }, []);

  const impersonateAdmin = useCallback((item) => {
    dispatch(customerActions.impersonate(item.shortname));
  }, []);

  const onLoginToCustomer = useCallback(
    (shortname, addToShortname) => {
      const nextRecent = [...(customersRecent[addToShortname] || [])];
      const index = nextRecent.findIndex(
        (item) => item.shortname === shortname,
      );
      if (index === -1) {
        nextRecent.unshift({ shortname, timestamp: Date.now() });
      } else {
        nextRecent.splice(index, 1);
        nextRecent.unshift({ shortname, timestamp: Date.now() });
      }
      setCustomersRecent({
        ...customersRecent,
        [addToShortname]: nextRecent.slice(0, MAX_RECENT_CUSTOMERS),
      });
      dispatch(profileActions.loginToCustomer({ shortname }));
    },
    [customer?.shortname, customersRecent, customers],
  );

  const remove = useCallback((inShortname) => {
    dispatch(customerActions.remove(inShortname));
    setDeleting(true);
  }, []);

  const edit = useCallback(
    (inShortname, item) => {
      if (!item) {
        return Promise.reject(new Error('have not item'));
      }
      if (item.id) {
        dispatch(
          customerActions.update({
            data: item,
            shortname: inShortname,
            enableResellerSso:
              item.useResellerSso && !editModal?.item?.useResellerSso,
            disableResellerSso:
              !item.useResellerSso && editModal?.item?.useResellerSso,
          }),
        );
      } else {
        dispatch(customerActions.add(item));
      }

      return new Promise((resolve, reject) => {
        setEditing({
          resolve,
          reject,
        });
      });
    },
    [editModal?.item?.useResellerSso],
  );

  const editConfirm = useCallback(
    (cancel, inShortname, item) => {
      if (cancel || !item) {
        setEditModal({});
        return Promise.reject(new Error('cancellation'));
      }

      return edit(inShortname, item);
    },
    [edit],
  );

  const checkShortname = useCallback(
    (values, resolve) => {
      if (!values || (!values.organization && !values.shortname)) {
        resolve();
        return;
      }

      const { isReseller } = customer;

      const check =
        (isReseller && values.shortname && values.shortname.length > 4) ||
        values.organization.length > 4;

      if (!check) {
        resolve();
        return;
      }

      dispatch(
        customerActions.checkShortname(
          (isReseller && values.shortname) || values.organization,
        ),
      );
      setChecking({ resolve });
    },
    [customer?.isReseller],
  );

  useEffect(() => {
    // do not add areAllFetched because we need to fetch customers each time they changed
    const skip = !customer?.shortname || !customer?.isReseller;
    if (skip) {
      return undefined;
    }
    const namespace = 'fetch_customers';
    dispatch(customerActions.fetch(customer.shortname, namespace));
    return () => {
      dispatch(customerActions.cancel(namespace));
    };
  }, [customer?.shortname, customer?.isReseller, listRefresher]);

  useEffect(() => {
    if (isFetching || !editing) {
      return;
    }

    const { resolve } = editing;
    const { errors } = meta;

    setEditing(null);

    if (error) {
      resolve({ [FORM_ERROR]: error, ...errors });
      return;
    }

    resolve();
    setEditModal({});
    setListRefresher(+new Date());
  }, [isFetching, editing, meta, error]);

  useEffect(() => {
    if (isFetching || !deleting) {
      return;
    }

    setDeleting(false);

    if (error) {
      return;
    }

    setDeleteModal({});
    setEditModal({});
  }, [isFetching, deleting, error]);

  useEffect(() => {
    if (isFetching || !checking) {
      return;
    }

    const { resolve } = checking;

    setChecking(null);

    const { errors, shortname: inShortname } = meta;

    resolve(inShortname, errors);
  }, [isFetching, checking, meta, error]);

  useEffect(() => {
    if (!Object.keys(customerTypes).length) {
      dispatch(customerActions.requestCustomerTypes());
    }
  }, [Object.keys(customerTypes).length]);

  useEffect(() => {
    if (!samlProvider?.alias) {
      dispatch(customerActions.requestSamlProvider());
    }
  }, [samlProvider?.alias]);

  return (
    <Fragment>
      <ActionsContainer>
        <Tooltip
          title={
            canAdd
              ? 'Add a new Customer'
              : 'Only reseller can add a new Customer'
          }
        >
          <span>
            <Button
              disabled={!canAdd}
              onClick={toggleEditModal}
              testId="add-customer-button"
            >
              Add Customer
            </Button>
          </span>
        </Tooltip>
      </ActionsContainer>

      <Row>
        <Col sm={12} item container={false}>
          <CustomersTable
            data={tableData}
            shortname={customer.shortname || ''}
            toggleEditModal={toggleEditModal}
            toggleDeleteModal={toggleDeleteModal}
            impersonateAdmin={impersonateAdmin}
            permissions={permissions}
            isDisabled={isFetching}
            noDataText={customers ? undefined : lang.loading}
            onLoginToCustomer={onLoginToCustomer}
            testId="customers-table"
          />
        </Col>
      </Row>

      {editModal.show && (
        <AddEditModal
          customer={customer}
          allowShortnameEdit={customer.isReseller}
          isMe={isMe}
          isAsyncRequest={isFetching && !!checking}
          isNew={!editModal.item?.id}
          initialValues={editModal.item}
          canManage={canManage}
          toggleModal={toggleEditModal}
          onConfirm={editConfirm}
          onAsyncRequest={checkShortname}
          deleteButtonText="Delete Customer"
          onDelete={() => toggleDeleteModal(editModal.item)}
          deleteButtonHidden={!editModal.item?.id}
          deleteButtonDisabled={!canRemove}
          error={error}
          isOpen
          testId="add-edit-customer-modal"
        />
      )}

      {deleteModal.show && (
        <ConfirmModal
          item={deleteModal.shortname || null}
          onConfirm={() => remove(deleteModal.shortname)}
          toggleOnConfirm={false}
          isDisabled={isFetching}
          cancelButtonDisabled={isFetching}
          onToggle={toggleDeleteModal}
          isOpen
          testId="delete-customer-modal"
        />
      )}
    </Fragment>
  );
};

export default Customers;
