import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import isFunction from 'lodash.isfunction';

import { actions, selectors } from '@/redux/ui';

import { functionalUpdate } from '+utils/functionalUpdate';

export const useUIProperty = (property, defaultValue) => {
  const dispatch = useDispatch();
  const prop = useRef(null);

  prop.current = property || null;
  const value = useSelector(selectors.get(property)) ?? defaultValue;
  const prev = useRef(null);
  prev.current = value;

  const change = useCallback(
    (nextValue) => {
      if (prop.current == null) {
        return;
      }

      const next = isFunction(nextValue) ? nextValue(prev.current) : nextValue;

      dispatch(
        actions.change({
          [prop.current]: functionalUpdate(next, prev.current),
        }),
      );
    },
    [dispatch],
  );

  const remove = useCallback(() => {
    dispatch(actions.remove(prop.current));
  }, [dispatch]);

  return [value, change, remove];
};

export default useUIProperty;
