import { createSelector, createSlice } from '@/redux/util';

const initialState = {
  stepFirst: false,
  stepSecond: false,
  show: false,
};

const setStep = (state, name, value) => {
  state[name] = value;
};

export const slice = createSlice({
  name: 'wizard',
  initialState,

  reducers: {
    setStepFirst: (state, { payload }) => setStep(state, 'stepFirst', payload),
    setStepSecond: (state, { payload }) =>
      setStep(state, 'stepSecond', payload),
    show(state) {
      state.show = true;
    },
  },

  sagas: () => ({}),

  selectors: (getState) => ({
    getShow: createSelector(
      [getState],
      (state) => !state.dismissed && state.show,
    ),
    getFirstStep: createSelector([getState], (state) => state.stepFirst),
    getSecondStep: createSelector([getState], (state) => state.stepSecond),
  }),
});

export const { actions, selectors } = slice;

export default slice;
