import { createRoot } from 'react-dom/client';

import 'core-js/stable';

import Highcharts from 'highcharts';
import * as PIXI from 'pixi.js-legacy';

import 'regenerator-runtime/runtime';

// import './customConsole';
import App from '@/pages/App';

import { chameleon } from '+utils/chameleon';
import { logRocket } from '+utils/logRocket';

// some highcharts libraries expect access to Highcharts on window
window.Highcharts = Highcharts;

// Initialize Plugins
chameleon.init();
logRocket.init();

PIXI.utils.skipHello();

const root = createRoot(document.getElementById('app'));

root.render(<App />);
