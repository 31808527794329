import { useMemo } from 'react';

import capitalize from 'lodash.capitalize';
import styled from 'styled-components';

import GearIcon from 'mdi-react/GearIcon';

import PermissionModel from '@/models/Permission';

import { HeaderSubheaderCell, ShowMoreWrapper } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
} from '+components/Table/Columns';
import {
  BooleanColumnFilter,
  SelectColumnFilter,
} from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import Tag from '+components/Tag';
import Tooltip from '+components/Tooltip';
import sortByHelper from '+utils/sortByHelper';

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .mdi-icon + span {
    margin-left: 4px;
  }
`;

const manageActionsArr = [
  PermissionModel.Actions.create,
  PermissionModel.Actions.update,
  PermissionModel.Actions.delete,
];

const permissionsSortDataExtractor = (value) => {
  const showPermissionsGrouped = value?.length > 1;
  const resourceGroupsColor = {};

  value.forEach((val) => {
    const { resource, ...permissions } = val;
    const resourceMeta = PermissionModel.Resources[resource];
    const key = showPermissionsGrouped
      ? resourceMeta.group
      : resourceMeta.value;
    if (!resourceMeta || resourceGroupsColor[key] === 'warning') {
      // resource meta not found
      // OR this resource is for resellers only
      // OR already mixed - don't need to check again
      return;
    }

    const { fetch, ...managePermissions } = permissions;
    const readOnly = Object.values(managePermissions).every(
      (item) => item === false,
    );
    const manage = Object.values(managePermissions).every(
      (item) => item === true,
    );
    if (!readOnly && !manage) {
      // not readOnly and not manage - mixed
      resourceGroupsColor[key] = 'warning';
      return;
    }

    const color = readOnly ? 'default' : 'success';
    if (resourceGroupsColor[key] && resourceGroupsColor[key] !== color) {
      // resourceGroup already has color and this color not equal to current - mixed
      resourceGroupsColor[key] = 'warning';
      return;
    }

    resourceGroupsColor[key] = color;
  });

  return Object.entries(resourceGroupsColor)
    .map(([resourceGroup, color]) => {
      // eslint-disable-next-line no-nested-ternary
      const colorOrder = color === 'success' ? 1 : color === 'warning' ? 2 : 3;
      return `${resourceGroup}-${colorOrder}`;
    })
    .sort()
    .join('-');
};

const permissionsFilterOptions = (canManageResellerParams) =>
  Object.values(PermissionModel.Resources).reduce((acc, item) => {
    if (item.mergeWithResource) {
      return acc;
    }
    if (item.resellersOnly && !canManageResellerParams) {
      return acc;
    }
    return [
      ...acc,
      ...manageActionsArr.reduce(
        (actions, action) =>
          item.excludeActions?.includes(action)
            ? actions
            : [
                ...actions,
                {
                  value: `${item.value}-${action}`,
                  label: capitalize(action),
                  group: item.label,
                  inputValue: `${item.label} - ${capitalize(action)}`, // this will be displayed in select input
                },
              ],
        [],
      ),
    ];
  }, []);

export const getColumns = ({ canManageResellerParams, cxActionMenu }) => [
  BaseColumnFactory({
    accessor: 'system',
    Header: <GearIcon size={14} />,
    Description: 'System',
    minWidth: 60,
    maxWidth: 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    disableResizing: true,
    sortType: 'boolean',
    Cell: ({ value }) =>
      useMemo(() => {
        if (!value) {
          return null;
        }
        const cellContent = <GearIcon size={14} />;
        const tooltipContent = (
          <Content>
            {cellContent}
            <span>System Role</span>
          </Content>
        );
        return (
          <Tooltip title={tooltipContent}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {cellContent}
            </span>
          </Tooltip>
        );
      }, [value]),
    Filter: BooleanColumnFilter({
      true: 'System',
      false: 'Custom',
    }),
    filter: 'booleanFilter',
  }),
  BaseColumnFactory({
    accessor: 'name',
    Header: 'Name / Desc',
    minWidth: 160,
    Cell: HeaderSubheaderCell({
      propHeader: 'name',
      propSubheader: 'description',
    }),
    realAccessor: ['name', 'description'],
  }),
  ...(canManageResellerParams
    ? [
        BaseColumnFactory({
          accessor: 'canMasquerade',
          Header: 'Masquerading',
          minWidth: 140,
          maxWidth: 140,
          getCellProps: () => ({ style: { justifyContent: 'center' } }),
          Cell: ({ value }) =>
            useMemo(
              () =>
                value ? (
                  <Tag outlined={false} color="success">
                    Enabled
                  </Tag>
                ) : null,
              [value],
            ),
          Filter: BooleanColumnFilter({ true: 'Enabled', false: 'Disabled' }),
          filter: 'booleanFilter',
          sortType: 'boolean',
        }),
      ]
    : []),
  BaseColumnFactory({
    accessor: 'canSendFlow',
    Header: 'Send NetoFlow',
    minWidth: 140,
    maxWidth: 140,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ value }) =>
      useMemo(
        () =>
          value ? (
            <Tag outlined={false} color="success">
              Enabled
            </Tag>
          ) : null,
        [value],
      ),
    Filter: BooleanColumnFilter({ true: 'Enabled', false: 'Disabled' }),
    filter: 'booleanFilter',
    sortType: 'boolean',
  }),
  BaseColumnFactory({
    accessor: 'canFetchAuditLog',
    Header: 'View Audit Logs',
    minWidth: 140,
    maxWidth: 140,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ value }) =>
      useMemo(
        () =>
          value ? (
            <Tag outlined={false} color="success">
              Enabled
            </Tag>
          ) : null,
        [value],
      ),
    Filter: BooleanColumnFilter({ true: 'Enabled', false: 'Disabled' }),
    filter: 'booleanFilter',
    sortType: 'boolean',
  }),
  BaseColumnFactory({
    accessor: 'permissions',
    Header: 'Permissions',
    minWidth: 420,
    Cell: ({ value, row }) =>
      useMemo(() => {
        const showPermissionsGrouped = !row.subRows.length;
        // green (success) if everything is manage
        // yellow (warning) if mixed
        // gray (info) if readonly
        const resourceGroupsColor = {};

        value.forEach((val) => {
          const { resource, ...permissions } = val;
          const resourceMeta = PermissionModel.Resources[resource];
          const key = showPermissionsGrouped
            ? resourceMeta.group
            : resourceMeta.value;
          if (
            !resourceMeta ||
            (resourceMeta.resellersOnly && !canManageResellerParams) ||
            resourceGroupsColor[key] === 'warning'
          ) {
            // resource meta not found
            // OR this resource is for resellers only
            // OR already mixed - don't need to check again
            return;
          }

          const { fetch, ...managePermissions } = permissions;
          const readOnly = Object.values(managePermissions).every(
            (item) => item === false,
          );
          const manage = Object.values(managePermissions).every(
            (item) => item === true,
          );
          if (!readOnly && !manage) {
            // not readOnly and not manage - mixed
            resourceGroupsColor[key] = 'warning';
            return;
          }

          const color = readOnly ? 'default' : 'success';
          if (resourceGroupsColor[key] && resourceGroupsColor[key] !== color) {
            // resourceGroup already has color and this color not equal to current - mixed
            resourceGroupsColor[key] = 'warning';
            return;
          }

          resourceGroupsColor[key] = color;
        });

        return (
          <ShowMoreWrapper>
            {Object.entries(resourceGroupsColor)
              .map(([resourceGroup, color]) => (
                <Tag key={resourceGroup} color={color}>
                  {resourceGroup}
                </Tag>
              ))
              .sort((a, b) =>
                a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1,
              )}
          </ShowMoreWrapper>
        );
      }, [value]),
    Filter: SelectColumnFilter({
      fixedOptions: [
        { value: 'all', label: 'All' },
        ...permissionsFilterOptions(canManageResellerParams),
      ],
      sort: false,
      selectProps: {
        groupBy: (option) => option.group,
      },
    }),
    filter: withAutoRemove((rows, [id], filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      const [filterValueResource, filterValueAction] =
        filterValue.value.split('-');
      return rows.filter(({ values: { [id]: value } }) =>
        value.some((val) => {
          return val.resource === filterValueResource && val[filterValueAction];
        }),
      );
    }, autoRemoveIfAll),
    sortType: sortByHelper(permissionsSortDataExtractor),
  }),
  MenuColumnFactory({ cxActionMenu }),
];
