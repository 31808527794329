import IPCIDR from 'ip-cidr';
import { isIP, isIPv6 } from 'is-ip';
import isValidDomain from 'is-valid-domain';
import get from 'lodash.get';

import dayjs, { DateFormat } from '+utils/dayjs';

// validate : (value, allValues, props) => error [optional] #

export const validateRequired = (value) => {
  if (Array.isArray(value) && value.length > 0) {
    return null;
  }

  if (!Array.isArray(value) && value != null && `${value}`.trim() !== '') {
    return null;
  }

  return 'Required';
};

export const validateNumber = (value) =>
  value && Number.isNaN(Number(value)) ? 'Must be a number' : null;

export const validateMinValue =
  ({ min, fieldName }) =>
  (_, allValues) => {
    const value = get(allValues, fieldName);
    if (value == null) {
      return null;
    }
    return value < min ? `Must be at least ${min}` : null;
  };

export const validateMaxValue =
  ({ max, fieldName }) =>
  (_, allValues) => {
    const value = get(allValues, fieldName);
    if (value == null) {
      return null;
    }
    return value > max ? `Must not be greater than ${max}` : null;
  };

export const validateMinMaxValue =
  ({ min, max, fieldName }) =>
  (_, allValues) => {
    const value = get(allValues, fieldName);
    if (value == null) {
      return null;
    }
    return (
      validateMinValue({ min, fieldName })(null, allValues) ||
      validateMaxValue({ max, fieldName })(null, allValues)
    );
  };

export const validateApiKeyName = (value) => {
  if (!(value || '').trim()) {
    return null;
  }

  if (!/^[A-Z0-9._%+-]+$/i.test(value)) {
    return 'Not a valid application name. Only RFC 6531 (email) characters are allowed.';
  }

  return null;
};

export const validateRoleName = (value) => {
  if (!(value || '').trim()) {
    return null;
  }

  if (!/^[A-Z0-9._%+-]+$/i.test(value)) {
    return 'Not a valid role name. Only RFC 6531 (email) characters are allowed.';
  }

  return null;
};

export const validateEmail = (value) => {
  if (!(value || '').trim()) {
    return null;
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    return 'Not a valid email address';
  }

  return null;
};

export const validateEmails = (values) => {
  if (!values) {
    return null;
  }

  const emails = Array.isArray(values)
    ? values
    : values.split(',').map((item) => item.trim());

  const invalidEmails = [];
  emails.forEach((item, i) => {
    const error = item ? validateEmail(item) : 'Email shouldn’t be empty';
    if (error) {
      invalidEmails.push(`${i + 1}. ${error}`);
    }
  });

  if (invalidEmails.length > 0) {
    return invalidEmails.join(', ');
  }

  return null;
};

export const validateIp = (value) => {
  const normalizedValue = (value || '').trim();
  if (!normalizedValue) {
    return null;
  }

  if (!isIP(normalizedValue)) {
    return `'${value}' is not a valid IP Address`;
  }

  return null;
};

export const validateIps = (values) => {
  if (!values) {
    return null;
  }

  const ips = Array.isArray(values)
    ? values
    : values.split(',').map((item) => item.trim());

  const invalidIps = [];
  ips.forEach((item, i) => {
    const error = item ? validateIp(item) : 'IP Address shouldn’t be empty';
    if (error) {
      invalidIps.push(`${i + 1}: ${error}`);
    }
  });

  if (invalidIps.length > 0) {
    return invalidIps.join(', ');
  }

  return null;
};

export const validateCidr = (value) => {
  const normalizedValue = (value || '').trim();
  if (!normalizedValue) {
    return null;
  }

  if (!IPCIDR.isValidAddress(normalizedValue)) {
    return `'${value}' is not valid CIDR Notation`;
  }

  return null;
};

export const validateCidrs = (values) => {
  if (!values) {
    return null;
  }

  const ips = Array.isArray(values)
    ? values
    : values.split(',').map((item) => item.trim());

  const invalidIps = [];
  ips.forEach((item, i) => {
    const error = item
      ? validateCidr(item)
      : 'CIDR Notation shouldn’t be empty';
    if (error) {
      invalidIps.push(`${i + 1}. ${error}`);
    }
  });

  if (invalidIps.length > 0) {
    return invalidIps.join(', ');
  }

  return null;
};

export const validateIpOrCidr = (value) => {
  const normalizedValue = (value || '').trim();
  if (!normalizedValue) {
    return null;
  }
  const validator = normalizedValue.includes('/') ? validateCidr : validateIp;
  return validator(normalizedValue);
};

export const validateIpOrCidrs = (values) => {
  if (!values) {
    return null;
  }

  const ips = Array.isArray(values)
    ? values
    : values.split(',').map((item) => item.trim());

  const invalidIps = [];
  ips.forEach((item, i) => {
    const normalizedItem = (item || '').trim();
    const validator = normalizedItem.includes('/') ? validateCidr : validateIp;
    const error = item ? validator(item) : 'IP/CIDR Address shouldn’t be empty';
    if (error) {
      invalidIps.push(`${i + 1}: ${error}`);
    }
  });

  if (invalidIps.length > 0) {
    return invalidIps.join(', ');
  }

  return null;
};

export const validateIpV6 = (value) => {
  const normalizedValue = (value || '').trim();
  if (!normalizedValue) {
    return null;
  }

  if (!isIP(value) && !isIPv6(value)) {
    return `${value} is not a valid IPv6 address`;
  }

  return null;
};

export const validateCombinedWhitelist = (values) => {
  if (!values) {
    return null;
  }
  const ips = values.filter((item) => item.substring(0, 3) !== 'id:');
  return validateIpOrCidrs(ips);
};

export const validateASNs = (values) => {
  if (!values) {
    return null;
  }

  const asns = Array.isArray(values)
    ? values
    : values.split(',').map((item) => item.trim());

  // Cannot be floats and must be numeric
  const invalidASNs = [];
  asns.forEach((value, i) => {
    if (!Number.isInteger(Number(value))) {
      invalidASNs.push(`${i + 1}. '${value}' is not an integer`);
    }
  });

  if (invalidASNs.length > 0) {
    return invalidASNs.join(', ');
  }

  return null;
};

export const validateShortname = (value) => {
  if (!value) {
    return null;
  }

  if (value.length < 5) {
    return 'Must have 5 or more characters';
  }

  if (value.length > 16) {
    return 'Must be less than 16 characters';
  }

  if (value !== encodeURIComponent(value)) {
    return 'Characters must be RFC 3986 compliant';
  }

  return null;
};

export const validatePort = (value) => {
  const numberValidation = validateNumber(value);
  if (numberValidation) {
    return numberValidation;
  }

  const v = parseInt(value, 10);
  if (v < 0) {
    return 'Must be equal or greater than 0';
  }

  if (v > 65535) {
    return 'Must be less than 65535';
  }

  return null;
};

export const validatePorts = (values) => {
  if (!values) {
    return null;
  }

  const ports = Array.isArray(values)
    ? values
    : values.split(',').map((item) => item.trim());

  const invalidPorts = [];
  ports.forEach((item, i) => {
    const error = item ? validatePort(item) : 'Port shouldn’t be empty';
    if (error) {
      invalidPorts.push(`${i + 1}. ${error}`);
    }
  });

  if (invalidPorts.length > 0) {
    return invalidPorts.join(', ');
  }

  return null;
};

export const validateDownSampleRate = (value) => {
  const v = parseInt(value, 10);
  if (v < 0) {
    return 'Must be equal or greater than 0';
  }
  if (v > 65535) {
    return 'Must be less than 65535';
  }
  return null;
};

export const validateSampleRate = (value) => {
  const v = parseInt(value, 10);
  if (v < 1) {
    return 'Must be greater than 0';
  }
  if (v > 65535) {
    return 'Must be less than 65535';
  }
  return null;
};

export const minLocalprefValue = validateMinValue(0);
export const maxLocalprefValue = validateMaxValue(4294967295);

export const validateUrl = (value) => {
  if (!value) {
    return null;
  }

  try {
    // eslint-disable-next-line no-new
    new URL(value);
  } catch (_) {
    return `'${value}' is not a valid URL`;
  }

  return null;
};

export const validateTcpFlags = (value) => {
  if (Number.isNaN(value)) {
    return 'Must be numeric';
  }
  const v = parseInt(value, 10);
  if (v < 0) {
    return 'Must be greater than or equal to 0';
  }
  if (v > 255) {
    return 'Must be less than 255';
  }
  return null;
};

export const validateDateTime = (value) => {
  if (value == null) {
    return null;
  }

  const fixedValue = dayjs(value);
  const isValid = fixedValue.isValid();
  return isValid ? null : 'Not a valid date and time';
};

export const validateLabel = (value) => {
  // Valid: a-z A-Z 0-9 ._/\\-#~:()
  const labelRegex = /[^a-zA-Z0-9 ._/\-#~:()]/;

  if (!(value || '').trim()) {
    return null;
  }

  if ((value || '').trim().length > 80) {
    return 'Label exceeds maximum length of 80';
  }

  if (labelRegex.test(value)) {
    return 'Label contains invalid characters.';
  }

  return null;
};

export const validateLabels = (values) => {
  const labels = Array.isArray(values)
    ? values
    : values?.split(',').map((item) => item.trim());

  if (!labels) {
    return null;
  }

  const invalidLabels = [];
  labels.forEach((item, i) => {
    const error = item.trim()
      ? validateLabel(item)
      : 'Label should not be empty';
    if (error) {
      invalidLabels.push(`${i + 1}. ${error}`);
    }
  });

  if (invalidLabels.length > 0) {
    return invalidLabels.join(', ');
  }

  return null;
};

/**
 * Validate field value with expected value or with value of fieldName.
 * one of unexpected or fieldName must be passed.
 * @param props
 * @param {Date|string|number} [props.unexpected] - unexpected value.
 * @param {string} [props.fieldName] - Name of field to get value from.
 * @param {string} [props.errorMessage] - error message if the same.
 * @return {string|null}
 */
export const validateDateTimeCannotBeSame = (props) => (value, allValues) => {
  const { unexpected, fieldName, errorMessage } = props;

  let fixedUnexpected = unexpected || allValues?.[fieldName];

  if (!value || !fixedUnexpected) {
    return null;
  }

  const fixedValue = dayjs(value);
  fixedUnexpected = dayjs(fixedUnexpected);

  if (!(fixedValue.isValid() && fixedUnexpected.isValid())) {
    return null;
  }

  return fixedValue.isSame(fixedUnexpected)
    ? errorMessage ||
        `Cannot be the same as ${
          fieldName || fixedUnexpected.format(DateFormat.second)
        } value`
    : null;
};

/**
 * Validate date & time between to dates or fields values
 * @param {Object} props
 * @param {(Date|string|number)} props.min - min date & time value
 * @param {(Date|string|number)} props.max - max date & time value
 * @param {string} [props.minFieldName] - field name for min value (min param should be undefined)
 * @param {string} [props.maxFieldName] - field name for max value (max param should be undefined)
 * @param {boolean} [props.includeMin] - if true, value should be _equal_ or greater then min
 * @param {boolean} [props.includeMax] - if true, value should be _equal_ or less then max
 * @returns {Function}
 */
// [Final- Form Bug] Conditional validate prop
// @see: https://github.com/final-form/react-final-form/issues/372
export const validateDateTimeBetween = (props) => (value, allValues) => {
  const {
    min,
    max,
    minFieldName,
    maxFieldName,
    includeMin = false,
    includeMax = false,
  } = props || {};
  const _min = min || allValues?.[minFieldName];
  const _max = max || allValues?.[maxFieldName];

  if (!value || !_min || !_max) {
    return null;
  }

  const fixedValue = dayjs(value);
  const fixedMin = dayjs(_min);
  const fixedMax = dayjs(_max);

  if (!fixedValue.isValid() || !fixedMin.isValid() || !fixedMax.isValid()) {
    return null;
  }

  const inclusivity = `${includeMin ? '[' : '('}${includeMax ? ']' : ')'}`;
  const isBetween = fixedValue.isBetween(
    fixedMin,
    fixedMax,
    undefined,
    inclusivity,
  );
  return isBetween
    ? null
    : `Value must be between ${fixedMin.format(
        DateFormat.second,
      )} and ${fixedMax.format(DateFormat.second)}`;
};

export const validateCve = (value) => {
  if (!(value || '').trim()) {
    return null;
  }

  if (!/(cve|CVE)-[0-9]{4}-[0-9]{4,}$/g.test(value)) {
    return 'Not a valid CVE';
  }

  return null;
};

export const validateTransformJson = (value) => {
  let obj = {};
  try {
    obj = JSON.parse(value);
  } catch (e) {
    return 'Invalid JSON';
  }
  return obj?.transforms ? null : 'Missing top-level transforms field in JSON';
};

export const validateTextJsonArray = (value) => {
  let obj = {};
  try {
    obj = JSON.parse(value);
  } catch (e) {
    return 'Invalid JSON';
  }
  if (!Array.isArray(obj)) {
    return 'Value must be an array';
  }
  return null;
};

export const validateDomain = (value) => {
  const fixed = (value || '').trim();
  if (!fixed) {
    return null;
  }

  const isDomain = isValidDomain(value, { allowUnicode: true });

  if (!isDomain) {
    return `${value} is not a valid domain name`;
  }

  return null;
};

export const validateDomains = (values) => {
  if (!values) {
    return null;
  }

  const domains = Array.isArray(values)
    ? values
    : values.split(',').map((item) => item.trim());

  const invalidDomains = [];

  domains.forEach((item, i) => {
    const error = item ? validateDomain(item) : 'Domain shouldn’t be empty';

    if (error) {
      invalidDomains.push(`${i + 1}. ${error}`);
    }
  });

  if (invalidDomains.length > 0) {
    return invalidDomains.join(', ');
  }

  return null;
};
