/* eslint-disable react/prop-types */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import {
  actions as contextActions,
  selectors as contextSelectors,
} from '@/redux/api/integrations/context';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { TtouModal } from '@/pages/Layout';

import Alert from '+components/Alert';
import Button, { ButtonVariants } from '+components/Button';
import CopyText from '+components/CopyText';
import FieldsSection from '+components/form/FieldsSection';
import {
  Field,
  FinalForm,
  useForm,
  useFormState,
} from '+components/form/FinalForm';
import Form from '+components/form/Form';
import { Description, Group, Label } from '+components/form/FormField';
import Plaintext from '+components/form/Plaintext';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import { Col, Row } from '+components/Layout';
import Tag from '+components/Tag';
import useCustomerSubscriptionTypes from '+hooks/useCustomerSubscriptionTypes';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useMaxSources from '+hooks/useMaxSources';
import usePageTabsAndFormSync from '+hooks/usePageTabsAndFormSync';
import usePermissions from '+hooks/usePermissions';
import useUIProperty from '+hooks/useUIProperty';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';
import dayjs, { DateFormat } from '+utils/dayjs';
import { getSsoLoginUrl } from '+utils/portalUrlHelper';

import PropTypes from '../../shared/prop-types';
import Container from './components/Container';
import ResellerAccessSection from './components/ResellerAccessSection';

const systemAllowlists = {
  pollers: ['100.25.189.39', '3.209.62.136', '35.168.144.18'],
  bgp: ['3.210.210.195', '34.232.43.129'],
  integrations: ['35.168.144.18'],
};

const AllowListName = styled(Row)`
  font-size: 12px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colorTextSecondary} !important;
`;

const SettingsForm = styled(Form)`
  width: 720px;
`;

const TrustExampleContainer = styled.div`
  width: 600px;
  display: flex;
`;

const JsonPlainText = styled(Plaintext)`
  margin-top: 10px !important;
  position: relative;
  padding: 8px 10px !important;
  border-radius: 4px;
  background: ${({ theme }) => theme.colorFieldBackground};
  width: 100% !important;
  white-space: pre;
  font-family: 'Source Code Pro', monospace;
  font-size: 0.8em !important;

  > div {
    width: 100%;
  }

  .copy-to-clipboard__content {
    ${ScrollBarMixin};
    width: 100%;
    height: 80px;
    overflow: auto;
  }
`;

const FormBody = (props) => {
  const { customer, samlProvider, ingestIp, disabled, handleSubmit } = props;
  const dispatch = useDispatch();
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  usePageTabsAndFormSync();

  const form = useForm();
  const { values, submitting } = useFormState({
    subscription: { values: true, submitting: true },
  });

  const portalSsoLoginUrl = getSsoLoginUrl(samlProvider?.alias);
  const shortname = customer?.shortname || 'N/A';
  const retention = customer?.retention ? `${customer.retention} days` : 'N/A';
  const rollupRetention = customer?.rollupRetention
    ? `${customer.rollupRetention} days`
    : 'N/A';
  const { enforceMaxSources, sourcesRemaining } = useMaxSources();
  const awsAccountId = useSelector(contextSelectors.getAwsAccountId);
  const externalId = useSelector(contextSelectors.getExternalId);
  const trustPolicyExample = useSelector(
    contextSelectors.getTrustPolicyExample,
  );
  useEffect(() => {
    if (Object.keys(values).length > 0) {
      return;
    }

    form.initialize(customer);
  }, [form, customer, values]);

  useEffect(() => {
    dispatch(contextActions.fetchAwsRoleData());
  }, []);

  return (
    <SettingsForm style={{ width: '720px' }} onSubmit={handleSubmit}>
      <Group>
        <Label>Shortname</Label>
        <Plaintext>{shortname}</Plaintext>
        <Description>A unique identifier for your account.</Description>
      </Group>

      <Row wrap="nowrap" gap="14px">
        <Field
          name="organization"
          label="Organization"
          component={TextField}
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Button type="submit" disabled={disabled || submitting}>
          Save
        </Button>
      </Row>

      {samlProvider?.alias && (
        <Group>
          <Label>SSO Login URL</Label>
          <Plaintext>{portalSsoLoginUrl}</Plaintext>
          <Description>Account-specific URL for your SSO logins.</Description>
        </Group>
      )}

      <Group>
        <Label>Ingest IP</Label>
        <Plaintext>{ingestIp}</Plaintext>
      </Group>

      <Group>
        <Label>System Allow Lists</Label>
        <Plaintext style={{ marginTop: '9px' }}>
          <Row wrap="nowrap" gap="72px">
            {Object.entries(systemAllowlists).map(([name, ips]) => (
              <Col key={name} gap="6px">
                <AllowListName>{name}</AllowListName>
                {ips.map((ip) => (
                  <Row key={`${name}-${ip}`}>{ip}</Row>
                ))}
              </Col>
            ))}
          </Row>
        </Plaintext>
      </Group>

      <Group>
        <Label>Data Retention</Label>
        <Plaintext>{retention}</Plaintext>
      </Group>

      <Group>
        <Label>Rollup Retention</Label>
        <Plaintext>{rollupRetention}</Plaintext>
      </Group>

      {enforceMaxSources && (
        <Group>
          <Label>{isDnsEnabled ? 'Traffic' : 'Flow'} Source Quota</Label>
          <Plaintext>
            <Tag color="secondary" outlined={false}>
              {sourcesRemaining} Remaining
            </Tag>
          </Plaintext>
        </Group>
      )}

      {customer?.parent && customer?.parent !== 'netography' && (
        <ResellerAccessSection customer={customer} disabled={disabled} />
      )}

      <FieldsSection label="AWS Custom Trust Policy" formLabelMargin>
        <Group>
          <Label>Account ID</Label>
          <Plaintext>{awsAccountId}</Plaintext>
          <Description>Netography AWS Account ID</Description>
        </Group>

        <Group>
          <Label>sts:ExternalId</Label>
          <Plaintext>{externalId}</Plaintext>
          <Description>
            Netography-issued External ID for AWS role creation
          </Description>
        </Group>

        <Group>
          <Label>Trust Policy</Label>
          <TrustExampleContainer>
            <JsonPlainText>
              <CopyText text={trustPolicyExample ?? ''}>
                {trustPolicyExample
                  ? JSON.stringify(JSON.parse(trustPolicyExample), null, 2)
                  : ''}
              </CopyText>
            </JsonPlainText>
          </TrustExampleContainer>
        </Group>
      </FieldsSection>
    </SettingsForm>
  );
};

FormBody.propTypes = {
  customer: PropTypes.shape().isRequired,
  samlProvider: PropTypes.shape().isRequired,
  ingestIp: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const AccountOverview = () => {
  const dispatch = useDispatch();

  const permissions = usePermissions(PermissionModel.Resources.account.value);
  const [isUnderCovered] = useUIProperty('underCover');
  const [isImpersonating] = useUIProperty('impersonating');

  const isFetching = useSelector(customerSelectors.isFetching);
  const customerSubscriptionTypes = useCustomerSubscriptionTypes();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const ingestIp = useSelector(customerSelectors.getCollectorIp);
  const samlProvider = useSelector(customerSelectors.getSamlProvider);
  const isAdmin = useSelector(profileSelectors.isAdminUser);

  const [showTtouModal, setShowTtouModal] = useState(false);

  useLoadingIndicator(isFetching);

  const canAcceptTtou = useMemo(() => {
    const isMasquerading = isUnderCovered || isImpersonating;
    return !customer.accepted_ttou && isAdmin && !isMasquerading;
  }, [customer.accepted_ttou, isAdmin, isUnderCovered, isImpersonating]);

  const onShowTtouModal = useCallback(() => {
    setShowTtouModal(true);
  }, []);

  const onSubmit = useCallback((values) => {
    const { id, organization, shortname } = values;
    const data = { id, organization };
    dispatch(customerActions.update({ shortname, data }));
  }, []);

  useEffect(() => {
    if (showTtouModal && customer.accepted_ttou) {
      setShowTtouModal(false);
    }
  }, [showTtouModal, customer.accepted_ttou]);

  useEffect(() => {
    if (!samlProvider?.alias) {
      dispatch(customerActions.requestSamlProvider());
    }
  }, [samlProvider?.alias]);

  const customerSubscriptionTypeMeta =
    customerSubscriptionTypes[customer?.subscriptionType];

  return (
    <Container>
      {customerSubscriptionTypeMeta?.trial && (
        <Alert style={{ marginLeft: '140px', width: '580px' }}>
          <Row style={{ width: '520px' }}>
            <Col
              xs={!customer.accepted_ttou && isAdmin ? 10 : 12}
              item
              container={false}
            >
              Free trial account.
              <br />
              {customer.accepted_ttou ? (
                <Fragment>
                  The{' '}
                  <a
                    href="https://netography.com/free-trial-terms/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Free Trial Services Terms
                  </a>{' '}
                  were accepted by {customer.accepted_ttou.email} on{' '}
                  {dayjs(customer.accepted_ttou.datetime).format(
                    DateFormat.day,
                  )}
                  .
                </Fragment>
              ) : (
                <Fragment>
                  The{' '}
                  <a
                    href="https://netography.com/free-trial-terms/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Free Trial Services Terms
                  </a>{' '}
                  have not been accepted.
                </Fragment>
              )}
            </Col>
            {canAcceptTtou && (
              <Col xs item alignItems="center" justifyContent="center">
                <Button
                  type="button"
                  variant={ButtonVariants.text}
                  onClick={onShowTtouModal}
                >
                  Accept
                </Button>
              </Col>
            )}
          </Row>
        </Alert>
      )}

      <FinalForm
        onSubmit={onSubmit}
        component={FormBody}
        customer={customer}
        samlProvider={samlProvider}
        ingestIp={ingestIp}
        disabled={isFetching || !permissions?.update}
        keepDirtyOnReinitialize
      />

      {showTtouModal && <TtouModal isOpen />}
    </Container>
  );
};

export default AccountOverview;
