import PropTypes from '+prop-types';
import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import { useAuthProfile } from '+hooks/useAuthProfile';

const PreloadSources = ({ children }) => {
  const dispatch = useDispatch();

  const isVpcFetching = useSelector(vpcSelectors.isFetching);
  const isDeviceFetching = useSelector(deviceSelectors.isFetching);

  const startedFetch = useRef(false);
  const isFetching = useRef(true);

  useEffect(() => {
    startedFetch.current = true;
    dispatch(vpcActions.fetchVpcs());
    dispatch(deviceActions.devicesFetch());
  }, []);

  if (isFetching.current) {
    isFetching.current =
      !startedFetch.current || isVpcFetching || isDeviceFetching;
  }

  return isFetching.current ? null : children;
};
PreloadSources.propTypes = {
  children: PropTypes.children.isRequired,
};

const HasSourcesState = ({ children }) => {
  const { isDefault } = useAuthProfile();

  const Wrapper = isDefault ? Fragment : PreloadSources;

  return <Wrapper>{children}</Wrapper>;
};
HasSourcesState.propTypes = {
  children: PropTypes.children.isRequired,
};

export const PreloadState = (props) => <HasSourcesState {...props} />;
