import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { ColorTypes } from '@/models/ColorTypes';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

import palette from '@/shared/theme/palette';

import Alert from '+components/Alert';
import ConfirmModal from '+components/ConfirmModal';
import FieldsRow from '+components/form/FieldsRow';
import { Field, useForm, useFormState } from '+components/form/FinalForm';
import { Description, FieldContainer, Group } from '+components/form/FormField';
import Label from '+components/form/FormField/components/Label';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RadioButtonField from '+components/form/RadioButton';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import Toggle, { ToggleField } from '+components/form/Toggle';
import {
  validateEmail,
  validateRequired,
  validateShortname,
} from '+components/form/Validators';
import FormModal from '+components/FormModal';
import GlobalLink from '+components/GlobalLink';
import useCustomerSubscriptionTypes from '+hooks/useCustomerSubscriptionTypes';

const retentions = [
  {
    value: 7,
    label: '7 days',
  },
  {
    value: 45,
    label: '45 days',
  },
  {
    value: 90,
    label: '90 days',
  },
  {
    value: 180,
    label: '180 days',
  },
];

const rollupRetentions = [
  {
    value: 7,
    label: '7 days',
  },
  {
    value: 90,
    label: '90 days',
  },
  {
    value: 180,
    label: '180 days',
  },
  {
    value: 365,
    label: '365 days',
  },
];

const RadioLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 7px;
  ${({ disabled }) => disabled && `color: ${palette.gray4};`}
`;

const DecorativeLine = styled.div`
  height: 1px;
  border-bottom: 1px solid ${palette.gray4};
  flex-grow: 2;
  margin-left: 10px;
`;

const Spacer = styled.div`
  margin-bottom: 10px;
`;

const FormBody = (props) => {
  const { isNew, isMe, canManage, error, allowShortnameEdit, onAsyncRequest } =
    props;

  const dispatch = useDispatch();

  const customerTypes = useSelector(customerSelectors.getCustomerTypes);
  const customerSubscriptionTypes = useCustomerSubscriptionTypes();
  const samlProvider = useSelector(customerSelectors.getSamlProvider);

  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const [showUseResellerSsoModal, setShowUseResellerSsoModal] = useState(false);

  const onUseResellerSso = useCallback(() => {
    setShowUseResellerSsoModal(true);
  }, []);

  const onUseResellerSsoCancel = useCallback(() => {
    setShowUseResellerSsoModal(false);
    // form.change('useResellerSso', lastResellerSsoValue.current);
  }, []);

  const onUseResellerSsoConfirm = useCallback(() => {
    setShowUseResellerSsoModal(false);
    form.change('useResellerSso', !formValues.useResellerSso);
  }, [formValues.useResellerSso]);

  const lastShortname = useRef();
  const lastShortnameError = useRef();
  const shortnameValidate = useCallback(
    (newValue, values, { name: field }) => {
      if (!values || (!isNew && !allowShortnameEdit)) {
        return null;
      }

      if (
        (field === 'organization' && values.shortname) ||
        !isNew ||
        !allowShortnameEdit
      ) {
        return null;
      }

      if (field === 'shortname') {
        if (lastShortname.current === newValue) {
          return lastShortnameError.current;
        }

        lastShortname.current = newValue;
      }

      return new Promise((resolve) => {
        const doResolve = (value, errors) => {
          if (value) {
            form.pauseValidation();
            lastShortname.current = value;
            form.change('shortname', value);
            form.resumeValidation();
          }

          if (errors?.shortname) {
            lastShortnameError.current = errors.shortname;
            form.mutators.touched('shortname', true);
            resolve(errors.shortname);
            return;
          }

          lastShortnameError.current = null;

          resolve(null);
        };

        onAsyncRequest(
          {
            ...values,
            [field]: newValue,
          },
          doResolve,
        );
      });
    },
    [onAsyncRequest, isNew, allowShortnameEdit],
  );

  useEffect(() => {
    const customerSubscriptionTypeMeta =
      customerSubscriptionTypes[formValues.subscriptionType];
    if (
      customerSubscriptionTypeMeta.retention != null &&
      customerSubscriptionTypeMeta.retention !== formValues.retention
    ) {
      form.change('retention', customerSubscriptionTypeMeta.retention);
    }
    if (
      customerSubscriptionTypeMeta.rollupRetention != null &&
      customerSubscriptionTypeMeta.rollupRetention !==
        formValues.rollupRetention
    ) {
      form.change(
        'rollupRetention',
        customerSubscriptionTypeMeta.rollupRetention,
      );
    }
  }, [
    formValues.subscriptionType,
    formValues.retention,
    formValues.rollupRetention,
  ]);

  useEffect(() => {
    if (formValues.useResellerSso) {
      form.change('username', null);
      form.change('given_name', null);
      form.change('family_name', null);
    }
  }, [formValues.useResellerSso]);

  useEffect(() => {
    if (!samlProvider?.alias) {
      dispatch(customerActions.requestSamlProvider());
    }
  }, [samlProvider?.alias]);

  const isRetentionEditable =
    customerSubscriptionTypes[formValues.subscriptionType]?.retention == null;

  return (
    <Fragment>
      {error && <Alert severity="error">{error}</Alert>}

      <Field required name="id" component="input" type="hidden" />

      {isNew && !formValues?.useResellerSso && (
        <Fragment>
          <Field
            name="username"
            component={TextField}
            type="email"
            label="Email"
            validate={[validateRequired, validateEmail]}
            disabled={!canManage}
            required
          />
          <FieldsRow>
            <Field
              name="given_name"
              component={TextField}
              label="Given Name"
              validate={validateRequired}
              disabled={!canManage}
              required
            />
            <Field
              name="family_name"
              component={TextField}
              label="Family Name"
              validate={validateRequired}
              disabled={!canManage}
              required
            />
          </FieldsRow>
        </Fragment>
      )}

      <FieldsRow>
        <Field
          name="organization"
          component={TextField}
          label="Organization"
          validate={[validateRequired, isNew && shortnameValidate]}
          validationDebounceTimeout={1000}
          disabled={!canManage}
          required
        />

        {isNew && (
          <Field
            disabled={!allowShortnameEdit || !canManage}
            name="shortname"
            component={TextField}
            minLength={5}
            maxLength={16}
            label="Shortname"
            validate={[validateRequired, validateShortname, shortnameValidate]}
            validationDebounceTimeout={1000}
            helperText={
              allowShortnameEdit && (
                <div>
                  <div>Length between 5-16</div>
                  <div>
                    <GlobalLink
                      href="https://tools.ietf.org/html/rfc3986"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      RFC 3986
                    </GlobalLink>{' '}
                    characters only , excluding plus (&quot;+&quot;)
                  </div>
                </div>
              )
            }
            required={allowShortnameEdit}
          />
        )}
      </FieldsRow>

      <FieldsRow>
        <Field
          name="type"
          component={SelectField}
          label="Type"
          options={Object.values(customerTypes)}
          parse={normalizeSelectValue}
          validate={validateRequired}
          disabled={!!isMe || !!formValues.directCustomers || !canManage}
          required
        />
        <Field
          name="fps"
          component={TextField}
          type="number"
          label="Contractual Flows per Second"
          disabled={!canManage}
          parse={(value) => value}
        />
      </FieldsRow>

      <Spacer />

      <RadioLabelContainer>
        <Label required>Subscription</Label>
        <DecorativeLine />
      </RadioLabelContainer>
      <FieldsRow>
        {Object.values(customerSubscriptionTypes).map((item) => (
          <Field
            key={item.value}
            id={`radio_subscription_${item.value}`}
            name="subscriptionType"
            disabled={!!isMe || !canManage}
            component={RadioButtonField}
            radioValue={item.value}
            label={item.label}
            required
          />
        ))}
      </FieldsRow>
      <Spacer />
      <Description>
        {customerSubscriptionTypes[formValues.subscriptionType]?.description}
      </Description>

      <Spacer />

      <Fragment>
        <RadioLabelContainer disabled={!isRetentionEditable}>
          <Label required>Data Retention</Label>
          <DecorativeLine />
        </RadioLabelContainer>
        <FieldsRow>
          {retentions.map((item) => (
            <Field
              key={item.value}
              id={`radio_retention_${item.value}`}
              name="retention"
              disabled={!!isMe || !canManage || !isRetentionEditable}
              component={RadioButtonField}
              radioValue={item.value}
              label={item.label}
              required
            />
          ))}
        </FieldsRow>
      </Fragment>

      <Fragment>
        <RadioLabelContainer disabled={!isRetentionEditable}>
          <Label required>Rollup Retention</Label>
          <DecorativeLine />
        </RadioLabelContainer>
        <FieldsRow>
          {rollupRetentions.map((item) => (
            <Field
              key={item.value}
              id={`radio__rollup_retention_${item.value}`}
              name="rollupRetention"
              disabled={!!isMe || !canManage || !isRetentionEditable}
              component={RadioButtonField}
              radioValue={item.value}
              label={item.label}
              required
            />
          ))}
        </FieldsRow>
      </Fragment>

      <Spacer />

      {isNew && samlProvider.resellerSso && (
        <Field
          name="useResellerSso"
          component={ToggleField}
          type="checkbox"
          checkedLabel="Use Parent SSO"
          helperText={`Customer users can authenticate against ${samlProvider.alias} SSO configuration`}
        />
      )}

      {!isNew && samlProvider.resellerSso && (
        <Group>
          <FieldContainer>
            <Toggle
              checkedLabel="Use Parent SSO"
              checked={formValues.useResellerSso}
              onChange={onUseResellerSso}
            />
          </FieldContainer>
          <Description>
            Customer users can authenticate against {samlProvider.alias} SSO
            configuration
          </Description>
        </Group>
      )}

      {showUseResellerSsoModal && (
        <ConfirmModal
          item="Parent SSO"
          confirmButtonText={formValues.useResellerSso ? 'Disable' : 'Enable'}
          confirmButtonColor={ColorTypes.primary}
          whyAsking={
            formValues.useResellerSso
              ? 'Parent Single Sign-on will be disabled, all linked users will be removed and required to sign in via their email and password.'
              : `Single Sign-on will be controlled by parent ${samlProvider.alias} configuration.`
          }
          onToggle={onUseResellerSsoCancel}
          onConfirm={onUseResellerSsoConfirm}
          isOpen
        />
      )}
    </Fragment>
  );
};

const propTypes = {
  onAsyncRequest: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  allowShortnameEdit: PropTypes.bool,
  isMe: PropTypes.bool,
  error: PropTypes.string,
  canManage: PropTypes.bool,
};

const defaultProps = {
  error: null,
  allowShortnameEdit: false,
  isMe: false,
  canManage: false,
};

FormBody.propTypes = propTypes;
FormBody.defaultProps = defaultProps;

const EditCustomerForm = (props) => {
  const {
    isOpen,
    isDisabled,
    isMe,
    isNew,
    canManage,
    error,
    isAsyncRequest,
    initialValues,
    allowShortnameEdit,
    onConfirm,
    onAsyncRequest,
    ...tail
  } = props;

  const { shortname } = initialValues || {};

  const confirm = useCallback(
    (cancel = true, newShortname = '', values = null) => {
      const promise = onConfirm(cancel, newShortname, values);

      return cancel ? promise.catch(() => {}) : promise;
    },
    [onConfirm],
  );

  const handleModalSubmit = useCallback(
    (values) => confirm(false, shortname, values),
    [confirm, shortname],
  );

  return (
    <FormModal
      {...tail}
      mode={isNew ? 'add' : 'edit'}
      item="customer"
      isOpen={isOpen}
      initialValues={initialValues}
      loading={isAsyncRequest}
      disabled={!canManage}
      onSubmit={handleModalSubmit}
      onToggle={confirm}
      labelOnTop
    >
      <FormBody
        isNew={isNew}
        isMe={isMe}
        canManage={canManage}
        error={error}
        allowShortnameEdit={allowShortnameEdit}
        onAsyncRequest={onAsyncRequest}
      />
    </FormModal>
  );
};

EditCustomerForm.propTypes = {
  ...propTypes,
  isOpen: PropTypes.bool.isRequired,
  isAsyncRequest: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  permissions: PropTypes.shape(),
};
EditCustomerForm.defaultProps = {
  ...defaultProps,
  isDisabled: false,
  permissions: null,
};

export default EditCustomerForm;
