import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import FeatureFlags from '@/models/FeatureFlags';
import RoutePaths from '@/models/RoutePaths';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';
import {
  actions as thresholdActions,
  selectors as thresholdSelectors,
} from '@/redux/api/thresholder';

import AutoThresholds from '../AutoThresholds';
import CommonEdit from '../shared/CommonEdit';
import { uiToAutoThresholdParams, uiToParams } from '../shared/utils';
import ThresholdsOverrides from '../ThresholdsOverrides';
import { autoThresholdDefaults } from './Add';
import FormBody from './FormBody';

const methods = {
  fetch: rulesActions.fetchAlgorithm,
  update: rulesActions.updateAlgorithm,
  remove: rulesActions.deleteAlgorithm,
  reset: rulesActions.resetAlgorithm,
  get: rulesSelectors.getAlgorithm,
};

const AlgorithmEdit = () => {
  const dispatch = useDispatch();
  const { algorithmId } = useParams();
  const autoThresholdsFeatureFlag = useFlag(FeatureFlags.autoThresholds);

  const algorithm = useSelector(methods.get(algorithmId));
  const thresholdAutomaton = useSelector(
    thresholdSelectors.getAutomaton(algorithm?.name),
  );
  // default threshold values from backend, if we find these we should overwrite portal defaults
  const defaultThresholdConfig = useSelector(
    thresholdSelectors.getDefaultConfig(algorithm?.name),
  );

  const updatedAlgorithm = useSelector(
    rulesSelectors.getAlgorithm(algorithmId),
  );
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const isFetching = useSelector(thresholdSelectors.isFetching);

  const [submitValues, setSubmitValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (algorithm?.name && !thresholdAutomaton && autoThresholdsFeatureFlag) {
      dispatch(
        thresholdActions.fetchDefaultConfig({
          name: algorithm.name,
        }),
      );
    }
  }, [algorithm, thresholdAutomaton, autoThresholdsFeatureFlag]);

  const additionalTabs = useMemo(() => {
    if (algorithm?.autothreshold && autoThresholdsFeatureFlag) {
      return [
        {
          value: 'autothresholds',
          label: 'Auto Thresholds',
          element: <AutoThresholds />,
        },
      ];
    }
    return [
      {
        value: 'overrides',
        label: 'Threshold Overrides',
        element: <ThresholdsOverrides />,
      },
    ];
  }, [algorithm]);

  const postProcessing = useCallback(() => {
    if (isFetching) {
      return false;
    }
    if (autoThresholdsFeatureFlag && !isSubmitting) {
      if (submitValues.autoThresholdEnabled && updatedAlgorithm) {
        setIsSubmitting(true);
        const automatonConfig = uiToAutoThresholdParams(submitValues);
        dispatch(
          thresholdActions.updateAutomaton({
            ...automatonConfig,
            afterUpdateMode: true,
          }),
        );
        return false;
      }
      if (
        !submitValues.autoThresholdEnabled &&
        thresholdAutomaton &&
        !isSubmitting
      ) {
        setIsSubmitting(true);
        dispatch(
          thresholdActions.removeAutomaton({
            ...algorithm,
            afterUpdateMode: true,
          }),
        );
        return false;
      }
    }
    return true;
  }, [
    autoThresholdsFeatureFlag,
    submitValues,
    updatedAlgorithm,
    isSubmitting,
    isFetching,
  ]);

  const onSubmit = useCallback(
    (values) => {
      setSubmitValues(values);
      dispatch(
        methods.update({
          ...uiToParams(values),
          id: algorithmId,
          silent: autoThresholdsFeatureFlag && values.autoThresholdEnabled,
        }),
      );
    },
    [algorithmId],
  );

  const autoThresholdValues = useMemo(() => {
    const thresholdValues = { ...thresholdAutomaton };
    // backend didn't update sigma values for pre-existing dms, so we need to parse out 0 values to default.
    if (
      thresholdValues.low_sigma === 0 &&
      thresholdValues.med_sigma === 0 &&
      thresholdValues.high_sigma === 0
    ) {
      thresholdValues.low_sigma = autoThresholdDefaults.low_sigma;
      thresholdValues.med_sigma = autoThresholdDefaults.med_sigma;
      thresholdValues.high_sigma = autoThresholdDefaults.high_sigma;
    }

    if (algorithm?.autothreshold && thresholdAutomaton) {
      return {
        autoThresholdEnabled: true,
        autoThresholdData: thresholdValues,
      };
    }
    if (defaultThresholdConfig) {
      return {
        autoThresholdEnabled: false,
        autoThresholdData: { ...defaultThresholdConfig },
      };
    }
    return {
      autoThresholdEnabled: false,
      autoThresholdData: {
        ...autoThresholdDefaults,
        data_lookback:
          customer?.retention || autoThresholdDefaults.data_lookback,
      },
    };
  }, [
    algorithm,
    thresholdAutomaton,
    customer?.retention,
    defaultThresholdConfig,
  ]);

  const isDnsEnabled = useFlag(FeatureFlags.dns);

  return (
    <CommonEdit
      title="Detection"
      methods={methods}
      FormBody={FormBody}
      routePath={
        isDnsEnabled ? RoutePaths.modelsDetection : RoutePaths.modelsNetwork
      }
      deleteButtonText="Delete Detection Model"
      additionalTabs={additionalTabs}
      focusOnFields={false}
      additionalInitialValues={autoThresholdValues}
      onSubmit={onSubmit}
      postProcessing={postProcessing}
    />
  );
};

export default AlgorithmEdit;
