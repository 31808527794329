/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';

import AlertDecagramIcon from 'mdi-react/AlertDecagramIcon';
import AlertOutlineIcon from 'mdi-react/AlertOutlineIcon';
import GearIcon from 'mdi-react/GearIcon';
import HomeIcon from 'mdi-react/HomeIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import RadarIcon from 'mdi-react/RadarIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';

import FeatureFlags from '@/models/FeatureFlags';
import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
  MAX_PINNED_DASHBOARDS,
} from '@/redux/api/dashboards';

import { usePageTabs } from '+components/PageTabs';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';

import ScrollBar from '../ScrollBar';
import {
  sideBarLeftCollapsedWidth,
  sideBarLeftWidth,
} from '../shared/constants';
import CollapseButton from './components/CollapseButton';
import Container from './components/Container';
import Content from './components/Content';
import Group from './components/Group';
import Item from './components/Item';
import ItemStyledLikeGroup from './components/ItemStyledLikeGroup';
import Profile from './components/Profile';
import Separator from './components/Separator';

const resizeEventDispatcher = () => window.dispatchEvent(new Event('resize'));

const SidebarLeft = (props) => {
  const { className, collapse, onToggle, $separatorWidth } = props;

  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const isDnsEnabled = useFlag(FeatureFlags.dns);
  const showRealTimeFlowMapBeta = useFlag(FeatureFlags.showRealTimeFlowMap);
  const [hideNav] = useUIProperty('hideNav');

  const [, , pageTabMethods] = usePageTabs();
  const [pinnedDashboards, setPinnedDashboards] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'pinnedDashboards',
    [],
  );
  const [localPinnedDashboards, setLocalPinnedDashboards] = useState([]);
  useEffect(() => {
    setLocalPinnedDashboards((prevValue) => {
      return isEqual(prevValue, pinnedDashboards)
        ? prevValue
        : pinnedDashboards;
    });
  }, [pinnedDashboards]);
  useDebounce(
    () => {
      if (!isEqual(localPinnedDashboards, pinnedDashboards)) {
        setPinnedDashboards(localPinnedDashboards);
      }
    },
    3000,
    [localPinnedDashboards, pinnedDashboards],
  );

  const containerRef = useRef(null);

  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);
  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  useEffect(() => {
    if (!isAllMetaFetched) {
      dispatch(dashboardsActions.fetchDashboardsMeta());
    }
  }, [isAllMetaFetched]);

  const pinnedDashboardsNavItems = useMemo(
    () =>
      localPinnedDashboards
        .map((item) => {
          if (item.customer !== customer?.shortname) {
            return null;
          }
          const dashboardMeta = dashboardsMeta?.[item.id];
          return !dashboardMeta
            ? null
            : {
                value: dashboardMeta.id,
                label: dashboardMeta.title,
                url: `${RoutePaths.dashboards}/${dashboardMeta.id}`,
              };
        })
        .filter(Boolean)
        .slice(0, MAX_PINNED_DASHBOARDS),
    [dashboardsMeta, localPinnedDashboards, customer?.shortname],
  );

  const onPinnedDashboardMove = useCallback(
    (dragId, hoverId) => {
      setLocalPinnedDashboards((prevValue) => {
        const nextValue = [...prevValue];
        const draggedIndex = prevValue.findIndex(
          (item) => item.id === dragId && item.customer === customer?.shortname,
        );
        const hoveredIndex = prevValue.findIndex(
          (item) =>
            item.id === hoverId && item.customer === customer?.shortname,
        );
        nextValue.splice(draggedIndex, 1);
        nextValue.splice(hoveredIndex, 0, prevValue[draggedIndex]);
        return isEqual(nextValue, prevValue) ? prevValue : nextValue;
      });
    },
    [customer?.shortname],
  );

  const onPinnedDashboardMoveUp = useCallback(
    (id) => {
      setLocalPinnedDashboards((prevValue) => {
        const nextValue = [...prevValue];
        const index = prevValue.findIndex(
          (item) => item.id === id && item.customer === customer?.shortname,
        );
        if (index === 0) {
          return prevValue;
        }
        [nextValue[index], nextValue[index - 1]] = [
          nextValue[index - 1],
          nextValue[index],
        ];
        return isEqual(nextValue, prevValue) ? prevValue : nextValue;
      });
    },
    [customer?.shortname],
  );

  const onPinnedDashboardMoveDown = useCallback(
    (id) => {
      setLocalPinnedDashboards((prevValue) => {
        const nextValue = [...prevValue];
        const index = prevValue.findIndex(
          (item) => item.id === id && item.customer === customer?.shortname,
        );
        if (index === prevValue.length - 1) {
          return prevValue;
        }
        [nextValue[index], nextValue[index + 1]] = [
          nextValue[index + 1],
          nextValue[index],
        ];
        return isEqual(nextValue, prevValue) ? prevValue : nextValue;
      });
    },
    [customer?.shortname],
  );

  const onPinnedDashboardUnpin = useCallback(
    (id) => {
      const nextValue = [...pinnedDashboards];
      const index = nextValue.findIndex(
        (item) => item.id === id && item.customer === customer?.shortname,
      );
      nextValue.splice(index, 1);
      if (isEqual(nextValue, pinnedDashboards)) {
        return;
      }
      setPinnedDashboards(nextValue);
    },
    [pinnedDashboards, customer?.shortname],
  );

  const onOpenInNewTab = useCallback(
    (data) => pageTabMethods.add(data, { force: true }),
    [],
  );

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }
    let timer;
    containerRef.current.ontransitionend = () => {
      timer = setTimeout(resizeEventDispatcher, 100);
    };
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const ContentWrapper = collapse ? Fragment : ScrollBar;

  return (
    <Container
      ref={containerRef}
      className={classNames(className, 'sidebar')}
      $collapse={collapse}
      $width={collapse ? sideBarLeftCollapsedWidth : sideBarLeftWidth}
      $separatorWidth={$separatorWidth}
    >
      <ContentWrapper>
        <Profile
          $separatorWidth={$separatorWidth}
          collapse={collapse}
          disabled={hideNav}
        />

        {!hideNav && (
          <Content $collapse={collapse}>
            <ItemStyledLikeGroup
              title="Home"
              icon={<HomeIcon />}
              route={`${RoutePaths.home}`}
              exact
              collapse={collapse}
              onOpenInNewTab={onOpenInNewTab}
            />

            <Group
              title="Dashboards"
              icon={<ViewGridIcon />}
              collapse={collapse}
              $separatorWidth={$separatorWidth}
            >
              <Item
                title="All"
                route={`${RoutePaths.dashboards}`}
                excludeRoutes={pinnedDashboardsNavItems.map(
                  (item) => `${item.url}/*`,
                )}
                onOpenInNewTab={onOpenInNewTab}
              />
              <Item
                title="Favorites"
                route={`${RoutePaths.dashboardsFavorites}`}
                onOpenInNewTab={onOpenInNewTab}
              />
              {!!pinnedDashboardsNavItems.length && (
                <Separator
                  $width={$separatorWidth}
                  $marginLeft={collapse ? 14 : 32}
                />
              )}
              {pinnedDashboardsNavItems.map((item, index) => (
                <Item
                  key={item.value}
                  id={item.value}
                  index={index}
                  title={item.label}
                  route={item.url}
                  onMove={onPinnedDashboardMove}
                  onMoveUp={index > 0 ? onPinnedDashboardMoveUp : null}
                  onMoveDown={
                    index < pinnedDashboardsNavItems.length - 1
                      ? onPinnedDashboardMoveDown
                      : null
                  }
                  onUnpin={onPinnedDashboardUnpin}
                  onOpenInNewTab={onOpenInNewTab}
                />
              ))}
            </Group>

            <Group
              title="Investigate"
              icon={<MagnifyIcon />}
              collapse={collapse}
              $separatorWidth={$separatorWidth}
            >
              <Item
                title="Search"
                route={`${RoutePaths.search}`}
                includeRoutes={`${RoutePaths.search}/*`}
                onOpenInNewTab={onOpenInNewTab}
              />
              <Item
                title="Traffic Miner"
                route={`${RoutePaths.trafficMiner}`}
                onOpenInNewTab={onOpenInNewTab}
              />
              <Item
                title={isDnsEnabled ? 'Traffic Top' : 'Network Top'}
                route={`${
                  isDnsEnabled ? RoutePaths.trafficTop : RoutePaths.networkTop
                }`}
                onOpenInNewTab={onOpenInNewTab}
              />
              <Item
                title="IP Explorer"
                route={`${RoutePaths.ipExplorer}`}
                onOpenInNewTab={onOpenInNewTab}
              />
              <Item
                title="IP Intelligence"
                route={`${RoutePaths.ipIntelligence}`}
                onOpenInNewTab={onOpenInNewTab}
              />
              {showRealTimeFlowMapBeta && (
                <Item
                  title="Real-Time Flow Map"
                  route={`${RoutePaths.realTimeFlowMap}`}
                  onOpenInNewTab={onOpenInNewTab}
                />
              )}
            </Group>

            <Group
              title="Events"
              icon={<AlertOutlineIcon />}
              collapse={collapse}
              $separatorWidth={$separatorWidth}
            >
              <Item
                title="Events by Asset"
                route={`${RoutePaths.eventsAsset}`}
                exact
                onOpenInNewTab={onOpenInNewTab}
              />
              <Item
                title="Events by Detection"
                route={`${RoutePaths.eventsDetection}`}
                onOpenInNewTab={onOpenInNewTab}
              />
              <Item
                title="Event List"
                route={`${RoutePaths.eventsList}`}
                onOpenInNewTab={onOpenInNewTab}
              />
            </Group>

            <Group
              title="Detections"
              icon={<RadarIcon />}
              collapse={collapse}
              $separatorWidth={$separatorWidth}
            >
              <Item
                title="Detection Models"
                route={`${RoutePaths.models}`}
                onOpenInNewTab={onOpenInNewTab}
              />
            </Group>

            <ItemStyledLikeGroup
              title="CVE Lookup"
              icon={<AlertDecagramIcon />}
              route={`${RoutePaths.cveLookup}`}
              collapse={collapse}
              onOpenInNewTab={onOpenInNewTab}
            />

            <ItemStyledLikeGroup
              title="Settings"
              icon={<GearIcon />}
              route={`${RoutePaths.settings}`}
              includeRoutes={`${RoutePaths.settings.rootPath}/*`}
              collapse={collapse}
              onOpenInNewTab={onOpenInNewTab}
            />
          </Content>
        )}

        {!hideNav && (
          <CollapseButton collapse={collapse} changeVisibility={onToggle} />
        )}
      </ContentWrapper>
    </Container>
  );
};

SidebarLeft.propTypes = {
  className: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  $separatorWidth: PropTypes.number.isRequired,
};

SidebarLeft.defaultProps = {
  className: '',
};

export default SidebarLeft;
