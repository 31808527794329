import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StatsRequest from '@/models/StatsRequest';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import { selectors as nqlCompleteSelectors } from '@/redux/api/nql-complete';

import { FinalForm, useForm } from '+components/form/FinalForm';
import { preserveRef } from '+utils';

import FormBody, { defaultProps, propTypes } from './components/FormBody';

const withFinalFormRef = (ref) => {
  return (props) => {
    const form = useForm();

    preserveRef(ref, form);

    return <FormBody {...props} />;
  };
};

const SearchForm = (props) => {
  const { multipleNql, nqlContext, onSearch, finalFormRef, ...tail } = props;

  const maxNqlQueries = multipleNql
    ? StatsRequest.SearchConfig[nqlContext]?.maxNqlQueries ?? 1
    : 1;
  const maxIntersects = multipleNql
    ? StatsRequest.SearchConfig[nqlContext]?.maxIntersects ?? 0
    : 0;

  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const customers = useSelector(customerSelectors.getCustomers);
  const areAllCustomersFetched = useSelector(customerSelectors.areAllFetched);

  const docs = useSelector(nqlCompleteSelectors.getDocs);

  const intersectFieldsOptions = useMemo(
    () =>
      Object.entries(docs?.docs?.fields?.[nqlContext] || {}).reduce(
        (acc, item) => {
          const [fieldName, fieldMeta] = item;
          if (!fieldMeta.intersectable) {
            return acc;
          }
          return [
            ...acc,
            {
              value: fieldName,
              label: fieldName,
              description: fieldMeta.description,
            },
          ];
        },
        [],
      ),
    [docs?.docs?.fields?.[nqlContext]],
  );

  const subAccountOptions = useMemo(() => {
    if (!customer?.isReseller) {
      return [];
    }
    const options = Object.values(customers || {})
      .reduce((acc, item) => {
        const canAggSubAccountData =
          customer?.shortname === 'netography' ||
          (customer?.shortname === item.parent &&
            item.meta.reseller_data_agg_enabled);
        const haveSameRetention =
          customer?.retention === item.retention &&
          customer?.rollupRetention === item.rollupRetention;
        if (!canAggSubAccountData || !haveSameRetention) {
          return acc;
        }
        return [
          ...acc,
          {
            value: item.shortname,
            label: item.shortname,
            description: item.organization,
            // disabled: !canAggSubAccountData || !haveSameRetention,
          },
        ];
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
    options.unshift({
      value: customer?.shortname,
      label: `${customer?.shortname} (current)`,
      description: customer?.organization,
    });
    return options;
  }, [customers, customer]);

  useEffect(() => {
    if (!customer?.isReseller || areAllCustomersFetched) {
      return undefined;
    }
    const namespace = 'fetch_customers';
    dispatch(customerActions.fetch(customer?.shortname, namespace));
    return () => {
      dispatch(customerActions.cancel(namespace));
    };
  }, [customer?.isReseller, areAllCustomersFetched, customer?.shortname]);

  const FormBodyWithFinalFormRef = useMemo(
    () => withFinalFormRef(finalFormRef),
    [finalFormRef],
  );

  return (
    <FinalForm
      keepDirtyOnReinitialize
      {...tail}
      onSubmit={onSearch}
      component={FormBodyWithFinalFormRef}
      context={nqlContext}
      maxNqlQueries={maxNqlQueries}
      maxIntersects={maxIntersects}
      intersectFieldsOptions={intersectFieldsOptions}
      subAccountOptions={subAccountOptions}
      focusOnFields={false}
      validateOnBlur
    />
  );
};

SearchForm.propTypes = {
  ...propTypes,
  multipleNql: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  finalFormRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

SearchForm.defaultProps = {
  ...defaultProps,
  finalFormRef: null,
  multipleNql: true,
};

export default SearchForm;
