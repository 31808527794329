import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

const useCustomerSubscriptionTypes = () => {
  const dispatch = useDispatch();
  const customerSubscriptionTypes = useSelector(
    customerSelectors.getCustomerSubscriptionTypes,
  );
  useEffect(() => {
    if (!Object.keys(customerSubscriptionTypes).length) {
      dispatch(customerActions.requestCustomerSubscriptionTypes());
    }
  }, [Object.keys(customerSubscriptionTypes).length]);
  return customerSubscriptionTypes;
};

export default useCustomerSubscriptionTypes;
