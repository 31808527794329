import PropTypes from '+prop-types';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { ContextTypes } from '@/models/ContextTypes';

import {
  actions as globalFiltersActions,
  makeFilterUrlParamStr,
  parseFilterUrlParamStr,
} from '@/redux/globalFilters';
import { actions as globalFiltersUiActions } from '@/redux/globalFilters/ui';

import { usePageTabs } from '+components/PageTabs';
import * as toast from '+components/toast';
import useGlobalFilters from '+hooks/useGlobalFilters';
import getIntersectFieldName from '+utils/getIntersectFieldName';
import getMetricFieldName from '+utils/getMetricFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';

let instance = 0;

const Setting = (props) => {
  const {
    range,
    from,
    to,
    metric,
    nql,
    size,
    context,
    customers,
    socketControl,
    updateEvery,
    configuring,
    excludeMetrics,
    excludeContexts,
    onlyRealtime,
  } = props;

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [, activePageTab] = usePageTabs();
  const [filters, change] = useGlobalFilters();

  useEffect(() => {
    if (!activePageTab?.id) {
      return;
    }
    change({ context });
  }, [activePageTab?.id, context, change]);

  const prevSearchParamsActivePageTabId = useRef();
  useDebounce(
    () => {
      if (!activePageTab?.id) {
        return;
      }
      const nextFilterUrlParamStr = makeFilterUrlParamStr(filters, {
        metric: true,
        customers,
      });
      setSearchParams((prevValue) => {
        const prevFilterUrlParamStr = prevValue.get('f');
        if (prevFilterUrlParamStr !== nextFilterUrlParamStr) {
          prevValue.set('f', nextFilterUrlParamStr);
        }
        return prevValue;
      });
      prevSearchParamsActivePageTabId.current = activePageTab?.id;
    },
    600,
    [
      activePageTab?.id,
      activePageTab?.pathname,
      filters,
      customers,
      setSearchParams,
    ],
  );

  const currentFilterSearchParamStr = searchParams.get('f');
  const currentFilter = useRef(filters);
  currentFilter.current = filters;
  useEffect(() => {
    if (!activePageTab?.id) {
      return;
    }
    if (
      prevSearchParamsActivePageTabId.current &&
      prevSearchParamsActivePageTabId.current !== activePageTab?.id &&
      !activePageTab?.search // if tab is with search, we need to use it as a source of truth
    ) {
      return;
    }
    if (!currentFilterSearchParamStr) {
      return;
    }
    const currentFilterUrlParamStr = makeFilterUrlParamStr(
      currentFilter.current,
      {
        metric: true,
        customers,
      },
    );
    if (currentFilterUrlParamStr !== currentFilterSearchParamStr) {
      const parsed = parseFilterUrlParamStr(currentFilterSearchParamStr);
      if (!ContextTypes[parsed.context]) {
        return;
      }
      const metricFieldName = getMetricFieldName(parsed.context);
      const nqlFieldName = getNqlFieldName(parsed.context);
      const intersectFieldName = getIntersectFieldName(parsed.context);
      change({
        ...parsed,
        [metricFieldName]: parsed.metric,
        [nqlFieldName]: parsed.nql,
        [intersectFieldName]: parsed.intersect,
      });
    }
  }, [
    activePageTab?.id,
    activePageTab?.search,
    currentFilterSearchParamStr,
    metric,
    customers,
    change,
  ]);

  useEffect(() => {
    dispatch(
      globalFiltersUiActions.change({
        range,
        from,
        to,
        nql: nql && context,
        size,
        metric,
        customers,
        socketControl,
        updateEvery,
        configuring,
        excludeMetrics,
        excludeContexts,
        onlyRealtime,
      }),
    );
  }, [
    range,
    from,
    to,
    metric,
    nql,
    size,
    updateEvery,
    configuring,
    context,
    customers,
    socketControl,
    excludeMetrics,
    excludeContexts,
    onlyRealtime,
  ]);

  useEffect(() => {
    if (!instance) {
      dispatch(globalFiltersUiActions.showTopbar());
    }
    instance += 1;

    return () => {
      instance = Math.max(0, instance - 1);

      if (!instance) {
        dispatch(globalFiltersUiActions.disableAll());
        dispatch(globalFiltersUiActions.hideTopbar());
      }
    };
  }, []);

  const metricField = getMetricFieldName(filters.context);
  const filtersMetric = filters[metricField];
  const isFiltersMetricExcluded = !!excludeMetrics?.includes(filtersMetric);
  useEffect(() => {
    if (isFiltersMetricExcluded) {
      dispatch(
        globalFiltersActions.setMetric({
          metric: ContextTypes.flow,
        }),
      );
      toast.warn({
        message: `Current metric '${filtersMetric}' not supported on this page.`,
        details: `Metric changed to default '${ContextTypes.flow}'.`,
      });
    }
  }, [isFiltersMetricExcluded, filtersMetric]);

  return null;
};

Setting.propTypes = {
  range: PropTypes.bool,
  from: PropTypes.bool,
  to: PropTypes.bool,
  metric: PropTypes.bool,
  nql: PropTypes.bool,
  size: PropTypes.bool,
  context: PropTypes.oneOf(['', ...Object.values(ContextTypes)]),
  customers: PropTypes.bool,
  socketControl: PropTypes.bool,
  updateEvery: PropTypes.number,
  configuring: PropTypes.bool,
  onlyRealtime: PropTypes.bool,
  excludeMetrics: PropTypes.arrayOf(PropTypes.string),
  excludeContexts: PropTypes.arrayOf(PropTypes.string),
};

Setting.defaultProps = {
  range: true,
  from: true,
  to: true,
  metric: false,
  nql: false,
  size: false,
  customers: false,
  socketControl: false,
  context: ContextTypes.flow,
  updateEvery: 0,
  configuring: false,
  onlyRealtime: false,
  excludeMetrics: null,
  excludeContexts: null,
};

export default Setting;
