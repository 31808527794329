import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import { useMeasure } from 'react-use';

import classNames from 'classnames';
import styled from 'styled-components';

import { Provider as MenuProvider } from '+components/Menu';
import Tooltip from '+components/Tooltip';

import { getProps, tooltipDelay } from '../utils';
import {
  DropZoneLeft,
  DropZoneRight,
  Ellipsis,
  ResizableHeader,
  ResizableHeaderContent,
  Resizer,
} from './Components';
import HeaderContextMenu from './HeaderContextMenu';
import HeaderSettings from './HeaderSettings';

const HeaderActionsContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(${({ $rightOffset }) => $rightOffset || 0}, -50%);
  margin-left: auto;
  display: ${({ $isDragging }) => ($isDragging ? 'none' : 'flex')};
  gap: 5px;
  z-index: 99;

  .MuiButtonBase-root {
    color: ${({ theme }) => theme.iconButtonInsideComponentsText} !important;
    background-color: ${({ theme }) =>
      theme.iconButtonInsideComponentsBackground} !important;
  }
`;

const TooltipContent = styled.div`
  .header {
    display: flex;
    align-items: center;
  }
  .title {
    color: ${({ theme }) => theme.colorTextSecondary};
  }
`;

const Wrapper = HeaderContextMenu(ResizableHeader);

const HeaderCell = (props) => {
  const {
    index,
    count,
    column,
    expanderInHeader,
    draggable,
    getHeaderProps,
    getToggleAllRowsExpandedProps,
    FilterRenderer,
    AggregatorRenderer,
    isGrouped,
    disableFilters,
    disableGroupBy,
    disableAggregators,
  } = props;

  const { id } = column;

  const [measureRef, { width: columnWidth }] = useMeasure();
  const dropRef = useRef(null);

  const [containerHover, setContainerHover] = useState(false);
  const [headerTooltipOpen, setHeaderTooltipOpen] = useState(false);
  const [activeActionsColumnIndex, setActiveActionsColumnIndex] =
    useState(null);

  const isColumnsDragging = useDragLayer(
    (monitor) => monitor.isDragging() && monitor.getItemType() === 'column',
  );

  const [{ isHover }, drop] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      isHover: monitor.isOver(),
    }),
  });

  const [{ isHoverLeft }, dropLeft] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      isHoverLeft: monitor.isOver(),
    }),
    hover: (item) => {
      const { index: dragIndex, id: dragId } = item;

      if (index >= dragIndex || id === dragId) {
        return;
      }

      column.moveColumn(dragId, id);

      item.index = index;
    },
  });

  const [{ isHoverRight }, dropRight] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      isHoverRight: monitor.isOver(),
    }),
    hover: (item) => {
      const { index: dragIndex, id: dragId } = item;

      if (index <= dragIndex || id === dragId) {
        return;
      }

      column.moveColumn(dragId, id);

      item.index = index;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'column',
    item: {
      id,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(dropRef));

  const canDrag =
    draggable &&
    column.canDragAndDrop &&
    (!column.isGrouped || !disableGroupBy);
  const canSort = !isColumnsDragging && column.canSort;
  const canResize = !isColumnsDragging && !column.headers && column.canResize;
  const title = [
    canDrag && 'Hold to Drag',
    column.canSort && 'Click to toggle sorting',
  ]
    .filter(Boolean)
    .join(' | ');

  const showAggregationButton =
    !disableAggregators &&
    isGrouped &&
    column.canAggregate &&
    (containerHover ||
      index === activeActionsColumnIndex ||
      column.aggregate != null);

  const showFilterButton =
    !disableFilters &&
    column.canFilter &&
    (containerHover ||
      index === activeActionsColumnIndex ||
      column.filterValue != null);

  const columnProps = column.getHeaderProps(
    getProps([
      expanderInHeader && column.expander
        ? getToggleAllRowsExpandedProps()
        : {},
      getHeaderProps,
      column.getProps,
      {
        style: {
          boxShadow: isDragging ? 'none' : null,
          background:
            !isDragging && (isHoverRight || isHoverLeft)
              ? 'rgba(255, 255, 255, 0.03)'
              : null,
        },
        $sortAsc: column.isSorted && !column.isSortedDesc,
        $sortDesc: column.isSorted && column.isSortedDesc,
      },
    ]),
  );

  delete columnProps.title;

  const header = column.render('Header');

  const content = useMemo(
    () =>
      !column.disableTooltip && (
        <TooltipContent>
          <div className="header">
            {expanderInHeader && column.expander && columnProps.title}
            {(expanderInHeader && column.expander) || column.render('Header')}
          </div>
          {column.Description && (
            <div className="description">{column.render('Description')}</div>
          )}
          <div className="title">
            <small>{title}</small>
          </div>
        </TooltipContent>
      ),
    [title, column],
  );

  const onHeaderTooltipOpen = useCallback(() => {
    if (column.disableTooltip || isColumnsDragging) {
      return;
    }
    setHeaderTooltipOpen(true);
  }, [column.disableTooltip, isColumnsDragging]);

  const onHeaderTooltipClose = useCallback(() => {
    setHeaderTooltipOpen(false);
  }, []);

  const onMouseEnter = useCallback(
    (e) => {
      // we need this check for the case when user resizing one column to the right and hover another column to the right
      const isHoveredWhenOtherColumnIsResizing =
        !column.isResizing && e.buttons > 0;
      if (isColumnsDragging || isHoveredWhenOtherColumnIsResizing) {
        return;
      }
      setContainerHover(true);
    },
    [isColumnsDragging, column.isResizing],
  );

  const onMouseLeave = useCallback(() => setContainerHover(false), []);

  useEffect(() => {
    if (isColumnsDragging) {
      setHeaderTooltipOpen(false);
    }
  }, [isColumnsDragging]);

  const setColumnRef = useCallback(
    (node) => {
      measureRef(node);
      if (canDrag) {
        dropRef.current = node;
      }
    },
    [canDrag],
  );

  const actionsRightOffset = useMemo(() => {
    if (!showAggregationButton && !showFilterButton) {
      return null;
    }
    // const actionsCount = showAggregationButton && showFilterButton ? 2 : 1;
    const actionsCount = 1;
    const minColumnWidth = 30 + actionsCount * 20;
    const needToOffset =
      index !== count - 1 && containerHover && columnWidth <= minColumnWidth;
    if (!needToOffset) {
      return null;
    }
    return actionsCount === 1 ? '70%' : '90%';
  }, [
    index,
    count,
    containerHover,
    columnWidth,
    showAggregationButton,
    showFilterButton,
  ]);

  return (
    <Wrapper
      {...columnProps}
      ref={setColumnRef}
      className={classNames(columnProps.className, {
        'col-menu': column.id === 'menu',
      })}
      column={column}
      activeActionsColumnIndex={activeActionsColumnIndex}
      disableFilters={disableFilters}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isHover && <DropZoneLeft ref={dropLeft} />}

      <Tooltip
        title={content}
        placement="top"
        enterDelay={tooltipDelay}
        enterNextDelay={tooltipDelay}
        open={headerTooltipOpen}
        onOpen={onHeaderTooltipOpen}
        onClose={onHeaderTooltipClose}
      >
        <ResizableHeaderContent
          {...(canDrag && { ref: drag })}
          {...(canSort && column.getSortByToggleProps())}
          title={null}
          $isDragging={isDragging}
        >
          {typeof header === 'string' ? <Ellipsis>{header}</Ellipsis> : header}
        </ResizableHeaderContent>
      </Tooltip>

      {(showAggregationButton || showFilterButton) && (
        <HeaderActionsContainer
          $rightOffset={actionsRightOffset}
          $isDragging={isDragging}
        >
          <MenuProvider>
            <HeaderSettings
              index={index}
              activeActionsColumnIndex={activeActionsColumnIndex}
              setActiveActionsColumnIndex={setActiveActionsColumnIndex}
              setContainerHover={setContainerHover}
              Renderer={
                <Fragment>
                  {showFilterButton && FilterRenderer}
                  {showAggregationButton && AggregatorRenderer}
                </Fragment>
              }
            />
          </MenuProvider>
        </HeaderActionsContainer>
      )}

      {isHover && <DropZoneRight ref={dropRight} />}

      {canResize && (
        <Resizer
          {...column.getResizerProps({ style: { cursor: 'col-resize' } })}
        />
      )}
    </Wrapper>
  );
};

const propTypes = {
  expanderInHeader: PropTypes.bool,
  getHeaderProps: PropTypes.func,
  getToggleAllRowsExpandedProps: PropTypes.func.isRequired,
};

const defaultProps = {
  expanderInHeader: null,
  getHeaderProps: null,
};

HeaderCell.propTypes = {
  index: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  column: PropTypes.shape().isRequired,
  draggable: PropTypes.bool.isRequired,
  isGrouped: PropTypes.bool.isRequired,
  FilterRenderer: PropTypes.children.isRequired,
  AggregatorRenderer: PropTypes.children.isRequired,
  disableFilters: PropTypes.bool,
  disableAggregators: PropTypes.bool,
  ...propTypes,
};

HeaderCell.defaultProps = {
  disableFilters: null,
  disableAggregators: true,
  ...defaultProps,
};

export { propTypes, defaultProps };
export default HeaderCell;
