import { createSelector } from 'reselect';

import { createSlice } from '@/redux/util';

const initialState = {
  isPaused: true,
};

export const slice = createSlice({
  name: 'socketControl',
  initialState,

  reducers: {
    pause(state) {
      state.isPaused = true;
    },
    resume(state) {
      state.isPaused = false;
    },
  },

  sagas: () => ({}),

  selectors: (getState) => ({
    isPaused: createSelector([getState], (state) => state.isPaused),
  }),
});

export const { actions, selectors } = slice;
export default slice;
