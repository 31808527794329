import { useMemo } from 'react';

import styled from 'styled-components';

const Highlighted = styled.span`
  &&:not(#fake_id_for_ighlighted) {
    color: ${({ theme }) => theme.colorHighlighted};
  }

  //font-weight: bold;
`;

const Highlight = ({ search, children }) =>
  useMemo(() => {
    if (search === '') {
      return children;
    }

    const normalSearch = search.toLowerCase();
    const matches = Array.from(children.toLowerCase().matchAll(normalSearch));

    let index = 0;
    const result = matches.reduce((acc, item) => {
      acc.push(children.slice(index, item.index));

      index = item.index + item[0].length;

      acc.push(
        <Highlighted key={item.index}>
          {children.slice(item.index, index)}
        </Highlighted>,
      );

      return acc;
    }, []);
    result.push(children.slice(index));

    return result.filter(Boolean);
  }, [children, search]);

export default Highlight;
