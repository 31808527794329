export const CloudProviderTypes = {
  'aws/kinesis': {
    value: 'aws/kinesis',
    label: 'AWS Kinesis',
  },
  'aws/s3': {
    value: 'aws/s3',
    label: 'AWS S3 VPC',
  },
  'aws-tgw/s3': {
    value: 'aws-tgw/s3',
    label: 'AWS S3 Transit Gateway',
  },
  'azure/blobstorage': {
    value: 'azure/blobstorage',
    label: 'Azure NSG',
  },
  'azure-vnet/blobstorage': {
    value: 'azure-vnet/blobstorage',
    label: 'Azure VNet',
  },
  'gcp/pubsub': {
    value: 'gcp/pubsub',
    label: 'GCP',
  },
  'ibm/objectstorage': {
    value: 'ibm/objectstorage',
    label: 'IBM COS',
  },
  'oracle/objectstorage': {
    value: 'oracle/objectstorage',
    label: 'Oracle COS',
  },
};

export default CloudProviderTypes;
