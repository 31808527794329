/* eslint-disable camelcase */
import { ContextTypes } from '@/models/ContextTypes';

import makeArr from '+utils/makeArr';

const matchThresholdMethods = /\w+\((?:\s*,?\s*\w+)*\s*\)/gim;

export const Config = {
  // defaults
  defaultSearchBySearch: '',
  defaultSearchBy: { type: 'all', search: [''] },
  defaultThresholds: { severity: 'medium', threshold: '' },
  defaultTrackBy: [],
  defaultContextTrackBy: {
    [ContextTypes.flow]: ['dstip'],
    [ContextTypes.dns]: ['srcip'],
  },
  defaultDiscards: '',
  // max
  maxThresholds: 6,
  maxTrackBy: 6, // synthetic ui limit
  maxDiscards: 50, // synthetic ui limit
  maxNameLength: 50,
  maxDescriptionLength: 120,
};

export const cadenceOptions = {
  daily: { value: '1d', label: 'Daily' },
  weekly: { value: '7d', label: 'Weekly' },
  monthly: { value: '30d', label: 'Monthly' },
};

export const strategyOptions = {
  average: { value: 'average', label: 'Average' },
  max: { value: 'max', label: 'Max' },
};

// TODO: API2021 Remove it after DB convertation
export const trackFromDdToUiConverter = (track) =>
  Array.isArray(track) ? track : track.fields;

export const paramsToUi = (params) => {
  if (!params) {
    return {};
  }

  const values = { ...params };

  if (!params.search_by?.length) {
    values.search_by = [Config.defaultSearchBy];
  } else {
    values.search_by = values.search_by.map((item) => ({
      ...item,
      search: makeArr(item.search),
    }));
  }

  if (!params.thresholds?.length) {
    values.thresholds = [Config.defaultThresholds];
  }

  if (!params.track_by?.length) {
    values.track_by = [Config.defaultTrackBy];
  } else {
    values.track_by = params.track_by.map(trackFromDdToUiConverter);
  }

  if (params.action?.length) {
    const dstip = params.action.find(({ field }) => field === 'dstip');
    if (dstip) {
      values.dstipContext = dstip.context;
      values.dstipLabels = dstip.labels;
    }
    const srcip = params.action.find(({ field }) => field === 'srcip');
    if (srcip) {
      values.srcipContext = srcip.context;
      values.srcipLabels = srcip.labels;
    }
  }

  if (!params.discards?.length) {
    values.discards = [Config.defaultDiscards];
  }

  if (
    params.autoThresholdData?.data_window &&
    typeof params.autoThresholdData?.data_window === 'string'
  ) {
    values.autoThresholdData.data_window = parseInt(
      params.autoThresholdData.data_window?.replace(/[^-\d]/g, ''),
      10,
    );
  }

  if (
    params.autoThresholdData?.data_lookback &&
    typeof params.autoThresholdData?.data_lookback === 'string'
  ) {
    values.autoThresholdData.data_lookback = parseInt(
      params.autoThresholdData.data_lookback?.replace(/[^-\d]/g, ''),
      10,
    );
  }

  return values;
};

export const uiToAutoThresholdParams = (values) => {
  const valuesData = values.autoThresholdData;
  const data = { ...valuesData };

  // need to handle parsing here in addition to final form parse methods because of this bug:
  // https://github.com/final-form/react-final-form/issues/431
  // if form values are not changed from default, parse() methods will not be applied.

  data.data_interval =
    valuesData?.data_interval?.value ?? valuesData?.data_interval;
  data.strategy = valuesData?.strategy?.value ?? valuesData?.strategy;
  data.data_lookback = `${valuesData?.data_lookback}d`;
  data.data_window = `${valuesData?.data_window}h`;
  data.algorithm = values?.name;
  data.track_by = values?.track_by[0];

  // if DM is disabled, we also need to disable the threshold automaton
  data.disabled = !values.enabled;

  // update interval is currently locked at 10 minutes
  data.update_interval = '10m';

  // currently UI does not support filters.
  data.filters = [];

  // we need to pull methods from thresholds and concat to array
  // e.g. avg(bitsxrate) >= 20000000 || avg(packetsxrate) >= 20000
  // becomes ['avg(bitsxrate)', 'avg(packetsxrate)']

  data.thresholds = [
    ...new Set(
      values?.thresholds.reduce(
        (acc, curr) => [
          ...acc,
          ...(curr.threshold.replace(/ /g, '').match(matchThresholdMethods) ||
            []),
        ],
        [],
      ),
    ),
  ];

  return data;
};

export const uiToParams = (values) => {
  const params = { ...values };
  if (params.rollupperiod && params.rollupperiod >= 0) {
    params.rollupperiod = parseInt(params.rollupperiod, 10);
  } else {
    params.rollupperiod = null;
  }

  if (params.expiration && params.expiration >= 0) {
    params.expiration = parseInt(params.expiration, 10);
  } else {
    params.expiration = null;
  }

  if (params.updateinterval && params.updateinterval >= 0) {
    params.updateinterval = parseInt(params.updateinterval, 10);
  } else {
    params.updateinterval = 0;
  }

  if (params.ndm_score_threat && params.ndm_score_threat >= 0) {
    params.ndm_score_threat = parseInt(params.ndm_score_threat, 10);
  } else {
    params.ndm_score_threat = 0;
  }

  if (params.ndm_score_confidence && params.ndm_score_confidence >= 0) {
    params.ndm_score_confidence = parseInt(params.ndm_score_confidence, 10);
  } else {
    params.ndm_score_confidence = 0;
  }

  if (params.track_by[0]?.length && params.algo_type === 'CDM') {
    const action = [];
    if (params.track_by[0].indexOf('dstip') > -1) {
      const contextLabel = {
        type: 'label',
        field: 'dstip',
        context: params.dstipContext?.toString(),
        labels: params.dstipLabels,
      };
      action.push(contextLabel);
    }
    if (params.track_by[0].indexOf('srcip') > -1) {
      const contextLabel = {
        type: 'label',
        field: 'srcip',
        context: params.srcipContext?.toString(),
        labels: params.srcipLabels,
      };
      action.push(contextLabel);
    }
    delete params.srcipContext;
    delete params.srcipLabels;
    delete params.dstipContext;
    delete params.dstipLabels;
    params.action = action;
  }

  params.search_by = (params.search_by || []).filter(
    (item) => !!item.search.filter((el) => !!el?.trim()).length,
  );

  params.thresholds = (params.thresholds || []).filter(
    (item) => !!item.threshold?.trim(),
  );
  params.discards = (params.discards || []).filter((item) => !!item?.trim());

  // delete ui-only property
  delete params.autoThresholdEnabled;

  // delete auto-threshold object, doesn't get sent to rule-engine
  delete params.autoThresholdData;

  return params;
};
