import { createSelector, createSlice } from '@/redux/util';

const name = 'globalFiltersUI';

const initialState = {
  available: false,
  portalId: false,

  range: true,
  from: true,
  to: true,
  nql: false,
  metric: false,
  customers: true,
  excludeMetrics: [],
  autoRefresh: false,
};

const slice = createSlice({
  name,
  initialState,

  reducers: {
    change(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    disableAll(state) {
      return {
        ...state,

        range: false,
        from: false,
        to: false,
        nql: false,
        metric: false,
        customers: false,
        onlyRealtimeOption: false,
      };
    },

    showTopbar(state) {
      state.available = true;
    },

    hideTopbar(state) {
      state.available = false;
    },
  },

  selectors: (getState) => ({
    getAllState: getState,
    getMetric: createSelector([getState], (state) => state.metric),
    getAvailable: createSelector([getState], (state) => state.available),
    getUpdateEvery: createSelector([getState], (state) => state.updateEvery),
    getOnlyRealtime: createSelector([getState], (state) => state.onlyRealtime),
  }),
});

export const { selectors, actions } = slice;

export default slice;
