import PropTypes from '+prop-types';
import { useEffect, useRef } from 'react';

import { useFlag } from '@unleash/proxy-client-react';

import FeatureFlags from '@/models/FeatureFlags';

import useUIProperty from '+hooks/useUIProperty';

import Container from './components/Container';
import ContainerResizable from './components/ContainerResizable';
import GrabArea from './components/Resizer';

const resizeEventDispatcher = () => window.dispatchEvent(new Event('resize'));

const SidebarRight = ({
  className,
  children,
  $width,
  $separatorWidth,
  isOpen,
  onResizeStart,
  onResizeReset,
}) => {
  const isResizableTrayEnabled = useFlag(FeatureFlags.resizableTray);

  const [isSideBarRightResizing] = useUIProperty(
    'isSideBarRightResizing',
    false,
  );

  const containerRef = useRef(null);

  useEffect(() => {
    if (isResizableTrayEnabled || !containerRef.current) {
      return undefined;
    }
    let timer;
    containerRef.current.ontransitionend = () => {
      timer = setTimeout(resizeEventDispatcher, 100);
    };
    return () => {
      clearTimeout(timer);
    };
  }, [isResizableTrayEnabled]);

  return isResizableTrayEnabled ? (
    <ContainerResizable
      ref={containerRef}
      className={className}
      $isOpen={isOpen}
      $width={$width}
      $separatorWidth={$separatorWidth}
      $resizing={isSideBarRightResizing}
    >
      <GrabArea
        $separatorWidth={$separatorWidth}
        onMouseDown={onResizeStart}
        onDoubleClick={onResizeReset}
      />
      {children}
    </ContainerResizable>
  ) : (
    <Container
      ref={containerRef}
      className={className}
      $isOpen={isOpen}
      $width={$width}
      $separatorWidth={$separatorWidth}
    >
      {children}
    </Container>
  );
};

SidebarRight.propTypes = {
  className: PropTypes.string,
  children: PropTypes.children,
  $width: PropTypes.number.isRequired,
  $separatorWidth: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  onResizeStart: PropTypes.func,
  onResizeReset: PropTypes.func,
};

SidebarRight.defaultProps = {
  className: null,
  children: null,
  isOpen: false,
  onResizeStart: null,
  onResizeReset: null,
};

export default SidebarRight;
