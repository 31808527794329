import styled from 'styled-components';

import CloudProviderTypes from '@/models/CloudProviderTypes';

import { VpcIcon } from '@/shared/img/icon';

const VpcContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const VpcIconWrapper = styled.span`
  width: 24px;
  justify-content: center;
`;

export const formatVpc = (vpc, logtype, size = 18) => {
  let name = CloudProviderTypes[vpc]?.label || vpc || '';
  // eslint-disable-next-line prefer-const
  let [flowtype, flowresource] = (vpc || '').split('/');

  if (logtype === 'azure-vnet-flow-log') {
    name = CloudProviderTypes['azure-vnet/blobstorage']?.label || '';
  }
  if (logtype === 'aws-tgw-flow-log') {
    name = CloudProviderTypes['aws-tgw/s3']?.label || '';
    flowtype = 'aws-tgw';
  }

  return (
    <VpcContainer>
      <VpcIconWrapper>
        <VpcIcon flowtype={flowtype} flowresource={flowresource} size={size} />
      </VpcIconWrapper>
      <span>{name}</span>
    </VpcContainer>
  );
};

const providers = Object.keys(CloudProviderTypes);

const parseIfInt = (value) =>
  typeof value === 'number' ? value : parseInt(value, 10);

export const vpcParamsToUi = (params) => {
  if (!params) {
    return null;
  }
  const ui = {
    ...params,
    ...params[`${params.flowtype}${params.flowresource}`],
    ...(params.logtype === 'azure-vnet-flow-log' ? params.azurevnet : {}),
    ...(params.logtype === 'aws-tgc-flow-log' ? params.awstgw : {}),
  };
  providers.forEach((provider) => {
    const key = provider.replace('/', '');
    delete ui[key];
  });
  return ui;
};

export const vpcUItoParams = (values) => {
  const samplerate = parseIfInt(values.samplerate, 10);
  const samplepercent = parseIfInt(values.samplepercent, 10);

  const vpcData = {
    ...values,
    samplerate,
    samplepercent,
  };

  // not allowed to specify it
  providers.forEach(
    (provider) => delete (vpcData[provider] || '').replace('/', ''),
  );
  delete vpcData.keypassphrase;
  delete vpcData.publickey;
  delete vpcData.fingerprint;
  delete vpcData.fingerprint_sha256;

  return vpcData;
};
