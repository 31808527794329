import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useCounter, useDebounce } from 'react-use';

import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';

import { ContextTypes } from '@/models/ContextTypes';
import SettingCategories from '@/models/SettingCategories';
import StatsRequest from '@/models/StatsRequest';
import { TimeDuration } from '@/models/TimePeriods';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as searchActions,
  selectors as searchSelectors,
} from '@/redux/api/search';

import { getNewRecentFiltersList } from '@/shared/utils/getNewRecentFilters';

import Alert from '+components/Alert';
import { trafficRecordFields } from '+components/ContextTables';
import { usePageTabs } from '+components/PageTabs';
import SearchForm from '+components/SearchForm';
import { ExportTypes } from '+components/Table/hooks/useExport';
import * as toast from '+components/toast';
import useEvent from '+hooks/useEvent';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import dayjs from '+utils/dayjs';
import { makeId } from '+utils/general';

import Container from './components/Container';

const CurrentViewExportTypes = [
  ExportTypes.csvCurrentView,
  ExportTypes.csvFlattenedCurrentView,
];

const getMinFrom = (retention) =>
  +dayjs(Date.now() - TimeDuration.day * retention).millisecond(0);
const getDefaultFrom = () => {
  const ins = dayjs();
  return +(ins.$u ? ins.utc() : ins).subtract(4, 'h').startOf('second');
};

const getDefaultTo = () => +dayjs().startOf('second');

const createEmptySearchFromLocationSearch = (locationSearch) => {
  // we need to save other (not search page) url search params
  // for example tabs can also use url params like ?tab=users
  const search = new URLSearchParams(locationSearch);
  search.delete('reqid');
  search.delete('from');
  search.delete('to');
  search.delete('nql');
  search.delete('intersect');
  search.delete('customers');
  return search;
};

const lastSearchParams = {};

export const SearchPage = (props) => {
  const {
    nqlContext,
    nqlPlaceholder,
    maxResultCount,
    resultRenderer: ResultRenderer,
    hideDates,
    hideGFButtons,
    sort,
  } = props;

  const [, activePageTab] = usePageTabs();
  const id = `${activePageTab?.id}_${nqlContext}`;
  const fullId = `${id}_full`;
  const dispatch = useDispatch();
  const location = useLocation();
  const navType = useNavigationType();
  const navigate = useNavigate();
  const [resetPageIndex, { inc: triggerResetPageIndex }] = useCounter();

  const [initialValuesRefresher, setInitialValuesRefresher] =
    useState(makeId());
  const [lastSearchRequest, setLastSearchRequest] = useState(null);
  const [exportDataRequestResolve, setExportDataRequestResolve] =
    useState(null);
  const [includeFields, setIncludeFields] = useState([]);
  const includeFieldsSearchCounter = useRef(0);

  const isSearchFetching = useSelector(searchSelectors.isFetching);
  const searchResult = useSelector(searchSelectors.getSearchResult(id));
  const fullSearchResult = useSelector(searchSelectors.getSearchResult(fullId));
  const [recents, changeRecents] = usePortalSettingsValue(
    SettingCategories.ui,
    'globalFiltersRecents',
    [],
  );
  const isCustomerFetching = useSelector(customerSelectors.isFetching);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const retention = useSelector(customerSelectors.getRetention);

  const maxNqlQueries =
    StatsRequest.SearchConfig[nqlContext]?.maxNqlQueries ?? 1;
  const maxIntersects =
    StatsRequest.SearchConfig[nqlContext]?.maxIntersects ?? 0;

  useLoadingIndicator(isSearchFetching);

  const finalFormRef = useRef();

  const searchParams = useMemo(() => {
    const params = {};
    const search = new URLSearchParams(activePageTab?.search);

    if (search.has('reqid')) {
      params.reqid = search.get('reqid');
    }

    if (!hideDates) {
      if (search.has('from')) {
        const from = search.get('from');
        params.from = Number.isNaN(+from)
          ? from
          : Math.max(Math.floor(+search.get('from')), 0);
      }
      if (search.has('to')) {
        const to = search.get('to');
        params.to = Number.isNaN(+to) ? to : Math.max(Math.floor(+to), 0);
      }
    }

    if (search.has('nql')) {
      params.nql = search.getAll('nql').slice(0, maxNqlQueries);
      if (search.has('intersect') && params.nql.length > 1) {
        params.intersect = search.getAll('intersect').slice(0, maxIntersects);
      }
    }

    if (search.has('customers')) {
      params.customers = search.getAll('customers');
    }

    return params;
  }, [activePageTab?.search, maxNqlQueries, maxIntersects, hideDates]);

  const initialValues = useMemo(
    () => {
      const defaultParams = {
        min: getMinFrom(retention),
        from: getDefaultFrom(),
        to: getDefaultTo(),
        startIsMin: false,
        endIsNow: false,
        nql: [''],
        intersect: [],
        customers: [],
        include: includeFields,
      };

      const startIsMin =
        !!searchParams.from && Number.isNaN(+searchParams.from);

      const from = startIsMin
        ? defaultParams.min
        : searchParams.from || defaultParams.from;

      const endIsNow =
        !searchParams.to ||
        Number.isNaN(+searchParams.to) ||
        searchParams.to < Math.min(searchParams.from, 1);

      const to = endIsNow ? defaultParams.to : searchParams.to;

      return omit(
        {
          // we need to add reqid to initValues to restart search form values
          // otherwise it can be situation when user added few empty nql queries,
          // run search and form will have this empty nql fields (because initValues didn't change)
          reqid: searchParams.reqid,
          from,
          to,
          startIsMin,
          endIsNow,
          nql: searchParams.nql || defaultParams.nql,
          intersect: searchParams.intersect || defaultParams.intersect,
          customers: searchParams.customers || defaultParams.customers,
          include: defaultParams.include,
        },
        hideDates ? ['from', 'to', 'startIsMin', 'endIsNow'] : [],
      );
    },
    // we need initialValuesRefresher to clear form in case there was no initial values
    // then user fill form but do not submit it and press clear button
    [
      retention,
      id,
      hideDates,
      searchParams,
      maxNqlQueries,
      initialValuesRefresher,
      includeFields,
    ],
  );

  const onColumnsChange = useEvent(
    (allColumns, hiddenColumns, technicalColumns) => {
      includeFieldsSearchCounter.current = 0;

      let fieldsSet = new Set(
        allColumns
          .map((item) => {
            if (item.realAccessor) {
              return item.realAccessor;
            }
            return typeof item.accessor === 'string' ? item.accessor : item.id;
          })
          .flat()
          .map((field) => {
            if (field.startsWith('tdm.')) {
              return 'tdm';
            }
            if (field.startsWith('label.ip')) {
              return 'label.ip';
            }
            if (field.startsWith('label.port')) {
              return 'label.port';
            }
            return field;
          }),
      );
      // remove hidden columns
      hiddenColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // remove technical columns
      technicalColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // add id - we need it to fetch full record if we need it
      fieldsSet.add('id');
      if (customer?.isReseller) {
        fieldsSet.add('customer');
      }
      // API does not support trafficRecord field.
      // Check if trafficRecord exists, if so, include indivdual fields in fieldSet instead
      // keeping this code latest
      if (fieldsSet.has('trafficRecord')) {
        fieldsSet.delete('trafficRecord');
        fieldsSet = new Set([
          ...fieldsSet,
          ...trafficRecordFields[ContextTypes.flow],
          ...trafficRecordFields[ContextTypes.dns],
        ]);
      }

      const nextValue = [...fieldsSet].filter(Boolean);
      setIncludeFields((prevValue) =>
        isEqual(prevValue, nextValue) ? prevValue : nextValue,
      );
    },
  );

  const onClearLastSearchParams = useCallback(() => {
    delete lastSearchParams[id];
    setLastSearchRequest(null);
    setInitialValuesRefresher(makeId());
  }, [id]);

  const onSearchClear = useCallback(() => {
    onClearLastSearchParams();

    dispatch(searchActions.cancel());
    dispatch(searchActions.searchClear({ id }));

    const search = createEmptySearchFromLocationSearch(activePageTab?.search);
    navigate({ search: search.toString() });
  }, [id, navigate, activePageTab?.search, onClearLastSearchParams]);

  const onGetExportData = useCallback(
    (type, data) => {
      if (!data?.length) {
        return Promise.resolve([]);
      }

      // This +1 made as a workaround that allows NOT TO run bulk search if we need to export all the data (more than maxResultCount).
      // In the future, remove +1 and use maxResultCount instead.
      const isResultLimited = data?.length >= maxResultCount + 1;
      // we need to rerun search if we have limited results or if we need to export all fields
      const needToRunSearch =
        isResultLimited || !CurrentViewExportTypes.includes(type);
      if (!needToRunSearch) {
        return Promise.resolve(data);
      }

      toast.info('Exporting full search results. Please wait...');
      const request = {
        ...lastSearchRequest,
        id: fullId,
        size: isResultLimited ? null : maxResultCount,
        include: !CurrentViewExportTypes.includes(type)
          ? null
          : lastSearchRequest?.include,
      };

      return new Promise((resolve) => {
        dispatch(searchActions.searchClear({ id: fullId }));
        dispatch(searchActions.search(request));

        const doResolve = (results) => {
          resolve(results);
          dispatch(searchActions.searchClear({ id: fullId }));
        };
        setExportDataRequestResolve({ resolve: doResolve });
      });
    },
    [fullId, maxResultCount, lastSearchRequest],
  );

  // Part of onGetExportData
  useEffect(() => {
    if (isSearchFetching || !exportDataRequestResolve) {
      return;
    }

    const { resolve } = exportDataRequestResolve;
    resolve(fullSearchResult);
    setExportDataRequestResolve(null);
  }, [isSearchFetching, exportDataRequestResolve, fullSearchResult]);

  // Load data from lastSearchParams
  const loadParamsFromLastSearch =
    searchResult && !Object.keys(searchParams).length && !!lastSearchParams[id];
  useDebounce(
    () => {
      if (!loadParamsFromLastSearch) {
        return;
      }

      const search = new URLSearchParams(activePageTab?.search);
      Object.entries(lastSearchParams[id]).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((val) => search.append(key, val));
        } else {
          search.set(key, `${value}`);
        }
      });

      navigate({ search: search.toString() }, { replace: true });
    },
    1000,
    [loadParamsFromLastSearch, id, navigate, activePageTab?.search],
  );

  useEffect(() => {
    // Add reqid to search params to run search (for cases when search was run by direct link)
    const needToRunSearch =
      (location.key === 'default' || navType === 'POP') &&
      !searchParams.reqid &&
      Object.keys(searchParams).length > 0;
    if (needToRunSearch) {
      const search = new URLSearchParams(activePageTab?.search);
      search.set('reqid', makeId());
      navigate({ search: search.toString() }, { replace: true });
    }
  }, [location.key, activePageTab?.search, searchParams, navType]);

  const firstRecordRef = useRef();
  firstRecordRef.current = searchResult?.[0];
  useDebounce(
    () => {
      const firstRecord = firstRecordRef.current;
      const skip = !firstRecord || includeFieldsSearchCounter.current > 0;
      if (skip) {
        return;
      }

      const needToRunSearch = includeFields.some(
        // we need firstRecord[field] === undefined for cases when objects are not nested
        // nested example: { label: { ip: '1.1.1.1' } }
        // not nested example: { label.ip: '1.1.1.1' }
        (field) =>
          get(firstRecord, field) === undefined &&
          firstRecord[field] === undefined,
      );

      if (!needToRunSearch) {
        return;
      }

      const search = new URLSearchParams(activePageTab?.search);
      search.set('reqid', makeId());
      navigate({ search: search.toString() }, { replace: true });
      includeFieldsSearchCounter.current += 1;
    },
    1000,
    [includeFields],
  );

  const recentsRef = useRef();
  recentsRef.current = recents;
  const onSearch = useEvent((_values) => {
    const params = _values;

    let start;
    let end;
    if (!hideDates) {
      const now = dayjs().startOf('second');
      start = params.startIsMin
        ? -Math.round((TimeDuration.day * retention) / 1000)
        : -Math.round(now.diff(dayjs(params.from).startOf('second')) / 1000);
      end = params.endIsNow
        ? 0
        : -Math.round(now.diff(dayjs(params.to).startOf('second')) / 1000);
    }

    const request = {
      id,
      context: nqlContext,
      ...(hideDates
        ? {
            last: maxResultCount,
          }
        : {
            start,
            end,
            size: maxResultCount,
          }),
      ...StatsRequest.makeSearch({
        search: params.nql,
        intersect: params.intersect,
      }),
      customers: params.customers,
      include: params.include,
      sort,
    };

    setLastSearchRequest(request);

    const search = createEmptySearchFromLocationSearch(activePageTab?.search);

    const newFrom = params.startIsMin ? 'min' : +params.from;
    if (newFrom) {
      search.set('from', `${newFrom}`);
    }

    const newTo = params.endIsNow ? 'now' : +params.to;
    if (newTo) {
      search.set('to', `${newTo}`);
    }

    const newNql = params.nql.filter((item) => !!item?.trim());
    if (newNql.length) {
      newNql.forEach((val) => search.append('nql', val));
    }

    const newIntersect = params.intersect;
    if (newNql.length > 1 && newIntersect.length) {
      newIntersect.forEach((val) => search.append('intersect', val));
    }

    const newCustomers = params.customers;
    if (newCustomers?.length) {
      newCustomers.forEach((val) => search.append('customers', val));
    }

    changeRecents(
      getNewRecentFiltersList(
        newNql?.[0],
        recentsRef,
        nqlContext,
        customer.shortname,
      ),
    );

    lastSearchParams[id] = {
      from: newFrom,
      to: newTo,
      nql: newNql,
      intersect: newIntersect,
      customers: newCustomers,
    };

    navigate({ search: search.toString() }, { replace: true });
  });

  // do search if we have reqid
  const lastReqId = useRef();
  const submitTimer = useRef();
  useEffect(() => {
    // we need to wait for customer fetching because we need to know retention
    if (isCustomerFetching) {
      return;
    }

    if (lastReqId.current) {
      lastReqId.current = null;

      submitTimer.current = setTimeout(() => {
        finalFormRef.current?.mutators.runValidation();
        finalFormRef.current?.submit();
      }, 1000);

      return;
    }

    const params = initialValues;

    // if dates are hidden then request params can be empty but if they don't - we need to have any params
    const hasReqId = params.reqid;
    const hasRequestParams =
      hideDates || params.from || params.to || params.nql || params.customers;
    if (!hasReqId || !hasRequestParams || lastReqId.current === params.reqid) {
      return;
    }

    lastReqId.current = params.reqid;

    // Remove request id from search params
    const search = new URLSearchParams(activePageTab?.search);
    if (search.has('reqid')) {
      search.delete('reqid');
    }

    navigate(
      { pathname: activePageTab?.pathname, search: search.toString() },
      { replace: true },
    );
  }, [
    isCustomerFetching,
    initialValues,
    id,
    hideDates,
    activePageTab?.pathname,
    activePageTab?.search,
    navigate,
  ]);

  useEffect(
    () => () => {
      clearTimeout(submitTimer.current);
    },
    [],
  );

  // Search
  useEffect(() => {
    if (!lastSearchRequest) {
      return undefined;
    }

    const namespace = `${id}_search`;
    dispatch(searchActions.searchClear({ id }));
    dispatch(searchActions.search(lastSearchRequest, namespace));

    triggerResetPageIndex();

    return () => {
      dispatch(searchActions.cancel(namespace));
    };
  }, [id, lastSearchRequest]);

  return (
    <Container>
      <SearchForm
        initialValues={initialValues}
        nqlContext={nqlContext}
        nqlPlaceholder={nqlPlaceholder}
        onSearch={onSearch}
        onSearchClear={onSearchClear}
        searchDisabled={isSearchFetching}
        hideDates={hideDates}
        hideGFButtons={hideGFButtons}
        finalFormRef={finalFormRef}
      />

      {searchResult?.length >= maxResultCount && (
        <Alert severity="info">
          Results were limited to {maxResultCount} records. Please consider
          adding more conditions so results are not truncated.
        </Alert>
      )}

      <ResultRenderer
        data={searchResult || []}
        searchId={id}
        loading={isSearchFetching}
        onColumnsChange={onColumnsChange}
        onGetExportData={onGetExportData}
        resetPageIndex={resetPageIndex || null}
      />
    </Container>
  );
};

SearchPage.displayName = 'SearchPage';

SearchPage.propTypes = {
  nqlContext: PropTypes.string,
  nqlPlaceholder: PropTypes.string,
  maxResultCount: PropTypes.number,
  resultRenderer: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  hideDates: PropTypes.bool,
  hideGFButtons: PropTypes.bool,
  sort: PropTypes.shape(),
};

SearchPage.defaultProps = {
  nqlContext: '',
  nqlPlaceholder: '',
  maxResultCount: 1000,
  hideDates: false,
  hideGFButtons: false,
  sort: {
    field: 'timestamp',
    order: 'desc',
  },
};

export default SearchPage;
