import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';
import SettingCategories from '@/models/SettingCategories';
import { ThemeTypes } from '@/models/ThemeTypes';
import TimeZones from '@/models/TimeZones';

import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Button, { ButtonVariants } from '+components/Button';
import FeatureFlag from '+components/FeatureFlag';
import Form from '+components/form/Form';
import {
  Description,
  FieldContainer,
  Group,
  Label,
} from '+components/form/FormField';
import { normalizeSelectValue } from '+components/form/Normalizers';
import Select from '+components/form/Select';
import Toggle from '+components/form/Toggle';
import { LayoutSizes, Row } from '+components/Layout';
import useEvent from '+hooks/useEvent';
import usePermissions from '+hooks/usePermissions';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import dayjs from '+utils/dayjs';

const localTz = dayjs.tz.guess();

const SettingsSection = () => {
  const [tz, changeTz] = usePortalSettingsValue(
    SettingCategories.ui,
    'tz',
    'Etc/UTC',
  );
  const [theme, changeTheme, isFetching] = usePortalSettingsValue(
    SettingCategories.ui,
    'theme',
    ThemeTypes.dark,
  );
  const [isTimeFormat12h, changeTimeFormat] = usePortalSettingsValue(
    SettingCategories.ui,
    'isTimeFormat12h',
    false,
  );
  const [isNotificationPopupsEnabled, changeNotificationPopups] =
    usePortalSettingsValue(
      SettingCategories.ui,
      'isNotificationPopupsEnabled',
      true,
    );
  const [realTimePaused, setRealTimePaused] = usePortalSettingsValue(
    SettingCategories.system,
    'realTimePaused',
    true,
  );
  const [companyRealTimePaused, setCompanyRealTimePaused] =
    usePortalSettingsValue(SettingCategories.ui, 'companyRealTimePaused');
  const isCompanyRealTimePaused = companyRealTimePaused ?? realTimePaused;

  const [userRealTimePaused, setUserRealTimePaused] = usePortalSettingsValue(
    SettingCategories.ui,
    'userRealTimePaused',
  );
  const isUserRealTimePaused = userRealTimePaused ?? isCompanyRealTimePaused;

  const permissions = usePermissions(PermissionModel.Resources.account.value);
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);

  const onTzChange = useCallback(
    (value) => changeTz(normalizeSelectValue(value)),
    [],
  );

  const setLocalTz = useCallback(() => onTzChange(localTz), []);

  const onThemeChange = useEvent(() =>
    changeTheme(theme === ThemeTypes.dark ? ThemeTypes.light : ThemeTypes.dark),
  );

  const onTimeFormatChange = useEvent(() => changeTimeFormat(!isTimeFormat12h));

  const onNotificationPopupsChange = useEvent(() =>
    changeNotificationPopups(!isNotificationPopupsEnabled),
  );

  const onRealTimePausedChange = useEvent(() => {
    setRealTimePaused(!realTimePaused);
  });

  const onCompanyRealTimePausedChange = useEvent(() => {
    let newValue = !isCompanyRealTimePaused;
    if (isUserRealTimePaused != null && newValue === isUserRealTimePaused) {
      setUserRealTimePaused(null);
    }
    newValue = newValue === realTimePaused ? null : newValue;
    setCompanyRealTimePaused(newValue, true);
  });
  const onUseSystemSetting = useCallback(() => {
    if (
      isUserRealTimePaused != null &&
      isUserRealTimePaused === realTimePaused
    ) {
      setUserRealTimePaused(null);
    }
    setCompanyRealTimePaused(null);
  }, []);

  const onUserRealTimePausedChange = useEvent(() => {
    let newValue = !isUserRealTimePaused;
    newValue = newValue === isCompanyRealTimePaused ? null : newValue;
    setUserRealTimePaused(newValue);
  });
  const onUseCompanySetting = useCallback(() => {
    setUserRealTimePaused(null);
  }, []);

  return (
    <Form>
      <Group>
        <Label>Theme</Label>
        <FieldContainer>
          <Toggle
            uncheckedLabel="Dark Theme"
            checked={theme !== ThemeTypes.dark}
            onChange={onThemeChange}
            checkedLabel="Light Theme"
            twoOptionToggle
            disabled={isFetching}
          />
        </FieldContainer>
      </Group>

      <Group>
        <Label>Time Format</Label>
        <FieldContainer>
          <Toggle
            uncheckedLabel="Time in 24"
            checked={isTimeFormat12h}
            onChange={onTimeFormatChange}
            checkedLabel="12 Hours"
            twoOptionToggle
            disabled={isFetching}
          />
        </FieldContainer>
      </Group>

      <FeatureFlag flag={FeatureFlags.notificationPopupsManager}>
        <Group>
          <Label>Notification Popups</Label>
          <FieldContainer>
            <Row gap={LayoutSizes.groupGap} wrap="nowrap" alignItems="center">
              <Toggle
                checked={isNotificationPopupsEnabled}
                onChange={onNotificationPopupsChange}
                uncheckedLabel="Disabled"
                checkedLabel="Enabled"
                disabled={isFetching}
              />
            </Row>
          </FieldContainer>
        </Group>
      </FeatureFlag>

      <Group style={{ maxWidth: '475px' }}>
        <Label>Time Zone</Label>
        <FieldContainer>
          <Row gap={LayoutSizes.groupGap} wrap="nowrap" alignItems="center">
            <Select
              name="tz"
              value={tz}
              options={TimeZones}
              onChange={onTzChange}
              disabled={isFetching}
            />

            <Button
              variant={ButtonVariants.outlined}
              disabled={isFetching || tz === localTz}
              onClick={setLocalTz}
            >
              OS time zone
            </Button>
          </Row>
        </FieldContainer>
      </Group>

      {permissions && isDefaultCustomer && (
        <Group>
          <Label>Real-Time By Default</Label>
          <FieldContainer>
            <Toggle
              checked={realTimePaused}
              onChange={onRealTimePausedChange}
              checkedLabel="Paused"
              disabled={isFetching}
            />
          </FieldContainer>
        </Group>
      )}

      {permissions && !isDefaultCustomer && (
        <Group>
          <Label>Real-Time By Default</Label>
          <FieldContainer>
            <Row gap={LayoutSizes.groupGap} wrap="nowrap" alignItems="center">
              <Toggle
                checked={isUserRealTimePaused}
                onChange={onUserRealTimePausedChange}
                checkedLabel="Paused"
                disabled={isFetching}
              />

              <Button
                onClick={onUseCompanySetting}
                variant={ButtonVariants.outlined}
                disabled={isFetching || userRealTimePaused == null}
              >
                Use company setting
              </Button>
            </Row>
          </FieldContainer>
        </Group>
      )}

      {permissions?.update && !isDefaultCustomer && (
        <Group>
          <Label>Company Real-Time By Default</Label>
          <FieldContainer>
            <Row gap={LayoutSizes.groupGap} wrap="nowrap" alignItems="center">
              <Toggle
                checked={isCompanyRealTimePaused}
                onChange={onCompanyRealTimePausedChange}
                checkedLabel="Paused"
                disabled={isFetching}
              />

              <Button
                onClick={onUseSystemSetting}
                variant={ButtonVariants.outlined}
                disabled={isFetching || companyRealTimePaused == null}
              >
                Use system setting
              </Button>
            </Row>
          </FieldContainer>
          <Description>
            This setting will be used by default for all users in the company.
            <br />
            If user does not have own setting, this setting will be used.
          </Description>
        </Group>
      )}
    </Form>
  );
};

export default SettingsSection;
