import PropTypes from '+prop-types';
import { Fragment, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import FeatureFlags from '@/models/FeatureFlags';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';
import { actions as thresholdActions } from '@/redux/api/thresholder';

import { CardTitle } from '+components/Card';
import ArrayNQLField from '+components/form/ArrayNQLField';
import FieldsSection from '+components/form/FieldsSection';
import {
  Field,
  FieldArray,
  useForm,
  useFormState,
} from '+components/form/FinalForm';
import { FieldContainer, Group, Label } from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import {
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import SelectField from '+components/form/SelectField';
import { SliderField } from '+components/form/Slider';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import ToggleRow from '+components/form/ToggleRow';
import { validateRequired } from '+components/form/Validators';
import CategoryLabel from '+components/Labels/CategoryLabel';
import { Col } from '+components/Layout';
import { CellNqlSearch } from '+components/Table/Cells';
import UniversalField from '+components/UniversalField';
import sortBySystemAndValue from '+utils/sortBySystemAndValue';

import CellAlgorithmName from '../../../../shared/components/Table/Cells/CellAlgorithmName';
import TrackLabel from '../../shared/LabelTrack';
import RenderSearchBy from '../../shared/RenderSearchBy';
import RenderThresholds from '../../shared/RenderThresholds';
import RenderTrackBy from '../../shared/RenderTrackBy';
import {
  formatTimeSliderLabel,
  getSliderMarks,
} from '../../shared/sliderUtils';
import { trafficTypes } from '../../shared/trafficTypes';
import { Config, trackFromDdToUiConverter } from '../../shared/utils';
import AutoThresholdsSection from './AutoThresholdsSection';

const SearchByContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const FormBody = (props) => {
  const {
    canManage,
    mode,
    initialValuesMap,
    isDefaultCustomer,
    disabledTrafficType,
  } = props;

  const dispatch = useDispatch();

  const isDnsEnabled = useFlag(FeatureFlags.dns);
  const autoThresholdsFeatureFlag = useFlag(FeatureFlags.autoThresholds);

  const { values } = useFormState();
  const form = useForm();

  const context = values.algo_record_type || ContextTypes.flow;

  const algorithmData = useMemo(
    () => initialValuesMap?.[context],
    [initialValuesMap, context],
  );

  const canManageSystem =
    mode !== 'update' || !values.system || isDefaultCustomer;

  const categories = useSelector(rulesSelectors.getCategories);

  const categoriesOptions = useMemo(
    () =>
      (categories || [])
        .map((el) => ({
          value: el.name,
          label: el.name,
          description: el.description,
          system: el.system,
          systemdefault: el.systemdefault,
        }))
        .sort(sortBySystemAndValue),
    [categories],
  );

  useEffect(() => {
    if (algorithmData?.name && autoThresholdsFeatureFlag) {
      dispatch(
        thresholdActions.fetchAutomaton({
          name: algorithmData?.name,
        }),
      );
    }
  }, [algorithmData, autoThresholdsFeatureFlag]);

  const prevValues = useRef(initialValuesMap);
  const prevContext = useRef(context);
  useEffect(() => {
    if (prevContext.current === context) {
      return;
    }

    const prev = prevValues.current[context];

    prevValues.current[prevContext.current] = values;
    prevContext.current = context;

    form.batch(() => {
      if (
        prev?.search_by &&
        JSON.stringify(values.search_by) !== JSON.stringify(prev.search_by)
      ) {
        form.resetFieldState('search_by');
        form.change('search_by', prev.search_by);
      }

      if (
        prev?.thresholds &&
        JSON.stringify(values.thresholds) !== JSON.stringify(prev.thresholds)
      ) {
        form.resetFieldState('thresholds');
        form.change('thresholds', prev.thresholds);
      }

      if (
        prev?.track_by &&
        JSON.stringify(values.track_by) !== JSON.stringify(prev.track_by)
      ) {
        form.resetFieldState('track_by');
        form.change('track_by', prev.track_by);
      }

      if (
        prev?.discards &&
        JSON.stringify(values.discards) !== JSON.stringify(prev.discards)
      ) {
        form.resetFieldState('discards');
        form.change('discards', prev.discards);
      }
    });
  }, [context, values]);

  useDebounce(
    () => {
      // Workaround:
      // remove search_by if search_by[*].type is empty
      // when context changes
      const searchBy = values.search_by.filter((searchByItem) => {
        return !!searchByItem.type;
      });
      if (JSON.stringify(values.search_by) !== JSON.stringify(searchBy)) {
        form.resetFieldState('search_by');
        form.change('search_by', searchBy);
      }
    },
    10,
    [context, values],
  );

  // load dependencies for the dropdown fields (categories)
  useEffect(() => {
    dispatch(rulesActions.fetchDependencies());
  }, []);

  return (
    <Fragment>
      <CardTitle
        head="Detection Model Configuration"
        subhead="Detection Models are always-running search conditions applied to incoming flow. They generate Alerts when thresholds are exceeded."
        style={{ marginLeft: '140px' }}
      />

      {mode === 'update' && (
        <Group>
          <Label>Name</Label>
          <Plaintext>
            <CellAlgorithmName name={values.name || ''} />
          </Plaintext>
        </Group>
      )}

      {mode === 'create' && (
        <Field
          name="name"
          label="Name"
          component={TextField}
          type="text"
          validate={validateRequired}
          maxLength={Config.maxNameLength}
          autoComplete="new-password"
          helperText={`Unique name of the detection model. Valid characters are 0-9a-zA-Z._-. Max length of ${Config.maxNameLength}.`}
          style={{ maxWidth: '290px' }}
          disabled={!canManage}
          required
        />
      )}

      {canManageSystem ? (
        <Field
          name="description"
          label="Description"
          component={TextField}
          type="text"
          validate={validateRequired}
          maxLength={Config.maxDescriptionLength}
          autoComplete="new-password"
          helperText={`Longer description of the detection model. Any RFC 3986 (URI) characters are allowed. Max length of ${Config.maxDescriptionLength}.`}
          disabled={!canManage}
          required
        />
      ) : (
        <Group>
          <Label>Description</Label>
          <FieldContainer>{values.description}</FieldContainer>
        </Group>
      )}

      {canManageSystem ? (
        <Field
          name="categories"
          label="Categories"
          component={MultiSelectField}
          options={categoriesOptions}
          allowCreate
          groupBy={(item) => (item.system ? 'System' : 'Custom')}
          parse={normalizeMultiSelectValue}
          validate={validateRequired}
          disabled={!canManage}
          required
        />
      ) : (
        <Group>
          <Label>Categories</Label>
          <FieldContainer>
            {values.categories.map((category) => (
              <CategoryLabel key={`category-${category}`} name={category} />
            ))}
          </FieldContainer>
        </Group>
      )}

      {isDnsEnabled && (
        <Fragment>
          {(disabledTrafficType || mode === 'update') && (
            <Group>
              <Label>Traffic Type</Label>
              <Plaintext>
                <UniversalField
                  field="algo_record_type"
                  // eslint-disable-next-line react/prop-types
                  value={values.algo_record_type}
                  disabled
                />
              </Plaintext>
            </Group>
          )}

          {!disabledTrafficType && mode === 'create' && (
            <Field
              name="algo_record_type"
              label="Traffic Type"
              style={{ width: '30%' }}
              component={SelectField}
              options={trafficTypes}
              parse={normalizeSelectValue}
              validate={validateRequired}
              disabled={!canManage}
              required
            />
          )}
        </Fragment>
      )}

      {isDefaultCustomer && (
        <Field
          name="subscriptiontype"
          label="Subscription Type"
          component={SelectField}
          style={{ width: '30%' }}
          options={[
            {
              value: 'optout',
              label: 'Opt-out',
            },
            {
              value: 'optin',
              label: 'Opt-in',
            },
          ]}
          parse={normalizeSelectValue}
          disabled={!canManage}
        />
      )}

      <ToggleRow label="Options">
        <Field
          name="enabled"
          component={ToggleField}
          type="checkbox"
          checkedLabel="Enable Detection Model"
          disabled={!canManage}
        />

        <Field
          name="bypassrule"
          component={ToggleField}
          type="checkbox"
          checkedLabel="Enable Policies and Integrations"
          disabled={!canManage}
          parse={(v) => !v}
          format={(v) => !v}
        />
      </ToggleRow>

      {isDefaultCustomer && (
        <Fragment>
          <Field
            name="beta"
            label="Beta"
            component={ToggleField}
            type="checkbox"
            checkedLabel="Enabled"
            disabled={!canManage}
          />

          <Field
            name="recommended"
            label="Recommended"
            component={ToggleField}
            type="checkbox"
            checkedLabel="Enabled"
            disabled={!canManage}
          />

          <Field
            name="bypassanalytics"
            label="Bypass Analytics"
            component={ToggleField}
            type="checkbox"
            checkedLabel="Enabled"
            disabled={!canManage}
            helperText="Disable to force inspection even when disabled by a customer"
          />
        </Fragment>
      )}

      <FieldsSection label="TRAFFIC MATCH" boldLabel formLabelMargin>
        {canManageSystem ? (
          <FieldArray
            name="search_by"
            label="NQL Search"
            helperText={
              <Fragment>
                NQL applied to all traffic types. Ignored if one or more
                type-specific NQL strings are provided.
                <br />
                If using type-specific NQL and empty, aws traffic type will not
                be matched.
                <br />
                You cannot use both all and specific search types in a Detection
                Model.
              </Fragment>
            }
            component={RenderSearchBy}
            context={context}
            disabled={!canManage}
            required
          />
        ) : (
          <Group>
            <Label>NQL Search</Label>
            <Plaintext>
              {values?.search_by.map((searchBy, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SearchByContainer key={index}>
                  {searchBy.search.map((search, i) => (
                    <CellNqlSearch
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      type={i === 0 ? searchBy.type : undefined}
                      value={search}
                      context={values.algo_record_type}
                    />
                  ))}
                </SearchByContainer>
              ))}
            </Plaintext>
          </Group>
        )}

        <FieldArray
          name="discards"
          label="Discards"
          helperText={
            <Fragment>
              Discard lists are NQL statements that if matched do not get
              processed through the event.
              <br />
              It enables skipping certain combinations without disabling the
              detection model.
            </Fragment>
          }
          component={ArrayNQLField}
          context={context}
          maxLength={Config.maxDiscards}
          disabled={!canManage}
        />
      </FieldsSection>
      <FieldsSection label="THRESHOLDS" boldLabel formLabelMargin>
        <Col>
          {canManageSystem ? (
            <FieldArray
              name="track_by"
              label="Track By Fields"
              component={RenderTrackBy}
              maxLength={Config.maxTrackBy}
              disabled={!canManage}
              context={context}
              required
              addGlobal
            />
          ) : (
            <Group>
              <Label>Track By</Label>
              <Plaintext>
                {values.track_by.map((track, idx) => (
                  <TrackLabel
                    // eslint-disable-next-line react/no-array-index-key
                    key={`track_by-${idx}`}
                    fields={trackFromDdToUiConverter(track) || []}
                  />
                ))}
              </Plaintext>
            </Group>
          )}

          <FieldArray
            name="thresholds"
            label="Thresholds"
            helperText="Expression used to calculate the threshold"
            component={RenderThresholds}
            context={
              context === ContextTypes.dns
                ? ContextTypes.thresholdDns
                : ContextTypes.thresholdFlow
            }
            maxLength={Config.maxThresholds}
            disabled={!canManage}
            required
          />

          <Field
            name="rollupperiod"
            label="Rollup Period"
            component={SliderField}
            step={1}
            min={15}
            max={3600}
            defaultValue={15}
            isRangeSlider={false}
            showInputField
            marks={getSliderMarks(3, 900)}
            disabled={!canManage}
            helperText="The lookback period for the detection model. Min 15 seconds. Max 1 hour (3600)."
            inputHelperText="Seconds"
            valueLabelFormat={formatTimeSliderLabel}
            required
          />
          <Field
            name="updateinterval"
            label="Update Interval"
            component={SliderField}
            step={1}
            min={0}
            max={21600}
            defaultValue={0}
            isRangeSlider={false}
            showInputField
            marks={getSliderMarks(5, 3600)}
            disabled={!canManage}
            helperText="When ongoing updates should be sent. Max 6 hours (21600). 0 for disabled."
            inputHelperText="Seconds"
            valueLabelFormat={formatTimeSliderLabel}
            required
          />

          {autoThresholdsFeatureFlag &&
            context === ContextTypes.flow &&
            (algorithmData?.can_auto_threshold || mode === 'create') && (
              <AutoThresholdsSection canManage={canManage} />
            )}
        </Col>
      </FieldsSection>
      <FieldsSection label="SCORING" boldLabel formLabelMargin>
        <Field
          name="ndm_score_threat"
          label="Threat Score"
          component={SliderField}
          step={1}
          min={0}
          max={100}
          defaultValue={0}
          isRangeSlider={false}
          showInputField
          marks={getSliderMarks(9, 10)}
          disabled={!canManage}
          helperText="Enter a number from 0-100 where 0 is the lowest perceived threat and 100 is the highest perceived threat"
        />
        <Field
          name="ndm_score_confidence"
          label="Confidence Score"
          component={SliderField}
          step={1}
          min={0}
          max={100}
          defaultValue={0}
          isRangeSlider={false}
          showInputField
          marks={getSliderMarks(9, 10)}
          disabled={!canManage}
          helperText="Enter a number from 0-100 where 0 is the lowest certainty and 100 is the highest certainty"
        />
      </FieldsSection>
    </Fragment>
  );
};

FormBody.propTypes = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  initialValuesMap: PropTypes.shape().isRequired,
  canManage: PropTypes.bool.isRequired,
  isDefaultCustomer: PropTypes.bool.isRequired,
  disabledTrafficType: PropTypes.bool.isRequired,
};

export default FormBody;
