import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { config } from '@/config';

export const logRocket = {
  init: () => {
    if (!config.logRocket.enabled) {
      return;
    }

    // https://docs.logrocket.com/reference/init
    LogRocket.init(config.logRocket.appId, {
      release: config.version,
      shouldDebugLog: config.environment === 'development',
      console: {
        isEnabled: {
          debug: false,
        },
      },
      dom: {
        // https://docs.logrocket.com/reference/dom
        privateAttributeBlocklist: ['data-hide-this'],
      },
    });
    setupLogRocketReact(LogRocket);
  },
  identify: (id, data) => {
    if (!config.logRocket.enabled) {
      return;
    }

    LogRocket.identify(id, data);
  },
  middleware: () =>
    config.logRocket.enabled
      ? LogRocket.reduxMiddleware({
          // https://docs.logrocket.com/reference/redux-logging#sanitize-redux-state
          stateSanitizer: () => null,
          // https://docs.logrocket.com/reference/redux-logging#sanitize-redux-actions
          actionSanitizer: () => null,
        })
      : () => (state) => state,
};
