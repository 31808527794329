import makeArr from '+utils/makeArr';

const resolve = (path, obj) => {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
    // eslint-disable-next-line no-restricted-globals
  }, obj || self);
};

export const normalizeSelectValue = (value) => value?.value ?? value;

export const normalizeMultiSelectValue = (arr) =>
  makeArr(arr)
    .map(normalizeSelectValue)
    .filter((value) => value != null || value !== '');

export const normalizeDateTimePickerValue = (value) =>
  value instanceof Date && !Number.isNaN(+value) ? value.toISOString() : value;
export const normalizeDateTimeToNumber = (value) =>
  value == null ? null : +value;

export const normalizeSlackChannelNameValue = (value) =>
  `${value || ''}`.replace(/^#+/, '');

export const normalizeNumber = (value) => (value ? +value : null);

export const lessThan = (otherField) => (value, previousValue, allValues) =>
  +value < +resolve(otherField, allValues) ? +value : +previousValue;

export const greaterThan = (otherField) => (value, previousValue, allValues) =>
  +value > +resolve(otherField, allValues) ? +value : +previousValue;

export const betweenThese =
  (minField, maxField) => (value, previousValue, allValues) => {
    return lessThan(maxField)(
      greaterThan(minField)(value, previousValue, allValues),
      previousValue,
      allValues,
    );
  };
