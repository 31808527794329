import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: ${(props) => (props.$isOpen ? props.$width : 0)}px;
  background-color: ${({ theme }) => theme.colorBackground};
  transition: ${({ $resizing }) =>
    $resizing ? 'unset' : 'width 0.3s ease-in-out 0.1s'};
  border-left: ${({ $separatorWidth }) => $separatorWidth}px solid
    ${({ theme }) => theme.colorBackgroundSeparator};

  z-index: 99998;
`;
