import { call, put, select } from 'redux-saga/effects';

import { PluginTypes } from '@/models/integrations/PluginTypes';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  pseudoTtlCache,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  error: '',
  allFetched: false,
  integrations: {},
  awsRoleData: {},
  testResults: null,
  testLabels: null,
  runResults: null,
  integrationManifests: {},
  integrationModels: {},
};

pseudoTtlCache.setWithTtl(initialState, 'integrationManifests', {}, 1);

const apiPathContext = '/integrations/context';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'contextIntegrations',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchContextIntegrations: startFetching,
    fetchContextIntegrationsSuccess(state, { payload: integrations }) {
      stopFetching(state);
      state.allFetched = true;
      integrations.forEach((item) => {
        state.integrations[item.id] = item;
      });
    },

    fetchContextIntegration: startFetching,
    fetchContextIntegrationSuccess(state, { payload: integration }) {
      stopFetching(state);
      state.integrations[integration.id] = integration;
    },

    fetchAwsRoleData: startFetching,
    fetchAwsRoleDataSuccess(state, { payload: roleData }) {
      stopFetching(state);
      state.awsRoleData.awsAccountId = roleData.netography_aws_accountid;
      state.awsRoleData.externalId = roleData.external_id;
      state.awsRoleData.trustPolicyExample = roleData.aws_trust_policy_example;
    },

    fetchIntegrationManifests: startFetching,
    fetchIntegrationManifestsSuccess(state, { payload: manifests }) {
      stopFetching(state);

      pseudoTtlCache.setWithTtl(state, 'integrationManifests', {}, 3e5);
      state.integrationModels = {};

      (manifests || []).forEach((manifest) => {
        state.integrationManifests[manifest.id] = manifest;
        state.integrationModels[manifest.id] = {
          type: PluginTypes.context,
          name: manifest.id,
          title: manifest.provider,
          manifest,
        };
      });
    },

    createContextIntegration: startFetching,
    createContextIntegrationSuccess(state, { payload: integration }) {
      stopFetching(state);
      state.integrations[integration.id] = integration;
    },

    updateContextIntegration: startFetching,
    updateContextIntegrationSuccess(state, { payload: integration }) {
      stopFetching(state);
      state.integrations[integration.id] = integration;
    },

    removeContextIntegration: startFetching,
    removeContextIntegrationSuccess(state, { payload: integration }) {
      stopFetching(state);
      delete state.integrations[integration.id];
    },
    bulkDeleteContextIntegrations: startFetching,
    bulkdDeleteSuccess(state, { payload }) {
      stopFetching(state);
      payload.forEach(
        (integration) => delete state.integrations[integration.id],
      );
    },

    updateContextIntegrationKeys: startFetching,
    updateContextIntegrationKeysSuccess(state, { payload: integration }) {
      stopFetching(state);
      state.integrations[integration.id] = integration;
    },

    testContextIntegration: startFetching,
    testContextIntegrationSuccess(state, { payload: results }) {
      stopFetching(state);
      state.testResults = results;
    },
    testContextIntegrationClear(state) {
      state.testResults = null;
    },

    runContextIntegration: startFetching,
    runContextIntegrationSuccess(state, { payload: results }) {
      stopFetching(state);
      state.runResults = results;
    },
    runContextIntegrationClear(state) {
      state.runResults = null;
    },

    setTestLabels(state, { payload: labels }) {
      state.testLabels = labels;
    },
    clearTestLabels(state) {
      state.testLabels = null;
    },

    skip: stopFetching,
  },

  sagas: (actions, selectors) => ({
    [actions.fetchContextIntegrations]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.get, apiPathContext);
          yield put(
            actions.fetchContextIntegrationsSuccess(response.data.data),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching context integrations',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchContextIntegration]: {
      *saga({ payload: { id, isDynamic = false } }) {
        initApi();
        try {
          const response = yield call(
            api.get,
            `${apiPathContext}/${isDynamic ? 'dynamic/' : ''}${id}`,
          );
          yield put(actions.fetchContextIntegrationSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching context integration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchIntegrationManifests]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        const path = `${apiPathContext}/manifests`;

        const state = yield select(selectors.getState);

        if (pseudoTtlCache.isValid(state, 'integrationManifests')) {
          yield put(actions.skip());
          return;
        }

        try {
          const response = yield call(api.get, path);
          yield put(
            actions.fetchIntegrationManifestsSuccess(response.data.data),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching context integrations manifests',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.createContextIntegration]: {
      *saga({
        payload: { isDynamic = false, silent = false, ...integration },
      }) {
        initApi();
        try {
          const response = yield call(api.post, apiPathContext, {
            ...integration,
            isDynamic,
          });
          yield put(
            actions.createContextIntegrationSuccess(response.data.data),
          );
          const integrationEnabled = response.data.data.enabled;
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `Context integration has been created ${
                integrationEnabled && !isDynamic
                  ? 'and new labels imported'
                  : ''
              } successfully`,
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating context integration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateContextIntegration]: {
      *saga({
        payload: { isDynamic = false, silent = false, ...integration },
      }) {
        initApi();
        try {
          const response = yield call(
            api.put,
            `${apiPathContext}/${integration.id}`,
            { ...integration, isDynamic },
          );
          yield put(
            actions.updateContextIntegrationSuccess(response.data.data),
          );
          const integrationEnabled = response.data.data.enabled;
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `Context integration has been updated ${
                integrationEnabled && !isDynamic
                  ? 'and new labels imported'
                  : ''
              } successfully`,
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating context integration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeContextIntegration]: {
      *saga({ payload: { isDynamic = false, ...integration } }) {
        initApi();
        try {
          const response = yield call(
            api.delete,
            isDynamic
              ? `${apiPathContext}/dynamic/${integration.id}`
              : `${apiPathContext}/${integration.id}`,
          );
          yield put(actions.removeContextIntegrationSuccess(integration));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Context integration has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting context integration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDeleteContextIntegrations]: {
      *saga({ payload }) {
        initApi();
        try {
          const response = yield call(api.put, `${apiPathContext}/bulkdelete`, {
            data: payload,
          });
          yield put(actions.bulkdDeleteSuccess(payload));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Context integrations have been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting context integrations',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.testContextIntegration]: {
      *saga({ payload: { silent = false, ...integration } }) {
        initApi();

        try {
          const response = yield call(
            api.get,
            `${apiPathContext}/${integration.id}/run`,
          );
          yield put(actions.testContextIntegrationSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Context integration tested successfully',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error testing integration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.runContextIntegration]: {
      *saga({ payload }) {
        initApi();
        const {
          silent = false,
          afterCreateMode = false,
          afterUpdateMode = false,
          ...integration
        } = payload;
        try {
          const response = yield call(
            api.put,
            `${apiPathContext}/${integration.id}/run`,
          );
          yield put(actions.runContextIntegrationSuccess(response.data.data));
          // yield put(labelActions.clear());
          if (afterCreateMode || afterUpdateMode) {
            yield put(
              toastActions.successWithAuditLogVerification({
                message: afterCreateMode
                  ? 'Context integration has been created and new labels imported successfully'
                  : 'Context integration has been updated and new labels imported successfully',
                response,
                showWarningOnly: silent,
              }),
            );
          } else {
            yield put(
              toastActions.successWithAuditLogVerification({
                message: 'Context integration ran successfully',
                response,
                showWarningOnly: silent,
              }),
            );
          }
        } catch (error) {
          yield put(actions.fail(error));
          if (afterCreateMode || afterUpdateMode) {
            yield put(
              toastActions.warn({
                message: afterCreateMode
                  ? 'Context integration has been created successfully but there was an error running it'
                  : 'Context integration has been updated successfully but there was an error running it',
                details: error.message,
              }),
            );
          } else {
            yield put(
              toastActions.error({
                message: 'Error running integration',
                details: error.message,
              }),
            );
          }
        }
      },
    },

    [actions.updateContextIntegrationKeys]: {
      *saga({ payload: { silent = false, ...integration } }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPathContext}/${integration.id}/regenkeys`,
          );
          yield put(
            actions.updateContextIntegrationKeysSuccess(response.data.data),
          );
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Context integration keys have been updated',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating context integration keys',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchAwsRoleData]: {
      *saga() {
        initApi();

        try {
          const response = yield call(api.get, 'integrations/aws/role');
          yield put(actions.fetchAwsRoleDataSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching AWS Account ID',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    getError: createSelector([getState], (state) => state.error),

    areAllFetched: createSelector([getState], (state) => state.allFetched),

    getTestResults: createSelector([getState], (state) => state.testResults),

    getTestLabels: createSelector([getState], (state) => state.testLabels),

    getContextIntegrations: createSelector(
      [getState],
      (state) => state.integrations,
    ),

    getContextIntegration: (id) =>
      createSelector([getState], (state) => state.integrations?.[id]),
    getContextIntegrationByName: (name) =>
      createSelector([getState], (state) =>
        Object.values(state.integrations).find(
          (integration) => integration.name === name,
        ),
      ),
    getAwsAccountId: createSelector(
      [getState],
      (state) => state.awsRoleData?.awsAccountId,
    ),
    getExternalId: createSelector(
      [getState],
      (state) => state.awsRoleData?.externalId,
    ),
    getTrustPolicyExample: createSelector(
      [getState],
      (state) => state.awsRoleData?.trustPolicyExample,
    ),
    getContextIntegrationManifests: createSelector(
      [getState],
      (state) => state.integrationManifests,
    ),

    getContextIntegrationModels: createSelector(
      [getState],
      (state) => state.integrationModels,
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
