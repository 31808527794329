import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import ArrowBottomLeftIcon from 'mdi-react/ArrowBottomLeftIcon';
import ArrowTopRightIcon from 'mdi-react/ArrowTopRightIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import FeatureFlags from '@/models/FeatureFlags';
import SettingCategories from '@/models/SettingCategories';
import StatsRequest from '@/models/StatsRequest';
import { TimeDuration } from '@/models/TimePeriods';

import { selectors as selectorsCustomer } from '@/redux/api/customer';
import { FetchStates } from '@/redux/api/nql-complete';
import { selectors as profileSelectors } from '@/redux/api/user/profile';
import { actions as globalFiltersActions } from '@/redux/globalFilters';

import Button, { ButtonVariants } from '+components/Button';
import ArrayNQLField from '+components/form/ArrayNQLField';
import { DateTimePickerField } from '+components/form/DateTimePicker';
import {
  Field,
  FieldArray,
  useField,
  useForm,
  useFormState,
} from '+components/form/FinalForm';
import Form from '+components/form/Form';
import {
  FieldContainer as FieldContainerOrigin,
  Label,
} from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import {
  normalizeDateTimeToNumber,
  normalizeMultiSelectValue,
} from '+components/form/Normalizers';
import {
  validateDateTime,
  validateDateTimeBetween,
  validateDateTimeCannotBeSame,
  validateRequired,
} from '+components/form/Validators';
import IconButton from '+components/IconButton';
import { Col, LayoutTypes, Row } from '+components/Layout';
import Tooltip from '+components/Tooltip';
import { useGlobalFilters } from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePageTabsAndFormSync from '+hooks/usePageTabsAndFormSync';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import { useVerifyNqlBeforeSend } from '+hooks/useVerifyNqlBeforeSend';
import dayjs from '+utils/dayjs';
import getIntersectFieldName from '+utils/getIntersectFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';

import ActionsContainer from './ActionsContainer';
import Container from './Container';
import LeftSide from './LeftSide';
import RightSide from './RightSide';

const FieldContainer = styled(FieldContainerOrigin)`
  .MuiTextField-root {
    width: 100%;
  }
`;

// TODO: Date widgets are in Localtime, but are submitted in UTC
const FormBody = (props) => {
  const {
    context,
    nqlPlaceholder,
    handleSubmit,
    onSearchClear,
    searchDisabled,
    hideDates,
    hideGFButtons,
    maxIntersects,
    intersectFieldsOptions,
    subAccountOptions,
    maxNqlQueries,
  } = props;

  const dispatch = useDispatch();

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);
  const profile = useSelector(profileSelectors.getProfile);
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  usePageTabsAndFormSync();

  const form = useForm();
  const {
    values: formValues,
    submitting,
    invalid,
  } = useFormState({
    subscription: {
      values: true,
      submitting: true,
      invalid: true,
    },
  });

  const { meta: fromFieldMeta } = useField('from', {});
  const { meta: toFieldMeta } = useField('to', {});

  const fromFieldInvalid = !!fromFieldMeta.error;

  const toFieldInvalid = !!toFieldMeta.error;

  const { validationStatus } = useVerifyNqlBeforeSend(context);

  const isValidatingNql = validationStatus === FetchStates.fetching;
  const isSearchDisabled = searchDisabled || submitting;

  const [filters] = useGlobalFilters(context);
  const customer = useSelector(selectorsCustomer.getCurrentCustomer);
  const retention = useSelector(selectorsCustomer.getRetention);

  useLoadingIndicator(isValidatingNql);

  const nqlDocTemplateConfig = useMemo(
    () => ({ showIntersectable: maxNqlQueries > 1 }),
    [maxNqlQueries],
  );

  const nqlFieldLength = useMemo(
    () => formValues.nql?.filter((item) => !item?.hidden).length || 0,
    [formValues.nql],
  );

  const maxNqlQueriesForStats =
    StatsRequest.StatsConfig[context]?.maxNqlQueries;
  const isPushToGfDisabled = invalid || nqlFieldLength > maxNqlQueriesForStats;

  const dateTimeLimit = useMemo(() => {
    if (isRolesUiSettingsEnabled && userRoleUiSettings?.dateTimeLimit) {
      return Math.min(userRoleUiSettings.dateTimeLimit, retention);
    }
    return retention;
  }, [retention, isRolesUiSettingsEnabled, userRoleUiSettings?.dateTimeLimit]);

  const min = useMemo(
    () =>
      context === ContextTypes.audit
        ? new Date(2019, 0, 1, 0, 0, 0, 0)
        : dayjs(Date.now() - TimeDuration.day * dateTimeLimit)
            .millisecond(0)
            .toDate(),
    [context, dateTimeLimit],
  );

  const max = useMemo(() => dayjs().millisecond(0).toDate(), []);

  const fromDateValidate = useMemo(
    () =>
      formValues.startIsMin
        ? null
        : [
            validateRequired,
            validateDateTime,
            !formValues.endIsNow &&
              validateDateTimeCannotBeSame({
                fieldName: 'to',
                errorMessage: 'From date and To date cannot be the same.',
              }),
            !formValues.endIsNow &&
              validateDateTimeBetween({
                min,
                maxFieldName: 'to',
                includeMin: true,
                includeMax: false,
              }),
          ],
    [min, formValues.startIsMin, formValues.endIsNow],
  );

  const toDateValidate = useMemo(
    () =>
      formValues.endIsNow
        ? null
        : [
            validateRequired,
            validateDateTime,
            !formValues.startIsMin &&
              validateDateTimeCannotBeSame({
                fieldName: 'from',
                errorMessage: ' ',
              }),
            !formValues.startIsMin &&
              validateDateTimeBetween({
                minFieldName: 'from',
                max,
                includeMin: false,
                includeMax: true,
              }),
          ],
    // To prevent form validation looping do not put 'max' to deps (validator will update if form values changed)
    // @see: https://netography.atlassian.net/browse/PORTAL-1415
    [formValues],
  );

  const doSearchClear = useCallback(() => {
    form.setConfig('keepDirtyOnReinitialize', false);
    form.reset();
    form.setConfig('keepDirtyOnReinitialize', true);
    onSearchClear();
  }, [form, onSearchClear]);

  const onPasteFromGlobalFilters = useCallback(() => {
    form.setConfig('keepDirtyOnReinitialize', false);
    form.restart({
      nql: filters.nql?.length ? filters.nql : [''],
      intersect: filters.intersect,
      ...(!hideDates && {
        from: filters.from,
        to: filters.to - 10000, // Subtract 10ms to prevent Search from breaking when RealTime is true
        startIsMin: filters.startIsMin,
        endIsNow: filters.endIsNow,
      }),
      ...(!!customer?.isReseller && { customers: filters.customers }),
    });
    form.setConfig('keepDirtyOnReinitialize', true);
  }, [
    form,
    hideDates,
    filters.from,
    filters.to,
    filters.startIsMin,
    filters.endIsNow,
    JSON.stringify(filters.nql),
    JSON.stringify(filters.intersect),
    JSON.stringify(filters.customers),
    customer?.isReseller,
  ]);

  const onPushToGlobalFilters = useCallback(() => {
    const values = formValues;

    const fromValue = values.from
      ? {
          from: values.startIsMin
            ? Date.now() - TimeDuration.day * retention
            : values.from,
        }
      : {};

    const toValue = values.to
      ? { to: values.endIsNow ? Date.now() : values.to }
      : {};

    if (fromValue.from && toValue.to) {
      const to60 = +dayjs(values.to).subtract(1, 'minute');
      if (to60 < values.from) {
        fromValue.from = to60;
      }
    }

    dispatch(
      globalFiltersActions.changeFilter({
        ...fromValue,
        ...toValue,
        startIsMin: Boolean(values.startIsMin),
        endIsNow: Boolean(values.endIsNow),
        ...(values.from && values.to
          ? {
              period: {
                type: CustomType,
              },
            }
          : {}),
        context,
        [getNqlFieldName(context)]: values.nql,
        [getIntersectFieldName(context)]: values.intersect,
        ...(!!customer?.isReseller && { customers: values.customers }),
      }),
    );
  }, [retention, context, formValues, customer?.isReseller]);

  const onMinToggle = useCallback(
    (isMin) => {
      form.change('startIsMin', isMin);
    },
    [form],
  );

  const onNowToggle = useCallback(
    (isNow) => {
      form.change('endIsNow', isNow);
    },
    [form],
  );

  const onNqlEnterPress = useCallback(() => {
    // We need activeElement.blur() / activeElement.focus()
    // to blur nql field when Enter key pressed and run field validation
    document.activeElement.blur();
    handleSubmit();
    document.activeElement.focus();
  }, [handleSubmit]);

  useEffect(() => {
    if (nqlFieldLength === 1 && !!formValues.intersect?.length) {
      form.change('intersect', []);
    }
  }, [form, formValues.intersect, nqlFieldLength]);

  return (
    <Container>
      <LeftSide>
        <Form onSubmit={handleSubmit}>
          {!hideDates && (
            <Row maxWidth="620px">
              <Label invalid={fromFieldInvalid || toFieldInvalid}>
                Date & Time
              </Label>
              <FieldContainer row>
                <Field
                  name="from"
                  component={DateTimePickerField}
                  defaultValue={formValues.from}
                  min={min}
                  max={formValues.endIsNow ? max : formValues.to ?? max}
                  validate={fromDateValidate}
                  parse={normalizeDateTimeToNumber}
                  required
                  minButton
                  minPlaceholder={
                    context === ContextTypes.audit
                      ? `-${dayjs().diff(dayjs('2019-01-01'), 'year')} YEARS`
                      : null
                  }
                  onMinToggle={onMinToggle}
                  isMin={formValues.startIsMin}
                />

                <Field
                  component="input"
                  type="hidden"
                  name="startIsMin"
                  value={formValues.startIsMin}
                />

                <span
                  style={{ marginTop: '5px' }}
                  dangerouslySetInnerHTML={{
                    __html: '&nbsp;&longrightarrow;&nbsp;',
                  }}
                />

                <Field
                  name="to"
                  component={DateTimePickerField}
                  defaultValue={formValues.to}
                  min={formValues.startIsMin ? min : formValues.from ?? min}
                  max={max}
                  validate={toDateValidate}
                  parse={normalizeDateTimeToNumber}
                  required
                  nowButton
                  onNowToggle={onNowToggle}
                  isNow={formValues.endIsNow}
                />

                <Field
                  component="input"
                  type="hidden"
                  name="endIsNow"
                  value={formValues.endIsNow}
                />
              </FieldContainer>
            </Row>
          )}

          <FieldArray
            name="nql"
            label="NQL Query"
            component={ArrayNQLField}
            placeholder={nqlPlaceholder}
            context={context}
            maxLength={maxNqlQueries}
            docTemplateConfig={nqlDocTemplateConfig}
            onEnterPress={onNqlEnterPress}
          />

          {nqlFieldLength > 1 && (
            <Col $type={LayoutTypes.fieldValue}>
              <Field
                name="intersect"
                label="Intersect By"
                placeholder="Select fields to intersect by..."
                component={MultiSelectField}
                options={intersectFieldsOptions}
                limit={maxIntersects}
                helperText={`Max ${maxIntersects} fields`}
                validate={validateRequired}
                parse={normalizeMultiSelectValue}
                required
              />
            </Col>
          )}

          {!!customer?.isReseller && (
            <Col $type={LayoutTypes.fieldValue}>
              <Field
                name="customers"
                label="Query By Account(s)"
                placeholder="Select accounts to query by..."
                component={MultiSelectField}
                options={subAccountOptions}
                parse={normalizeMultiSelectValue}
              />
            </Col>
          )}

          <ActionsContainer>
            <Button
              type="submit"
              disabled={isSearchDisabled}
              data-tracking="search-submit"
            >
              Search
            </Button>

            <Button
              variant={ButtonVariants.outlined}
              onClick={doSearchClear}
              data-tracking="search-clear"
            >
              Clear
            </Button>
          </ActionsContainer>
        </Form>
      </LeftSide>

      {!hideGFButtons && (
        <RightSide>
          <Tooltip title="Populate from the current values in the Global Filters">
            <div>
              <IconButton
                size="medium"
                color="primary"
                onClick={onPasteFromGlobalFilters}
                data-tracking="pull-from-global-filters"
              >
                <ArrowBottomLeftIcon size={16} />
              </IconButton>
            </div>
          </Tooltip>

          <Tooltip
            title={
              <Fragment>
                Push search values to the Global Filters form fields.
                {isPushToGfDisabled && (
                  <Fragment>
                    <br />
                    {invalid &&
                      'Please, fix form validation errors before push to Global Filters.'}
                    {!invalid &&
                      nqlFieldLength > maxNqlQueriesForStats &&
                      `Only ${maxNqlQueriesForStats} NQL can be pushed to Global Filters.`}
                  </Fragment>
                )}
              </Fragment>
            }
          >
            <div>
              <IconButton
                size="medium"
                color="primary"
                onClick={onPushToGlobalFilters}
                disabled={isPushToGfDisabled}
                data-tracking="push-to-global-filters"
              >
                <ArrowTopRightIcon size={16} />
              </IconButton>
            </div>
          </Tooltip>
        </RightSide>
      )}
    </Container>
  );
};

export const propTypes = {
  context: PropTypes.string,
  nqlPlaceholder: PropTypes.string,
  onSearchClear: PropTypes.func,
  searchDisabled: PropTypes.bool,
  hideDates: PropTypes.bool,
  hideGFButtons: PropTypes.bool,
  refresher: PropTypes.number,
  maxNqlQueries: PropTypes.number,
  maxIntersects: PropTypes.number,
  intersectFieldsOptions: PropTypes.arrayOf(PropTypes.shape({})),
  subAccountOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export const defaultProps = {
  context: 'flow',
  nqlPlaceholder: '',
  onSearchClear: null,
  searchDisabled: false,
  hideDates: false,
  hideGFButtons: false,
  refresher: undefined,
  maxNqlQueries: 1,
  maxIntersects: 0,
  intersectFieldsOptions: [],
  subAccountOptions: [],
};

FormBody.propTypes = {
  ...propTypes,
  handleSubmit: PropTypes.func.isRequired,
};

FormBody.defaultProps = {
  ...defaultProps,
};

export default FormBody;
