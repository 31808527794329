import PropTypes from '+prop-types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';

import { VpcIcon } from '@/shared/img/icon';

import Button, { ButtonVariants } from '+components/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '+components/Modal';
import PluginCard from '+components/PluginCard';

const ModalBodyContainer = styled.div`
  text-align: center;
  min-height: 150px;
`;

const CardContainer = styled.div`
  margin: 5px;
`;

const PluginCards = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const StatusContainer = styled.div`
  margin-top: ${({ margin }) => margin || '20px'};
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.noDevicesText};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RetryButtonContainer = styled.div`
  margin-top: 25px;
`;

const CheckDevicesModal = ({ isOpen, toggleModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { unknownDevices, isFetching: isDeviceFetching } = useSelector(
    deviceSelectors.getState,
  );

  const fetchDevices = useCallback(() => {
    dispatch(deviceActions.unknownDevicesFetch(null, 'fetching'));
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchDevices();
    }
    return () => {
      dispatch(deviceActions.cancel('fetching'));
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={toggleModal}>
      <ModalHeader onClose={toggleModal}>Device Discovery</ModalHeader>
      <ModalBody>
        <ModalBodyContainer>
          {isDeviceFetching && (
            <StatusContainer>Searching for flow...</StatusContainer>
          )}

          {!isDeviceFetching && unknownDevices?.length > 0 && (
            <PluginCards>
              <StatusContainer>New Devices Found</StatusContainer>
              {unknownDevices.map((device) => (
                <CardContainer key={device.name}>
                  <PluginCard
                    name={device.name}
                    icon={<VpcIcon flowtype="device" size={40} />}
                    caption={device.name}
                    onClick={() =>
                      navigate(`../devices/add?ips=${device.ips.join()}`)
                    }
                    width="203px"
                    height="80px"
                    fontSize="12px"
                    secondaryColor
                  />
                </CardContainer>
              ))}
            </PluginCards>
          )}
          {!isDeviceFetching && unknownDevices?.length === 0 && (
            <StatusContainer>
              No Device Found
              <RetryButtonContainer>
                <Button onClick={fetchDevices}>Retry</Button>
              </RetryButtonContainer>
            </StatusContainer>
          )}
        </ModalBodyContainer>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleModal} variant={ButtonVariants.outlined}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CheckDevicesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default CheckDevicesModal;
