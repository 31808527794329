import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line no-unused-vars
import PermissionModel from '@/models/Permission';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as profileActions,
  selectors as profileSelectors,
} from '@/redux/api/user/profile';

/**
 * Returns a current user permissions.
 *
 * @param {string} [resource] one of {@link PermissionModel.Resources}
 * @param {boolean?} [fetch]
 * @return {Object}
 */
export const usePermissions = (resource, fetch = true) => {
  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const profile = useSelector(profileSelectors.getProfile);
  const permissions = useSelector(profileSelectors.getPermissions(resource));

  useEffect(() => {
    if (fetch && profile?.id && !permissions) {
      dispatch(profileActions.requestPermissions(resource));
    }
  }, [fetch, profile?.id, permissions, resource]);

  return useMemo(() => {
    if (!customer?.active || !permissions) {
      return { fetch: true, create: false, update: false, delete: false };
    }

    return permissions;
  }, [permissions, customer?.active]);
};

export default usePermissions;
