import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FORM_ERROR } from 'final-form';
import styled from 'styled-components';

import KeyIcon from 'mdi-react/KeyIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import { ColorTypes } from '@/models/ColorTypes';
import PermissionModel from '@/models/Permission';
import RoleModel from '@/models/Role';

import {
  actions as apiKeysActions,
  selectors as apiKeysSelectors,
} from '@/redux/api/apiKeys';
import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

import Button, { ButtonVariants } from '+components/Button';
import { lang } from '+components/charts/common/utils';
import ConfirmModal from '+components/ConfirmModal';
import CopyTextOrigin from '+components/CopyText';
import { Description, Group, Label } from '+components/form/FormField';
import Plaintext from '+components/form/Plaintext';
import FormModal from '+components/FormModal';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import useRoles from '+hooks/useRoles';

import ApiKeyModal from './components/ApiKeyModal';
import { getColumns } from './components/Columns';

const CopyText = styled(CopyTextOrigin)`
  padding: 8px 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colorFieldBackground};
`;

const tableId = 'ApiKeys_Table';

const sortBy = [{ id: 'appname', desc: false }];

const defaultApiKey = {
  isNew: true,
  appname: '',
  description: '',
  roles: [RoleModel.Roles.app_admin],
};

const encodeToBase64 = (json) => btoa(JSON.stringify(json));

const ApiKeys = () => {
  const dispatch = useDispatch();

  const permissionsApiKey = usePermissions(
    PermissionModel.Resources.api_key.value,
  );
  const permissionsCustomers = usePermissions(
    PermissionModel.Resources.account.value,
  );
  const { shortname = '' } = useSelector(customerSelectors.getCurrentCustomer);
  const customerSharedSecret = useSelector(customerSelectors.getSharedSecret);
  const { error } = useSelector(apiKeysSelectors.getState);
  const apiKeys = useSelector(apiKeysSelectors.getApiKeys);
  const lastApiKey = useSelector(apiKeysSelectors.getLastApiKey);
  const { roles, isRolesFetching } = useRoles();

  const isCustomerFetching = useSelector(customerSelectors.isFetching);
  const isApiKeysFetching = useSelector(apiKeysSelectors.isFetching);
  const isFetching = isCustomerFetching || isApiKeysFetching || isRolesFetching;

  const [isProcessing, setIsProcessing] = useState(null);
  const [showSharedSecret, setShowSharedSecret] = useState(false);
  const [showSharedSecretRegen, setShowSharedSecretRegen] = useState(false);
  const [apiKeyToManage, setApiKeyToManage] = useState(null);
  const [apiKeyToRegen, setApiKeyToRegen] = useState(null);
  const [apiKeyToDelete, setApiKeyToDelete] = useState(null);

  const canManage = apiKeyToManage?.appname
    ? permissionsApiKey?.update
    : permissionsApiKey?.create;
  const canRemove = apiKeyToManage?.appname && permissionsApiKey?.delete;

  useLoadingIndicator(isFetching);

  const netosecret = useMemo(() => {
    if (!lastApiKey) {
      return '';
    }
    return encodeToBase64({
      url: `${import.meta.env.VITE_APP_BACKEND_URL_ROOT}/api/v1`,
      shortname,
      appname: lastApiKey.appname,
      sharedsecret: customerSharedSecret,
      appkey: lastApiKey.appkey,
    });
  }, [lastApiKey, shortname, customerSharedSecret]);

  const cxActionMenu = useCallback(
    (_, original) => {
      const items = [
        {
          icon: <KeyIcon />,
          text: 'Regenerate Key',
          disabled: !permissionsApiKey?.update,
          onClick: () => {
            setApiKeyToRegen(original);
          },
        },
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => {
            setApiKeyToManage(original);
          },
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissionsApiKey?.delete,
          onClick: () => {
            setApiKeyToDelete(original);
          },
        },
      ];

      return (
        <MenuColumnContextMenu
          title={original.appname}
          items={items}
          dataTracking="api-keys"
        />
      );
    },
    [permissionsApiKey],
  );

  const columns = useMemo(
    () => getColumns({ shortname, roles, cxActionMenu }),
    [shortname, roles, cxActionMenu],
  );

  const onSharedSecretShow = useCallback(() => {
    setShowSharedSecret(true);
  }, []);

  const onSharedSecretHide = useCallback(() => {
    setShowSharedSecret(false);
  }, []);

  const onSharedSecretRegen = useCallback(() => {
    dispatch(customerActions.regenSharedSecret());
    return new Promise((resolve) => {
      setIsProcessing({ resolve });
    });
  }, []);

  const onApiKeySave = useCallback(({ isNew, ...values }) => {
    if (isNew) {
      dispatch(apiKeysActions.createApiKey(values));
    } else {
      dispatch(apiKeysActions.updateApiKey(values));
    }
    return new Promise((resolve) => {
      setIsProcessing({ resolve });
    });
  }, []);

  const onApiKeyRegen = useCallback(() => {
    dispatch(apiKeysActions.regenApiKey(apiKeyToRegen.appname));
    return new Promise((resolve) => {
      setIsProcessing({ resolve });
    });
  }, [apiKeyToRegen?.appname]);

  const onLastApiKeyClear = useCallback(() => {
    dispatch(apiKeysActions.clearApiKeyKey());
  }, []);

  const onApiKeyDelete = useCallback(() => {
    dispatch(apiKeysActions.removeApiKey(apiKeyToDelete.appname));
    return new Promise((resolve) => {
      setIsProcessing({ resolve });
    });
  }, [apiKeyToDelete?.appname]);

  const textTemplate = useMemo(
    () => (
      <Fragment>
        <Group>
          <Label>Application name (appname)</Label>
          <Plaintext>
            <CopyText text={lastApiKey.appname}>{lastApiKey.appname}</CopyText>
          </Plaintext>
        </Group>
        <Group>
          <Label>Application key (appkey)</Label>
          <Plaintext>
            <CopyText text={lastApiKey.appkey}>{lastApiKey.appkey}</CopyText>
          </Plaintext>
          <Description>
            Store this Application Key somewhere safe. When this modal is
            closed, it will no longer be retrievable. In case the Key is lost, a
            new Key can be Regenerated for this Application.
          </Description>
        </Group>
        <Group>
          <Label>Combined API secret (netosecret)</Label>
          <Plaintext>
            <CopyText text={netosecret}>{netosecret}</CopyText>
          </Plaintext>
          <Description>
            netosecret is a base64 encoded string of JSON containing shortname,
            appname, appkey, shared_secret, and API URL in a single field. If
            you are using a component that supports netosecret this is the only
            value needed. This is not used if you are using the individual
            fields.
          </Description>
        </Group>
      </Fragment>
    ),
    [lastApiKey.appname, lastApiKey.appkey, netosecret],
  );

  useEffect(() => {
    if (isFetching || !isProcessing) {
      return;
    }

    const { resolve } = isProcessing;

    if (error) {
      resolve({ [FORM_ERROR]: error });
      return;
    }

    setIsProcessing(null);

    setShowSharedSecretRegen(false);
    setApiKeyToManage(null);
    setApiKeyToRegen(null);
    setApiKeyToDelete(null);

    resolve();
  }, [isFetching, isProcessing, error]);

  useEffect(() => {
    dispatch(customerActions.requestSharedSecret());
    dispatch(apiKeysActions.fetchApiKeys());
    return () => {
      dispatch(customerActions.clearSharedSecret());
    };
  }, []);

  return (
    <Fragment>
      <ActionsContainer>
        <Button
          onClick={() => setApiKeyToManage(defaultApiKey)}
          disabled={!permissionsApiKey?.create}
          testId="add-api-key-button"
        >
          Add API Key
        </Button>
        <Button
          variant={ButtonVariants.outlined}
          onClick={onSharedSecretShow}
          testId="show-api-shared-secret"
        >
          API Shared Secret
        </Button>
      </ActionsContainer>

      <Table
        id={tableId}
        columns={columns}
        data={Object.values(apiKeys || {})}
        sortBy={sortBy}
        noDataText={apiKeys ? undefined : lang.loading}
        testId="api-keys-table"
      />

      {!!showSharedSecret && !!customerSharedSecret && (
        <FormModal
          item="API shared secret"
          confirmButtonText="Regenerate"
          titleTemplate={(_, item) => item}
          onToggle={onSharedSecretHide}
          onSubmit={() => setShowSharedSecretRegen(true)}
          disabled={isFetching || !permissionsCustomers?.update}
          toggleOnConfirm={false}
          labelOnTop
          isOpen
          testId="api-shared-secret-modal"
        >
          <Plaintext>
            <CopyText text={customerSharedSecret}>
              {customerSharedSecret}
            </CopyText>
            <Description>
              The shared secret is used to encode and validate API keys.
              Regenerate the shared secret as needed, but note it will cause
              current API integrations to become immediately invalid until they
              are updated to encode requests with the new shared secret.
            </Description>
          </Plaintext>
        </FormModal>
      )}

      {showSharedSecretRegen && (
        <ConfirmModal
          item="API shared secret"
          confirmButtonText="regenerate"
          confirmButtonColor={ColorTypes.primary}
          onToggle={() => setShowSharedSecretRegen(false)}
          onConfirm={onSharedSecretRegen}
          isDisabled={isFetching}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {!!apiKeyToManage && (
        <ApiKeyModal
          item={apiKeyToManage.appname || 'new API key'}
          mode={apiKeyToManage.appname ? 'edit' : 'add'}
          initialValues={apiKeyToManage}
          roles={roles}
          onToggle={() => setApiKeyToManage(null)}
          onSubmit={onApiKeySave}
          deleteButtonText="Delete API Key"
          onDelete={() => setApiKeyToDelete(apiKeyToManage)}
          deleteButtonHidden={!apiKeyToManage?.appname}
          deleteButtonDisabled={!canRemove}
          disabled={!canManage}
          toggleOnConfirm={false}
          isOpen
          testId="add-edit-api-key-modal"
        />
      )}

      {!!lastApiKey?.appname && (
        <ConfirmModal
          item="API key"
          confirmButtonText="Done"
          confirmButtonColor={ColorTypes.primary}
          cancelButtonText=""
          titleTemplate={(_, item) => item}
          textTemplate={textTemplate}
          onToggle={onLastApiKeyClear}
          onConfirm={onLastApiKeyClear}
          isDisabled={isFetching}
          toggleOnConfirm={false}
          isOpen
          testId="last-api-key-modal"
        />
      )}

      {!!apiKeyToRegen && (
        <ConfirmModal
          item={`${apiKeyToRegen.appname} key`}
          confirmButtonText="regenerate"
          confirmButtonColor={ColorTypes.primary}
          onToggle={() => setApiKeyToRegen(null)}
          onConfirm={onApiKeyRegen}
          isDisabled={isFetching}
          toggleOnConfirm={false}
          isOpen
          testId="regen-api-key-modal"
        />
      )}

      {!!apiKeyToDelete && (
        <ConfirmModal
          item={apiKeyToDelete.appname}
          onToggle={() => setApiKeyToDelete(null)}
          onConfirm={onApiKeyDelete}
          isDisabled={isFetching}
          toggleOnConfirm={false}
          isOpen
          testId="delete-api-key-modal"
        />
      )}
    </Fragment>
  );
};

export default ApiKeys;
