import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCounter, useMeasure } from 'react-use';

import isEqual from 'lodash.isequal';
import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import SettingCategories from '@/models/SettingCategories';
import StatsRequest from '@/models/StatsRequest';

import {
  actions,
  ConnectionStatus,
  selectors,
  SubscribeMode,
} from '@/redux/newsocket';
import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';

import Map from './components/Map';
import Overlay from './components/Overlay';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const excludeContexts = new Set([
  ContextTypes.alerts,
  ContextTypes.blocks,
  ContextTypes.traffic,
  ContextTypes.dns,
]);

const convertItem = (item) => {
  const {
    timestamp,

    srcgeo: {
      continentcode: sourceLand,
      countrycode: sourceCountry,
      location: { lon: sourceLng, lat: sourceLat },
    },

    dstgeo: {
      continentcode: targetLand,
      countrycode: targetCountry,
      location: { lon: targetLng, lat: targetLat },
    },

    flowsrcname,
    input,
    inputalias,
  } = item;

  const inputNum = input && ` - ${input}`;
  const inputAlias = inputalias && ` - ${inputalias}`;

  const categoryName = `${flowsrcname}${inputAlias || inputNum || ''}`;

  return {
    timestamp,
    categoryName,
    flowsrcname,

    sourceLat,
    sourceLng,
    sourceLand,
    sourceCountry,

    targetLat,
    targetLng,
    targetLand,
    targetCountry,

    data: item,
  };
};

const filterByLocation = ({ sourceLat, sourceLng, targetLat, targetLng }) =>
  (+sourceLat || +sourceLng) && (+targetLat || +targetLng);

const name = 'RealtimeFlowMap_data';

const includeFields = [
  'timestamp',
  'srcgeo',
  'dstgeo',
  'flowsrcname',
  'input',
  'inputalias',
  'srcip',
  'srcport',
  'srcowneras',
  'dstip',
  'dstport',
  'protocol',
  'bits',
  'label',
];

export default memo(() => {
  const [subscribed, setSubscribed] = useState(false);
  const dispatch = useDispatch();

  const [measureRef, { width, height }] = useMeasure();

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const socketStatus = useSelector(selectors.socketStateSelector);
  const isSocketPaused = useSelector(socketControlSelectors.isPaused);
  const [filters] = useGlobalFilters(context);

  useLoadingIndicator(socketStatus !== ConnectionStatus.ready);

  const [data, setData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [nqlVersion, { inc }] = useCounter(0);
  const oldNql = useRef(filters.nql);
  const waitData = useRef(false);

  const [socketLimit] = usePortalSettingsValue(
    SettingCategories.socket,
    'limit',
    50,
  );

  const socketOptions = useMemo(
    () => ({
      feed: 'flow',
      include: [
        ...includeFields,
        !!filters.customers?.length && 'customer',
      ].filter(Boolean),
      last: 20,
      interval: 2,
      format: 'object',
      type: 'search',
      limit: socketLimit,
      ...StatsRequest.makeSearch({
        search: filters.nql,
        intersect: filters.intersect,
      }),
      customers: filters.customers,
    }),
    [
      socketLimit,
      JSON.stringify(filters.nql),
      JSON.stringify(filters.intersect),
      JSON.stringify(filters.customers),
    ],
  );

  const onData = useCallback((completed, inCategories) => {
    setCompletedData(completed);
    setCategories(inCategories);
  }, []);

  const selector = useMemo(() => selectors.recordsSelector(name), [name]);

  const records = useSelector(selector);

  useEffect(() => {
    if (!records?.size || isSocketPaused) {
      return;
    }

    if (waitData.current) {
      waitData.current = false;
      inc();
    }

    setData(
      records
        .toArray()
        .filter(Boolean)
        .map(convertItem)
        .filter(filterByLocation),
    );
  }, [isSocketPaused, records]);

  useEffect(() => {
    if (isSocketPaused || socketStatus !== ConnectionStatus.ready) {
      return;
    }

    if (!isEqual(oldNql.current, filters.nql)) {
      waitData.current = true;
      oldNql.current = filters.nql;
    }

    dispatch(
      actions.subscribe({
        name,
        options: {
          ...socketOptions,
          ...(subscribed && { replace: true }),
        },
        // fn: generator,
        mode: SubscribeMode.throw,
      }),
    );

    if (!subscribed) {
      setSubscribed(true);
    }
  }, [socketStatus, isSocketPaused, name, socketOptions]);

  useEffect(() => {
    if (isSocketPaused) {
      dispatch(actions.unsubscribe({ name }));
      setSubscribed(false);
    }
  }, [isSocketPaused, name]);

  useEffect(
    () => () => {
      dispatch(actions.unsubscribe({ name }));
    },
    [],
  );

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  return (
    <Container ref={measureRef}>
      <GlobalFiltersSetting
        nql
        context={context}
        excludeContexts={excludeContextsArr}
        onlyRealtime
        customers
        socketControl
      />
      <Map
        data={data}
        width={width}
        height={height}
        onData={onData}
        refresh={nqlVersion}
      />
      <Overlay
        data={completedData}
        categories={categories}
        refresh={nqlVersion}
      />
    </Container>
  );
});
