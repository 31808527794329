import chmln from '@chamaeleonidae/chmln';

import { config } from '@/config';

export const chameleon = {
  init: () => {
    if (!config.chameleon.enabled) {
      return;
    }

    chmln.init(config.chameleon.apiKey, {
      fastUrl: config.chameleon.fastUrl,
    });
  },
  identify: (id, data) => {
    if (!config.chameleon.enabled) {
      return;
    }

    chmln.identify(id, data);
  },
};
