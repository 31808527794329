import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: ${({ $isOpen }) => ($isOpen ? '32px' : 0)};
  line-height: ${({ $isOpen }) => ($isOpen ? '32px' : 0)};
  overflow: hidden;
  font-weight: 600;

  text-align: center;
  vertical-align: center;

  background-color: ${({ theme }) => theme.colorTopbarBannerBackground};
  color: ${({ theme }) => theme.colorTopbarBannerText} !important;

  transition:
    height 0.6s,
    line-height 0.6s;

  button {
    outline: unset;
    background: unset;
    border: unset;
    padding: unset;
    text-decoration: underline;
    font-weight: 600;
  }
`;
