import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import AccountKeyIcon from 'mdi-react/AccountKeyIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useCustomerSubscriptionTypes from '+hooks/useCustomerSubscriptionTypes';
import useRoles from '+hooks/useRoles';

import { Columns, getColumns } from './Columns';

const defaultColumns = [
  Columns.organization,
  Columns.parent,
  Columns.type,
  Columns.subscriptionType,
  Columns.ingest,
  Columns.retention,
  Columns.rollupRetention,
  Columns.flowSources,
  Columns.fps,
  Columns.menu,
];

const additionalColumns = Object.keys(Columns).filter(
  (item) => !defaultColumns.includes(item),
);

const CustomersTable = (props) => {
  const {
    data,
    impersonateAdmin,
    toggleDeleteModal,
    toggleEditModal,
    shortname,
    permissions,
    isDisabled,
    noDataText,
    onLoginToCustomer,
    ...tail
  } = props;

  const { roles } = useRoles();
  const profile = useSelector(profileSelectors.getProfile);
  const canUserMasquerade = !!roles?.[profile?.roles?.[0]]?.canMasquerade;
  const originalShortname =
    profile?.app_metadata?.original || profile?.app_metadata?.shortname;

  const customerTypes = useSelector(customerSelectors.getCustomerTypes);
  const customerSubscriptionTypes = useCustomerSubscriptionTypes();
  const [autoResetPage, setAutoResetPage] = useState(false);
  const lastShortname = useRef(shortname);

  const cxActionMenu = useCallback(
    (id, item) => {
      const items = [];
      const { isReseller, directCustomers } = item;

      const canMasquerade =
        !isDisabled &&
        canUserMasquerade &&
        (item.meta?.reseller_login_enabled ||
          originalShortname === 'netography');
      if (item.shortname !== shortname) {
        items.push({
          icon: <AccountKeyIcon />,
          text: 'Login To',
          disabled: !canMasquerade,
          onClick: () => {
            onLoginToCustomer(item.shortname, originalShortname);
          },
        });

        items.push({
          icon: <AccountKeyIcon />,
          text: 'Impersonate Admin',
          disabled: !canMasquerade,
          onClick: () => {
            impersonateAdmin(item);
          },
        });

        // Remove this `items.pop()` if we want to have ability to impersonate users
        // @see: https://netography.atlassian.net/browse/PORTAL-1289
        items.pop();
      }

      items.push({
        icon: <LeadPencilIcon />,
        text: 'Edit',
        disabled: isDisabled,
        onClick: () => {
          toggleEditModal(item);
        },
      });

      if (item.shortname !== shortname) {
        items.push({
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled:
            !permissions?.delete ||
            isDisabled ||
            !!(isReseller && directCustomers),
          onClick: () => {
            toggleDeleteModal(item);
          },
        });
      }

      return (
        <MenuColumnContextMenu
          title={item.shortname}
          items={items}
          dataTracking="customer"
        />
      );
    },
    [
      toggleEditModal,
      toggleDeleteModal,
      shortname,
      impersonateAdmin,
      permissions,
      isDisabled,
      canUserMasquerade,
      originalShortname,
      onLoginToCustomer,
    ],
  );

  const columns = useMemo(
    () =>
      getColumns(defaultColumns, {
        cxActionMenu,
        customerTypes,
        customerSubscriptionTypes,
      }),
    [cxActionMenu, customerTypes, customerSubscriptionTypes],
  );

  const availableColumns = useMemo(
    () =>
      getColumns(additionalColumns, {
        cxActionMenu,
        customerTypes,
        customerSubscriptionTypes,
      }),
    [cxActionMenu, customerTypes, customerSubscriptionTypes],
  );

  useEffect(() => {
    if (lastShortname.current === shortname) {
      setAutoResetPage(false);
    } else {
      setAutoResetPage(true);
    }

    lastShortname.current = shortname;
  }, [shortname, data]);

  return (
    <Table
      {...tail}
      id="Settings_customers_table"
      columns={columns}
      availableColumns={availableColumns}
      data={data}
      sortBy={[
        {
          id: 'organization',
          desc: false,
        },
      ]}
      autoResetPage={autoResetPage}
      noDataText={noDataText}
    />
  );
};

CustomersTable.propTypes = {
  toggleEditModal: PropTypes.func.isRequired,
  impersonateAdmin: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shortname: PropTypes.string.isRequired,
  permissions: PropTypes.shape(),
  isDisabled: PropTypes.bool,
  noDataText: PropTypes.string,
  onLoginToCustomer: PropTypes.func.isRequired,
};

CustomersTable.defaultProps = {
  permissions: null,
  isDisabled: false,
  noDataText: undefined,
};

export default CustomersTable;
