import PropTypes from '+prop-types';
import { Fragment } from 'react';

import AwsAuthTypes from '@/models/AwsAuthTypes';

import ButtonGroupField from '+components/form/ButtonGroupField';
import { useFormState } from '+components/form/FinalForm';
import Field from '+components/form/FinalForm/Field';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const AwsFields = ({ disabled, tags }) => {
  const regionOptions = useProviderRegions('aws');
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  return (
    <Fragment>
      <Field
        name="aws.region"
        label="Region"
        component={MultiSelectField}
        options={regionOptions}
        parse={normalizeMultiSelectValue}
        helperText="Location(s) of the Amazon Web Services, leave empty to include all regions."
        disabled={disabled}
      />
      <Field
        name="aws.tags"
        label="Tag/Label Matches"
        helperText="One or many tags to match any custom tag names to track in Amazon Web Services"
        options={tags}
        component={MultiSelectField}
        parse={normalizeMultiSelectValue}
        disabled={disabled}
        allowCreate
      />
      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="roleAuth"
          label="Authentication Type"
          component={ButtonGroupField}
          options={Object.values(AwsAuthTypes)}
          disabled={disabled}
          defaultValue={AwsAuthTypes.role.value}
        />
        {formValues.roleAuth === AwsAuthTypes.key.value ? (
          <Fragment>
            <Field
              name="aws.accesskeyid"
              label="Access Key ID"
              component={TextField}
              type="text"
              validate={validateRequired}
              helperText="Access key id for authenticating to Amazon Web Services"
              disabled={disabled}
              required
            />

            <Field
              name="aws.accesssecret"
              label="Access Secret"
              component={TextField}
              type="password"
              autoComplete="off"
              validate={validateRequired}
              helperText="The access secret for authenticating to Amazon Web Services"
              disabled={disabled}
              required
            />
          </Fragment>
        ) : (
          <Field
            name="aws.role.arn"
            label="AWS ARN"
            component={TextField}
            type="text"
            validate={validateRequired}
            helperText="The role ARN for authenticating to Amazon Web Services"
            disabled={disabled}
            required
          />
        )}
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

AwsFields.propTypes = {
  disabled: PropTypes.bool,
  tags: PropTypes.array,
};

AwsFields.defaultProps = {
  disabled: false,
  tags: [],
};

export default AwsFields;
