import { cloneElement, Fragment, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import DividerOriginal from '@mui/material/Divider';

import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import Button from '+components/Button';
import { ActionsContainer, Col, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import PluginCard from '+components/PluginCard';
import Tag from '+components/Tag';
import useMaxSources from '+hooks/useMaxSources';
import usePermissions from '+hooks/usePermissions';
import useProviderClassifications from '+hooks/useProviderClassifications';

import { SOURCETYPES } from '../../utils/sourceTypes';
import CheckDevicesModal from './CheckDevicesModal';

const Divider = styled(DividerOriginal).attrs(() => ({
  textAlign: 'left',
}))`
  text-transform: uppercase;

  &:before {
    width: 0;
  }

  &:after {
    width: 100%;
  }

  span {
    opacity: 0.5;
  }
`;

const AddSource = () => {
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const [, , pageTabMethods] = usePageTabs();

  const classifications = useProviderClassifications();

  const devicePermissions = usePermissions(
    PermissionModel.Resources.device.value,
  );
  const cloudPermissions = usePermissions(
    PermissionModel.Resources.cloud_provider.value,
  );

  const trafficCategories = useMemo(() => {
    if (!classifications) {
      return { flow: SOURCETYPES };
    }

    const result = {};

    SOURCETYPES.forEach((item) => {
      const keys = Object.keys(
        classifications[item.flowtype]?.[item.flowresource] || {
          flow: null,
        },
      );
      keys.forEach((key) => {
        let category = result[key];
        if (!category) {
          category = [];
          result[key] = category;
        }

        category.push(item);
      });
    });

    return result;
  }, [classifications]);

  const [showCheckDevicesModal, toggleCheckDevicesModal] = useToggle(false);

  const { enforceMaxSources, sourcesRemaining } = useMaxSources();

  const onAddDeviceClick = useCallback(() => {
    pageTabMethods.updateActive(`${RoutePaths.sourcesDevices}/add`);
  }, []);

  const onAddVpcClick = useCallback(
    (flowType, flowResource, trafficType) => () => {
      pageTabMethods.updateActive(
        `${RoutePaths.sourcesCloudProviders}/add/${flowType}/${flowResource}/${trafficType}`,
      );
    },
    [],
  );

  return (
    <Col gap="10px">
      <ActionsContainer>
        <Button
          onClick={() => toggleCheckDevicesModal()}
          disabled={!devicePermissions?.create}
        >
          Check for devices
        </Button>
      </ActionsContainer>

      {enforceMaxSources && (
        <Row gap="10px">
          <Tag color="secondary" outlined={false}>
            {sourcesRemaining} Remaining
          </Tag>
        </Row>
      )}

      {Object.entries(trafficCategories).map(([trafficType, sources]) => {
        if (!isDnsEnabled && trafficType === 'dns') {
          return null;
        }
        return (
          <Fragment key={trafficType}>
            {isDnsEnabled && <Divider>{trafficType}</Divider>}
            <Row gap="10px">
              {sources.map((item) => (
                <PluginCard
                  group={isDnsEnabled ? trafficType : ''}
                  key={item.label}
                  name={item.label}
                  icon={
                    item.icon
                      ? cloneElement(item.icon, { size: 40 })
                      : item.icon
                  }
                  caption={`Add ${item.label} ${
                    isDnsEnabled ? 'Traffic' : 'Flow'
                  } Source`}
                  disabled={
                    item.label === 'Device'
                      ? !devicePermissions?.create
                      : !cloudPermissions?.create
                  }
                  onClick={
                    item.label === 'Device'
                      ? onAddDeviceClick
                      : onAddVpcClick(
                          item.flowtype,
                          item.flowresource,
                          trafficType,
                        )
                  }
                />
              ))}
            </Row>
          </Fragment>
        );
      })}

      {showCheckDevicesModal && (
        <CheckDevicesModal
          isOpen={showCheckDevicesModal}
          toggleModal={toggleCheckDevicesModal}
        />
      )}
    </Col>
  );
};

export default AddSource;
